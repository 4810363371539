//css
import './css/progressbar.css';

const ProgressBar = (props) => {
    var color;

    switch (localStorage['background_theme']) {
        case 'dark':
            color = "#b19cd9"
            break;
        case 'light':
            color =  "#FFFF99"
            break;
        case 'earth':
            color = "yellowgreen"
            break;
        case 'ice':
            color = "skyblue"
            break;
        case 'fire':
            color = "red"
            break;
        default:
            color = "#76c7c0"
            break;
    }
    
    return(
        <div className="progress-bar-container">
            <div
                className="progress-bar"
                style={{ width: `${props.progress}%`, 
                backgroundColor: color,
                color: localStorage['background_theme'] == "light"? "black" : "white" }}
            >
                <span className="progress-bar-text">{props.progress}%</span>
            </div>
        </div>
    )
}
export default ProgressBar;