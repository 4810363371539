import { Fragment, useEffect, useState } from "react";
import "./css/keyboard.css";

const Keyboard = (props) => {
    return (
        <Fragment>
            <div className="prefix-keyboard above-background">
                {[...Array(26).keys()].map((k) => {
                    return <div className="prefix-keyboard-letter">
                        <span id={"letter-"+(k+1)} onClick={props.press}></span>
                    </div>
                })}
            </div>
        </Fragment>
    )
}

export default Keyboard;