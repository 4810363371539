import { Fragment, useEffect, useState } from "react";
import './css/panel.css';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

const Panel = (props) => {
    const [char, setChar] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    function handleSelect() {
        props.select(props.num)
    }

    function toggle() {
        setDropdownOpen(!dropdownOpen)
    }

    function stopProp(e) {
        e.stopPropagation();
    }

    function selectSkill(e) {
        props.selectSkill(e.target.id)
    }

    useEffect(() => {
        // verify correct selections
        if (props.panel === props.num) {
            if (props.skillSelected === props.skillNum && props.guess.name === props.answer.name) {
                var currObj = JSON.parse(localStorage['skill'])
                currObj['solve'][props.num-1] = true
                localStorage['skill'] = JSON.stringify(currObj)
                setChar(true)
            } else {
                // end game cause guess panel wrong
                setChar(false)
                // should set other char false too, probably invoke parent state
                props.solve(false)
            }
        }
        if (props.config.length && props.answer) {
            if (props.config[props.num-1]) {
                setChar(true)
            } else setChar(false)
        }
        // cleanup
        props.cleanup()
    }, [props.guessCounter, props.config]) //props.gameOver

    return (
        <Fragment>
                <div className={
                    char === null? "guess-skill-image-panel col-4-lg col-md-4 col-sm-4" :
                        (char?"guess-skill-image-panel-correct col-4-lg col-md-4 col-sm-4":"guess-skill-image-panel-wrong col-4-lg col-md-4 col-sm-4")
                    } 
                onClick={handleSelect}>
                    <div>
                        <div className="guess-skill-image-bg" style={{backgroundImage: 
                            `url(${char === null? "" : props.answer.image[0] })`
                        }}></div>
                    </div>
                    <div>
                        {props.num}.
                        <div className={char === null?"guess-skill-image-selection":(char?"guess-skill-image-correct":"guess-skill-image-wrong")}>
                            {props.panel === props.num && char === null? "Selected" : ""}
                            {char !== null && char? "Correct" : ""}
                            {char !== null && !char? "Wrong" : ""}
                        </div>
                        <div className="guess-skill-image-char">
                            <img src={props.skill} alt="skill-icon"/><br/>
                            <u>{char !== null && char? props.skillNum : ""}</u>
                            <u>{char !== null && !char? props.skillNum : ""}</u>
                        </div>
                        {/* here will be dropdown */}
                        <div className="guess-skill-image-info">
                            {/* dropdown */}
                            <Dropdown style={props.panel === props.num && char === null?{display: "block"}:{display: "none"}}
                                isOpen={dropdownOpen} toggle={toggle} onClick={stopProp}
                            >
                                <DropdownToggle caret color="primary">Skill</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem id={"S1"} onClick={selectSkill}>S1</DropdownItem>
                                    <DropdownItem id={"S2"} onClick={selectSkill}>S2</DropdownItem>
                                    <DropdownItem id={"S3"} onClick={selectSkill}>S3</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            {props.panel === props.num  && char === null?"Your Guess: ":""}<u>{props.panel === props.num  && char === null?props.skillSelected: ""}</u>
                        </div>
                    </div>
                </div>
        </Fragment>
    )
}

export default Panel;