import { Fragment, useEffect, useState } from "react";
import HomeHeader from "../E7dle/Components/HomeHeader";
import './index.css'

const Feedback = () => {
    const [text, setText] = useState("")
    const [option, setOption] = useState("bug")

    function changeText(e) {
        setText(e.target.value)
    }

    function changeOption(e) {
        setOption(e.target.value)
    }

    async function submitForm(e) {
        const url = "https://e7dle-backend.uc.r.appspot.com/api/feedback/submit"
        e.preventDefault()
        // html dom change
        const form = document.querySelector("#FeedbackForm")
        const page = form.parentElement
        form.remove()
        const loadingBox = document.createElement('div')
        loadingBox.className = "text-center mt-5"
        const loading = document.createElement('img')
        loading.id = "while-loading"
        loading.src = "loading-gif.gif"
        loading.alt = "loading..."
        loadingBox.appendChild(loading)
        page.appendChild(loadingBox)
        // call rest api
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json', // Send as JSON
                },
                body: JSON.stringify({
                    "type": option,
                    "comment": text
                })
            }).then((result) => {
                // html dom change
                const success = document.createElement('h2')
                success.className = "above-background text-white text-center mt-5"
                if (result.status === 200) {
                    success.innerHTML = "Successfully submitted. Thank you."
                } else {
                    success.innerHTML = "Something went wrong with the server, try again later."
                }
                page.appendChild(success)
                loading.remove()
            })
        } catch (err) {
            console.log(err)
            return
        }
    }

    return (
        <Fragment>
            <div className="overlay">
            <div className="homepage">
                <div className="homepage-header container">
                    <HomeHeader />
                    <form onSubmit={submitForm} id="FeedbackForm">
                    <div className="row feedback-form above-background">
                        <div className="col-2 feedback-options">
                            <label htmlFor="issue">Issue</label>
                            <select id="issue" onChange={changeOption}>
                                <option value="bug">Bug</option>
                                <option value="idea">Idea</option>
                            </select>
                            <button type="submit">Submit</button>
                        </div>
                        
                        <div className="col-10 feedback-box">
                            <textarea className="feedback-textbox" placeholder="Type something..."
                                onChange={changeText} value={text}></textarea>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            </div>
        </Fragment>
    )
}

export default Feedback;