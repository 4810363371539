import { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronUp } from '@fortawesome/free-solid-svg-icons';
//components
//css
import './styles/footer.css';

const Footer = () => {
    const [openFooter, setOpenFooter] = useState(false)
    const handleFooter = () => {
        setOpenFooter(!openFooter)
    }

    async function retrieveVer() {
        const response = await fetch("https://e7dle-backend.uc.r.appspot.com/api/version").then((res) => {
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json();

        }).then((data) => {
            if (localStorage['ver'] !== data.ver) {
                console.log("new app ver; reloading cache...")
                alert("App is outdated, clearing cache and reloading...")
                localStorage.clear()
                window.location.reload()
                localStorage['ver'] = data.ver;
                return false
            }
            return true;
        })
        return response // true false but async slow
    }

    function checkScreenWidth() {
        const screenWidth = window.innerWidth
        if (screenWidth < 1024) {
            alert("This app is currently not optimized for screens like phones.")
            // should I keep the screen notif on if their device is initially ok then not?
        }
    }

    useEffect(() => {
        try {
            retrieveVer()
            .then((res) => {
                if (localStorage['notif']) {
                    const notif = JSON.parse(localStorage['notif'])
                    if (notif.closeScreen != true) {
                        checkScreenWidth()
                    }
                    localStorage['notif'] = JSON.stringify({
                        'close': notif.close,
                        'timestamp': notif.timestamp,
                        'closeScreen': true,
                    });
                }
            })
        } catch (err) { console.log(err) }
    })

    return (
        <Fragment>
            <footer className={openFooter? "footer-div-close" : "footer-div-open"} style={{fontFamily:"Times New Roman, erif"}}>
                <button className='footer-expand btn btn-secondary' onClick={handleFooter}>
                    <FontAwesomeIcon icon={faCircleChevronUp} />
                </button>
                <div className='container'>
                    <div className='row footer-top'>
                        <div className='col'>
                            <a href={"https://ko-fi.com/V7V311SJGV"} target="_blank" rel="noreferrer">
                                <img src={process.env.PUBLIC_URL+'/kofi-badge-long.png'} alt="Support this project" height="45px"/>
                            </a>
                        </div>
                    </div>
                    <div className='row footer-mid'>
                        <div className='col'>
                            (Official)
                        </div>
                        <div className='col'>
                            <a href="https:/epic7.onstove.com/" target="_blank" rel="noreferrer">https://epic7.onstove.com/</a>
                        </div>
                    </div>
                    <div className='row footer-mid'>
                        <div className='col'>
                            (Sources)
                        </div>
                        <div className='col'>
                                <a href="https://www.e7vau.lt/" target="_blank" rel="noreferrer">https://www.e7vau.lt/</a>
                        </div>
                    </div>
                    <div className='row footer-mid'>
                        <div className='col'>
                            
                        </div>
                        <div className='col'>
                            <a href="https://ceciliabot.github.io/timeline/" target="_blank" rel="noreferrer">https://ceciliabot.github.io/timeline/</a>
                            
                        </div>
                    </div>
                    <div className='row footer-mid'>
                        <div className='col'>
                            (Background-art)
                        </div>
                        <div className='col'>
                            Epic Seven Artbook Vol. 2 Cover
                        </div>
                    </div>
                    <div className='row footer-bottom'>
                        <div className='col'>
                        Disclaimer: I am NOT affiliated with this game.
                        This is a fan site dedicated to the mobile game <b>Epic Seven</b> owned by Smilegate.
                        A passion project to practise some programming. 
                        P.S. If you have any suggestions or idea you would like to see implemented, you can 
                        <a href='/#/feedback'> comment here</a>
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
        
    )
}
export default Footer;