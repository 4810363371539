import { Fragment } from "react";
import {Link} from "react-router-dom";
import "./css/mode.css";

const Mode = (props) => {
    
    return (
        <div className="Mode-div" key={"modediv-"+props.id+"-"+props.id2}>
            <Link to={props.link}>
                <button className="Mode-Card" style={props.progress? {color: "darkgrey"} : {color: "black"}}>
                    <span className="Mode-Progress" 
                    style={props.progress? {display: "absolute"} : {display: "none"}}>  
                        In Progress
                    </span>
                    <span className="Mode-Beta" 
                    style={props.special === true? {display: "absolute"} : {display: "none"}}>
                        In Beta
                    </span>
                    <span className="Mode-Title">
                        {props.title}
                    </span>
                    <br/>
                    <span className="Mode-Desc">
                        {props.desc}
                    </span>
                </button>
            </Link>
        </div>
    )
}

export default Mode;