import { Fragment, useEffect, useState } from "react";
import ArtifactImage from "./ArtifactImage";

import "./css/artifactcropper.css";

const ArtifactCropper = (props) => {
    const [localOrder, setLocalOrder] = useState(null)
    const [arti, setArti] = useState(0);
    const [loading, setLoading] = useState(true);
    const [croppedImage, setCroppedImage] = useState([]);
    const crop = { x: 0, y: 0, width: 95, height: 100 } //max 24 pieces

    const distribution = {
        1: [0,0],       2: [0,1],       3: [0,2],       4: [0,3], 
        5: [1,0],       6: [1,1],       7: [1,2],       8: [1,3], 
        9: [2,0],       10: [2,1],      11: [2,2],      12: [2,3], 
        13: [3,0],      14: [3,1],      15: [3,2],      16: [3,3], 
        17: [4,0],      18: [4,1],      19: [4,2],      20: [4,3], 
        21: [5,0],      22: [5,1],      23: [5,2],      24: [5,3], 
    }

    function craftPart(src, part, visibility, array, scale) {
        const img = new window.Image();
        img.src = src;

        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
        
            // Set canvas dimensions to the cropped size
            canvas.width = crop.width*scale;
            canvas.height = crop.height*scale;
        
            if (visibility[part]) {
                // Draw the cropped image onto the canvas
                ctx.drawImage(
                    img,
                    distribution[part][1]*crop.width, distribution[part][0]*crop.height,
                                                                crop.width, crop.height, // Source rectangle (crop)
                    0, 0, crop.width*scale, crop.height*scale                            // Destination rectangle (on canvas)
                );
    
            } else {
                ctx.fillStyle = 'black';
                ctx.fillRect(
                    0, 0, crop.width*scale, crop.height*scale
                );
            }
            
            // Convert the canvas content to a base64-encoded image
            const dataUrl = canvas.toDataURL('image/jpeg', scale);
            array.push(dataUrl)

            // recursive
            if (part < 24) {
                craftPart(props.arti.artifact[Object.keys(props.arti.artifact)],
                    part+1,
                    props.revealOrder,
                    array,
                    scale
                )
            } else {
                setArti(arti+1) //after 24th settled
            }
            return
        }
        
    }

    useEffect(() => {
        if (props.arti && localOrder !== props.revealOrder) { //croppedImage == false && 
            var allParts = []
            craftPart(props.arti.artifact[Object.keys(props.arti.artifact)],
                1,
                props.revealOrder,
                allParts,
                0.7
            )
            setCroppedImage(allParts);
            setLocalOrder(props.revealOrder)
        }

    }, [props.arti, arti, props.revealOrder])
    
    return (
        <Fragment>
            <div className="artifact-cropper">
                <ArtifactImage img={croppedImage} arti={arti} change={localOrder} />
            </div>
        </Fragment>
    )
}

export default ArtifactCropper;