import { Fragment, useEffect, useState } from "react";
import './css/index.css';
import './css/timer.css';

const Timer = (props) => {
    const [time, setTime] = useState(props.time)
    const [disrupt, setDisrupt] = useState(false)
    const [localGuess, setLocalGuess] = useState(props.guess)

    function changeTimer(n) {
        setTime(n);
    }

    function startTimer(n) { //one-time
        setTime(n);
        props.resolve();
    }

    function disruptTimer() { //it takes 1 state update to disrupt
        setDisrupt(!disrupt);
    }

    function restartTimer() { //it takes 1 state update to disrupt
        setDisrupt(!disrupt);
        setTime(props.time);
    }

    function continueTimer() {
        setDisrupt(false);
    }

    function handleGameOver() { 
        props.end();
    }

    useEffect(() => {
        // console.log("timer js", time, props.time, props.run);
        // unlimited mode reset round, in front so timer can full reset; not sure exactly but probably state 
        if (props.reset || props.guess !== localGuess) {
            restartTimer();
            setLocalGuess(props.guess);
        }
        if (props.guess !== localGuess) { // any guess reset timer, cant be placed below cause below needs return, this doesnt
            continueTimer();
        }
        // when guessed correctly or game over
        if (props.solved || props.gameover) {
            disruptTimer();
            return
        }
        // start the timer after modal options are selected, run once
        if (props.run) {
            startTimer(props.time);
            return
        }
        // check if props.time is NaN because it will only ever be that on start or if turned off
        if (!isNaN(props.time)) {
            while (time > 0 && !disrupt) {
                var timerID = setInterval(() => changeTimer(time-1000), 1000);

                return function cleanup(){
                    clearInterval(timerID);
                };
            }
            // props run is false on run then true when startTimer then back to false
            // checks props.run to prevent startTimer from calling minus() once
            // checks for disrupt to ensure timer isnt running when disrupt is true
            if (!props.run && !disrupt) {
                // deduct life from time up, then restart timer.
                if (props.lives > 1) {
                    props.minus()
                    // instead of alert maybe do animation and non-disruptive screen? But with anim reset, needs an artificial blocker
                    // alert("Time's up! You automatically lose 1 life due to not guessing.\nThe timer will restart after you close the alert.")
                    setTime(props.time)
                } else {
                // if no life left, gameover.
                    props.minus()
                    handleGameOver()
                }
            }
        }
    }, [time, props.time, props.solved, props.reset, props.guess])

    return (
        <Fragment>
            <div class="countdown-timer mx-auto mt-2" style={isNaN(props.time) ? {display: "none"} : {display: "block"}}>
                <div class="countdown-bar above-background" style={{width: `${time/props.time*100}%`}}></div>
            </div>
        </Fragment>
    )
}

export default Timer;