import crypto from 'crypto-browserify';
import publicKey from './publicKey.pem';

/**
   * Signs the given data using the stored public key.
   * @param {string} data - The input data to hash.
   * @returns {string} - The signature in hex format.
*/
export const signData = async (data) => {
    // perform one layer of hashing first
    const hash = crypto.createHash('sha256').update(data).digest('hex');
    // then encrypt / sign with keys
    const signature = await fetch(publicKey)
        .then(r => r.text())
        .then(pkey => {
        const signature = crypto.publicEncrypt(pkey, Buffer.from(hash));
        return signature.toString('hex');
    });
    return signature
};