import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import HomeHeader from "../E7dle/Components/HomeHeader";
import ArtifactCropper from "./Components/ArtifactCropper";
import DailyStats from "../Classic/Components/DailyStats";
import Lives from "../Classic/Components/Lives";
import Tutorial from "../Classic/Components/Tutorial";
import GuessFields from "../Classic/Components/GuessFields";
import Keyboard from "../Prefix/Components/Keyboard";
import GuessesShown from "./Components/GuessesShown";
import GlobalStats from "../Classic/Components/GlobalStats";
import MiniFooter from "../E7dle/Components/MiniFooter";
import { signData } from '../../Utils/cryptoUtils';
import './Components/css/index.css';
import './Components/css/phase2.css';

const Artifact = (props) => {
    // phase 1
    const [artifact, setArtifact] = useState(null);
    const [revealOrder, setRevealOrder] = useState([])
    const [revealState, setRevealState] = useState(Array(24).fill(false))
    const [currentGuess, setCurrentGuess] = useState(null)
    const [guessArray, setGuessArray] = useState([]) // name only
    // phase 2
    // const [docId, setDocId] = useState("")
    const [src, setSrc] = useState("")
    const [name, setName] = useState("")
    const [guessedLetters, setGuessedLetters] = useState([])
    const [checkLetters, setCheckLetters] = useState([])
    const [lives, setLives] = useState(5);
    // general
    const [solved1, setSolved1] = useState(false);
    const [solved2, setSolved2] = useState(false);
    const [loading, setLoading] = useState(true); //true
    const [instructions, setInstructions] = useState(false);
    const [req, setReq] = useState(0)
    // non-states
    const charWithArti = props.GetInventory.filter((char) => char.artifact);
    const charWithArtiList = charWithArti.map((char) => { return char.name });
    
    function openInst() {
        setInstructions(!instructions)
    }

    function refreshStats() {
        setReq(req+1)
    }

    async function submitResult(data1, data2, guessed) {
        const url = "https://e7dle-backend.uc.r.appspot.com/api/artifact/submit"
        try {
            const jsonBody = JSON.stringify({
                "guessArray": data1,
                "letters": data2,
                "guessed": guessed
            });
            const response = await signData(jsonBody)
            .then((signedBody) => {
                var res = fetch(url, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json', // Send as JSON
                        'Signature': signedBody
                    },
                    body: jsonBody
                })
                return res
            })
            return response

        } catch (err) {
            console.log(err)
            return false
        }
    }

    // PART 1 - GUESS CHARACTER ARTIFACT
    function checkSolve1() {
        setSolved1(true)
        setSrc(Object.values(artifact.artifact)) // only sets after first part done, also is an array? somehow works
        var name = Object.keys(artifact.artifact)[0]
        setName(name)
        // above is unintended fix for cheating

        // setup for part 2
        var tempCheck = new Set([...name])
        tempCheck = Array.from(tempCheck).filter((p) => Object.values(alphabets).includes(p.toUpperCase()))
        tempCheck = tempCheck.map((p) => p.toUpperCase())
        setCheckLetters(tempCheck)
    }

    function processGuess(guess) {
        // process guess
        if (currentGuess === null) {
            var retrievedGuessInfo = props.GetInventory.filter(char => char.name.toLowerCase() == guess)
            // pass this object into guesseswritten
            setCurrentGuess(retrievedGuessInfo[0])
            // fake check solve
            if (retrievedGuessInfo[0] == artifact) {
                checkSolve1()
            }
            var tempArray = guessArray.slice()
            tempArray.push(retrievedGuessInfo[0].name)
            setGuessArray(tempArray)
            // handle artifact cropping
            updateRevealState(revealOrder, tempArray)
        } else { 
            // dont retrieve if same guess, also need make sure haven't guessed before
            if (guess !== currentGuess.name.toLowerCase() && !guessArray.includes(guess)) {
                var retrievedGuessInfo = props.GetInventory.filter(char => char.name.toLowerCase() == guess)
                setCurrentGuess(retrievedGuessInfo[0])
                // fake check solve
                if (retrievedGuessInfo[0] == artifact) {
                    checkSolve1()
                }
                var tempArray = guessArray.slice()
                tempArray.push(retrievedGuessInfo[0].name)
                setGuessArray(tempArray)
                // handle artifact cropping
                updateRevealState(revealOrder, tempArray)
            }
        }
    }

    function shuffleArrayFixed(seed) {
        const pattern_1 = [13, 24, 5, 11, 2, 22, 10, 16, 21, 12, 15, 1, 19, 9, 8, 23, 18, 7, 20, 4, 6, 14, 17, 3]
        const pattern_2 = [12, 9, 3, 24, 15, 7, 11, 14, 23, 18, 19, 21, 17, 16, 1, 20, 6, 8, 22, 5, 4, 2, 10, 13]
        const pattern_3 = [21, 10, 13, 7, 18, 5, 20, 17, 6, 9, 11, 19, 24, 14, 16, 1, 2, 12, 15, 8, 22, 4, 3, 23]
        const pattern_4 = [3, 23, 10, 13, 19, 4, 12, 17, 11, 9, 8, 21, 1, 22, 14, 20, 24, 6, 7, 15, 18, 5, 2, 16]
        const pattern_5 = [14, 13, 16, 18, 11, 15, 24, 10, 5, 19, 7, 21, 4, 3, 17, 1, 2, 22, 20, 8, 12, 9, 6, 23]
        const pattern_6 = [22, 21, 2, 4, 10, 12, 20, 23, 13, 19, 16, 24, 8, 7, 3, 17, 5, 18, 11, 14, 15, 6, 9, 1]
        const pattern_7 = [23, 8, 15, 11, 24, 4, 14, 18, 19, 12, 6, 10, 20, 9, 16, 2, 7, 13, 22, 17, 1, 5, 3, 21]
        const pattern_8 = [8, 13, 24, 10, 21, 16, 14, 11, 7, 18, 15, 3, 5, 2, 17, 23, 12, 9, 6, 19, 22, 4, 1, 20]
        const pattern_9 = [17, 12, 15, 10, 8, 23, 24, 5, 19, 13, 21, 18, 20, 1, 22, 2, 3, 7, 4, 14, 16, 6, 9, 11]
        const pattern_10 = [8, 1, 13, 11, 6, 22, 20, 15, 24, 7, 4, 3, 14, 17, 12, 23, 10, 9, 18, 19, 21, 5, 16, 2]
        var patterns = [pattern_1, pattern_2, pattern_3, pattern_4, pattern_5,
                        pattern_6, pattern_7, pattern_8, pattern_9, pattern_10]
        return patterns[seed%10]
    }

    function updateRevealState(tempOrder, tempArray) {
        const nthReveal = tempArray.length
        var tempState = revealState.slice()
        tempState[tempOrder[nthReveal]] = true
        setRevealState(tempState)
    }

    // PART 2 - GUESS ARTIFACT NAME
    const alphabets = {
        "letter-1": 'A', "letter-2": 'B', "letter-3": 'C', 
        "letter-4": 'D', "letter-5": 'E', "letter-6": 'F', 
        "letter-7": 'G', "letter-8": 'H', "letter-9": 'I', 
        "letter-10": 'J', "letter-11": 'K', "letter-12": 'L', 
        "letter-13": 'M', "letter-14": 'N', "letter-15": 'O', 
        "letter-16": 'P', "letter-17": 'Q', "letter-18": 'R', 
        "letter-19": 'S', "letter-20": 'T', "letter-21": 'U', 
        "letter-22": 'V', "letter-23": 'W', "letter-24": 'X', 
        "letter-25": 'Y', "letter-26": 'Z', 
    }

    const isSubset = (A, B) => {
        // Use every() method to check if every element in B is in A
        return B.every(element => A.includes(element));
    }

    function tabulateLetters(A, B) { // B (guessed) subset of A (actual/check)
        const compareLetters = new Set(B)
        const baseLetters = new Set([...A].map((p) => p.toUpperCase()))
        if (isSubset(Array.from(compareLetters), Array.from(baseLetters))) {
            checkSolve2(B, true)
        }
    }

    function pressLetter(e) {
        const id = e.target.id
        const letter = alphabets[id]

        // handles characters that are not selected before
        var tempArray = guessedLetters.slice()
        if (!guessedLetters.includes(letter)) {
            tempArray.push(letter)
            setGuessedLetters(tempArray)

            // handles checking if letters are right or wrong
            if (checkLetters.includes(letter)) {
                document.querySelector(`#${id}`).className = "right-pressed";
                tabulateLetters(checkLetters, tempArray)
            } else {
                document.querySelector(`#${id}`).className = "wrong-pressed";
                if (lives == 1) {
                   checkSolve2(tempArray, false)
                }
                setLives(lives-1)
            }
        }
        
    }

    function checkSolve2(tempLetters, guessed) {
        setSolved2(guessed)
        var currObj = JSON.parse(localStorage['arti'])
        currObj['solve'] = [true,guessed]
        currObj['guesses'] = guessArray
        currObj['letters'] = tempLetters
        localStorage['arti'] = JSON.stringify(currObj)
        // api call - guessArray, letters, guessed
        try {
            submitResult(guessArray, tempLetters, guessed)
        } catch (err) { console.log(err) }
    }

    // OTHERS
    // note: this is the only localstorage with non-full spelling, eg. classic and skill are as such, 'artifact' is abr as 'arti'.
    useEffect(() => {
        function refresh(res_seed) {
            const seed = (res_seed + 31) % charWithArti.length
            const tempHolder = charWithArti[seed]
            setArtifact(tempHolder) //affected by guessskill, some shared variables (?)
            return tempHolder.artifact
        }
        // retrieve seed from API
        fetch("https://e7dle-backend.uc.r.appspot.com/api/seed").then((res) => {
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json();
        }).then((data) => {
            // check if seed same as yesterday's
            if (localStorage['arti']) {
                var currObj = JSON.parse(localStorage['arti']);
                    if (currObj['seed'] != data.seed) {
                        // refresh if not
                        refresh(data.seed)
                        // refresh localstorage['arti']
                        var tempObj = {}
                        tempObj['seed'] = data.seed
                        tempObj['solve'] = [null]
                        localStorage['arti'] = JSON.stringify(tempObj)
                    } else {
                        // check if game is played
                        if (currObj['solve'][0] !== null) {
                            // yes; set game as over
                            var ansName = refresh(data.seed)
                            setName(Object.keys(ansName))
                            setSolved1(currObj['solve'][0]) //always true
                            if (currObj['solve'][1]) {
                                setSolved2(currObj['solve'][1])
                            } else {
                                setLives(0) // need to activate dead screen
                            }

                        } else refresh(data.seed) // no; nothing
                    }
            } else {
                var tempObj = {}
                tempObj['seed'] = data.seed
                tempObj['solve'] = [null]
                localStorage['arti'] = JSON.stringify(tempObj)
                refresh(data.seed)
            }

            // set order of appearance
            var tempOrder = shuffleArrayFixed(data.seed)
            setRevealOrder(tempOrder)
            updateRevealState(tempOrder, [])
            
            // loading
            setLoading(false)
            
        }).catch((err) => {
            console.log(err);
        });
        
    }, [])

    return (
        <Fragment>
        <div className="overlay">
        <div className="artifact-guesser">
            <div className="homepage-header container">
                <HomeHeader />
            </div>
            <div className="artifact-solved above-background" style={((solved1 && solved2) && !loading)? {display: "block"} : {display:"none"}}>
                <span>The artifact was:</span>
                <span>{name? name: ""}</span>
                <span id="classic-sol-img">
                    <img src={artifact? process.env.PUBLIC_URL+artifact.image[0]: ""} alt="hero-answer" />
                </span>
                <span id="artifact-sol-img">
                    <img src={artifact? process.env.PUBLIC_URL+Object.values(artifact.artifact)[0]: ""} alt="art-answer"/>
                </span>
            </div>
            <div className="artifact-unsolved above-background" style={(lives < 1 && !loading)? {display: "block"} : {display:"none"}}>
                <span>The artifact was:</span>
                <span>{name? name: ""}</span>
                <span>
                    {[...Array(100).keys()].map((x) => {
                        return ("GAME OVER")
                    }).join(" ")}
                </span>
                <span id="classic-sol-img">
                    <img src={artifact? process.env.PUBLIC_URL+artifact.image[0]: ""} alt="hero-answer" />
                </span>
                <span id="artifact-sol-img">
                    <img src={artifact? process.env.PUBLIC_URL+Object.values(artifact.artifact)[0]: ""} alt="art-answer" />
                </span>
            </div>
            <div className="artifact-guesser-info above-background" style={(((solved1 && solved2) || lives < 1) || loading)? {display: "none"} : {display:"block"}}>
                {!solved1? "Guess whose Artifact" : "Name the Artifact"}
            </div>
            <div className="artifact-part-1 above-background" style={(solved1 || loading)? {display: "none"} : {display:"block"}}>
                <ArtifactCropper arti={artifact} revealOrder={revealState} /> 
                <div className="classic-fields above-background">
                    <div className="classic-char-info">
                        <FontAwesomeIcon icon={faQuestionCircle} />
                        <span></span>
                    </div>
                    <GuessFields list={charWithArtiList} func={processGuess} display={true} />
                </div>
                <GuessesShown guessArray={guessArray} charWithArti={charWithArti}  />
            </div>
            <div className="artifact-part-2 above-background" style={((solved1 && !solved2 && lives >= 1) && !loading)? {display: "block"} : {display:"none"}}>
                <div className="artifact-hangman">
                    {
                        [...name].map((p) => {
                            return <span>
                                {
                                guessedLetters.includes(p.toUpperCase())? p : 
                                    p === " " ? <span>&nbsp;&nbsp;</span> :
                                        !Object.values(alphabets).includes(p.toUpperCase())? p :
                                        "_"
                                }
                                </span>
                        })
                    }
                </div>
                <div className="text-center">
                    <img src={src} alt="artifact-full-art" width={"190"} />
                </div>
                <Keyboard press={pressLetter} />
                <Lives lives={lives} fixedLives={5} />
            </div>
            <div className="artifact-extra" style={((solved1 && (solved2 || lives < 1)) || loading)? {display: "none"} : {display:"block"}}>
                {/* switch between apis during different mode? */}
                <DailyStats api={"https://e7dle-backend.uc.r.appspot.com/api/artifact/stats"}/>
                <div className="text-center">
                    <text className="text-white" style={{textDecoration: "underline", cursor: "pointer", zIndex: "200", position: "relative"}} 
                    onClick={openInst}>How to play?</text>
                </div>
                <Tutorial open={openInst} inst={instructions} page={!solved1? "artifact-1" : "artifact-2"}/>
            </div>
        </div>
        {/* end of artifact */}
        <div className="text-center mt-5 mb-5" style={loading? {display: "block"} : {display: "none"}}>
            <img src={"loading-gif.gif"} alt="loading..." />
        </div>
        <div className="classic-extra-stats above-background" style={((solved1 && (solved2 || lives < 1)) && !loading)? {display: "block"} : {display: "none"}}>
            <GlobalStats solved={solved2} gameOver={false} ans={artifact} page={'arti'} req={req} reload={refreshStats}
                        list={charWithArtiList} default={true}  api={"https://e7dle-backend.uc.r.appspot.com/api/artifact/stats"} />
        </div>
        </div>
        {/* end of overlay */}
        <MiniFooter />
        </Fragment>
    )
}
const mapStateToProp = state => ({
    GetInventory: state.ProxyData.GetInventoryAfter
})

export default connect(mapStateToProp)(Artifact);