import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import '../Components/css/globalstats.css';

import PieChart from "./PieChart";
Chart.register(CategoryScale);

const GlobalStats = (props) => {
    const [loading, setLoading] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [activateCount, setActivateCount] = useState(0);
    const [totalPlays, setTotalPlays] = useState(0);
    // const [commonGuess, setCommonGuess] = useState([]);
    // const [commonCount, setCommonCount] = useState(0);
    const [averageGuess, setAverageGuess] = useState(0);
    const [yourGuess, setYourGuess] = useState([]);
    // for the charts, will replace the ones above
    const [pieChartData, setPieChartData] = useState({
        labels: [],
        datasets: []
    })

    Array.prototype.max = function() {
        return Math.max.apply(null, this);
    }
    
    const toggleDropdown = () => {
        setDropdown(!dropdown)
    }

    useEffect(() => {
        setLoading(true);
        if (activateCount < 1) {
            setActivateCount(activateCount+1)
        } else {
            try {
                fetch(props.api)
                    .then((res) => {
                        if (!res.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return res.json();
                    }).then(data => {
                        //total plays
                        var totalPlays = data.totalPlays
                        setTotalPlays(totalPlays)

                        //most common guess
                        //unexpected fights against xss because of how it checks against actual list
                        var filteredData;
                        if (props.default) {
                            filteredData = data.occurrences.filter((entry) => props.list.includes(entry[0]) && entry[0] !== props.ans.name)
                        } else {
                            filteredData = data.occurrences.filter((entry) => props.list.includes(entry[0]))
                        }
                        
                        var occurrenceDict = {}
                        for (let i = 0; i < filteredData.length; i++) {
                            var [name, value] = filteredData[i]
                            if (occurrenceDict[value] !== undefined) {
                                occurrenceDict[value].push(name)
                            } else {
                                occurrenceDict[value] = [name]
                            }
                        }
                        
                        var dictLen = Object.keys(occurrenceDict).length
                        if (dictLen >= 1) {
                            const max = Object.keys(occurrenceDict).max() // err if only correct ans
                            var remainder = filteredData.length >= 3? filteredData.length - 3 : 0
                            // setCommonCount(max)
                            // setCommonGuess(occurrenceDict[max])
                            setPieChartData({
                                labels: filteredData.slice(0,3).map((i) => i[0]).concat("Others"),
                                datasets: [
                                {
                                    label: "Guessed ",
                                    // data: [...Array(charMost.length)].fill(max),
                                    data: filteredData.slice(0,3).map((i) => i[1]).concat(filteredData.slice(3,3+remainder).reduce((x, i) => x + i[1], 0)),
                                    backgroundColor: ["#d4af37","#aaa9ad","#a97142"].concat([...Array(remainder)].fill("#ecf0f1")),
                                    borderColor: "black",
                                    borderWidth: 2
                                }
                                ]
                            })
                        }
                        // re-render for it to appear
                        document.querySelector(".global-stats-board .chart-container canvas").style.width = "15em";
                        document.querySelector(".global-stats-board .chart-container").style.marginRight = "auto";
                        document.querySelector(".global-stats-board .chart-container").style.marginLeft = "auto";
                        
                        //average guess
                        data.avgGuessedData?
                            setAverageGuess(data.avgGuessedData)
                            :
                            setAverageGuess(0);

                        //user guess
                        var tempObj = JSON.parse(localStorage[props.page])
                        setYourGuess(tempObj['guesses'])
    
                    }).catch(error => {
                        console.error('There was a problem with the fetch operation:', error);
                    });
            } catch(err) {
                console.log(err)
            }
        }
        setLoading(false);

    }, [props.solved, props.gameOver, props.req])

    return (
        <Fragment>
            <div className="classic-global-stats text-white mt-2 mb-2">
                <div className="global-stats-button" style={dropdown? {display: "none"} : {display: "block"}} onClick={toggleDropdown}>
                    Click to view stats
                </div>
                <div className="global-stats-button" style={dropdown? {display: "block"} : {display: "none"}} onClick={toggleDropdown}>
                    Click to hide stats
                </div>
                <div className="global-stats-board" style={dropdown? {display: "block"} : {display: "none"}}>
                    <span id="refresh-stats">
                        <button onClick={props.reload}>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </button>
                    </span>
                    <div className="row" style={loading? {display: "none"} : {display: "flex", justifyContent: "center"}}>
                        <div className="col-sm-12 col-md-5">
                            <div className="row">
                                <PieChart chartData={pieChartData}/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7 row">
                            <div className="col-sm-12 col-md-4 align-self-center">
                                <div className="align-self-center">Your guesses</div>
                                {yourGuess?
                                    yourGuess.map((char) => {
                                        var charInfo = props.GetInventory.filter(char2 => char2.name === char)
                                        return <img src={charInfo[0].icon} alt={charInfo[0].name} width="45px"/>
                                    })
                                :"NaN"}
                            </div>
                            <div className="col-sm-12 col-md-4 align-self-center">
                                <div className="align-self-center">Plays Today</div>
                                <text style={{fontSize:"xx-large"}}>{totalPlays}</text>
                                <hr color="white"/>
                                <div className="align-self-center">Average Successful Guesses</div>
                                <text style={{fontSize:"xx-large"}}>{averageGuess? averageGuess.toFixed(2) : NaN}</text>
                            </div>
                            <div className="col-sm-12 align-self-center">

                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-5 mb-5" style={loading? {display: "block"} : {display: "none"}}>
                        <img src={"loading-gif.gif"} alt="loading..." />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProp = state => ({
    GetInventory: state.ProxyData.GetInventoryAfter
})

export default connect(mapStateToProp)(GlobalStats);