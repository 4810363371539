import { Fragment } from "react";
import './css/localstats.css'

const LocalStats = () => {
    // simulate backend call
    if (localStorage['unlimited']) {
        var totalCount = parseInt(JSON.parse(localStorage['unlimited'])['plays']);
        var averageGuess = (parseInt(JSON.parse(localStorage['unlimited'])['guesses']) / totalCount).toFixed(2);
    } else {
        var totalCount = 0;
        var averageGuess = 0;
    }

    return (
        <Fragment>
            <div className="local-stats above-background">
            <b>
            Score: {totalCount+" "} 
            |
            {" "+averageGuess+" "} average guesses
            </b>
            </div>
        </Fragment>
    )
}

export default LocalStats;