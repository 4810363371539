import { useState } from 'react';
import { CardBody } from 'reactstrap';
//css
import './styles/dropdown.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

const Input = (props) => {
    const [openAnswer, setOpenAnswer] = useState(false)
    const [chevron, setChevron] = useState(false)
    const openAnswerBody = () => {
        setOpenAnswer(!openAnswer)
        setChevron(!chevron)
    }
    return (
        <div className="dropdown-component">
            <button className="dropdown-button text-white" onClick={openAnswerBody}>
                <span className="dropdown-icon" style={chevron === true? {display: "block"} : {display: "none"}}>
                    <FontAwesomeIcon icon={faChevronUp} />
                </span>
                <span className="dropdown-icon" style={chevron === true? {display: "none"} : {display: "block"}}>
                    <FontAwesomeIcon icon={faChevronDown} />
                </span>
                {props.question}
            </button>
            <CardBody className="dropdown-answer text-white" 
            style={openAnswer === true? {display: "block"} : {display: "none"}}>
                {props.answer}
            </CardBody>
        </div>
    )
}

export default Input;