import { Fragment, useState } from "react";
// components
// import { DropdownItem, DropdownMenu } from "reactstrap";
//css
import '../../Sorter/Components/css/menu.css';
import '../Components/css/customizemenu.css';

const CustomizeMenu = (props) => {
    const [modalOpened, setModalOpened] = useState(true)
    const [livesOption, setLivesOption] = useState(props.lives)
    const [timeOption, setTimeOption] = useState(null)

    const openModal = () => {
        setModalOpened(!modalOpened)
        props.customizeLives(livesOption)
        props.customizeTimeLimit(timeOption)
    }

    const changeLives = (e) => {
        setLivesOption(e.target.value)
    }

    const changeTime = (e) => {
        setTimeOption(e.target.value)
    }

    return(
        <Fragment>
            <div id="simpleModal" style={modalOpened === true? {display: "block"} : {display: "none"}}>
                <div id="modal-content">
                    <div className="container pt-5 pb-5">

                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 bg-dark px-5 rounded z-depth-5 mt-5" id="whole_box">
                                {/* <span id="closeBtn" onClick={openModal}>&times;</span> */}
                                
                                <div className="row">
                                    <div className="card-header bg-dark mt-4" id="unlimited-settings">
                                        <h1 className="text-white">Settings</h1>
                                        <div className="col-sm-12 border border-bottom border-warning"></div>
                                        <br/>
                                    </div>
                                </div>
                                <div className="row unlimited-customize" id="unlimited-customize-lives">
                                    <div className="col-lg-6 col-md-6 text-white text-center">
                                        Lives
                                    </div>
                                    <div className="col-lg-6 col-md-6 text-white text-center">
                                        <select name="lives" onChange={changeLives}>
                                            {[...Array(7).keys()].map((i) => {
                                                return (
                                                <option key={"lives-opt-"+i} value={i+1}
                                                selected={i === 6? true : false}    >{i+1}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="row unlimited-customize" id="unlimited-customize-time">
                                    <div className="col-lg-6 col-md-6 text-white text-center">
                                        Time (seconds)
                                    </div>
                                    <div className="col-lg-6 col-md-6 text-white text-center">
                                        <select name="time" onChange={changeTime}>
                                            <option key={"time-opt-0"} value={null} defaultValue>None</option>
                                            <option key={"time-opt-1"} value={30000}>30</option>
                                            <option key={"time-opt-2"} value={45000}>45</option>
                                            <option key={"time-opt-3"} value={60000}>60</option>
                                            <option key={"time-opt-4"} value={90000}>90</option>
                                            <option key={"time-opt-5"} value={120000}>120</option>
                                            <option key={"time-opt-6"} value={180000}>180</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row" id="unlimited-customize-submit">
                                    <button className=" bg-warning" onClick={openModal}>Start</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </div>
        </Fragment>
    )
}
export default CustomizeMenu;