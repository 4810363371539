import { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import './css/notification.css';

const Notification = () => {
    const [close, setClose] = useState(false)

    function closeNotif() { // button press
        const notif = JSON.parse(localStorage['notif']);
        localStorage['notif'] = JSON.stringify({
            'close': true,
            'timestamp': Date.now(),
            'closeScreen': notif.closeScreen,
        });
        setClose(true)
    }

    useEffect(() => {
        if (localStorage['notif']) {
            const notif = JSON.parse(localStorage['notif']);
            if (Date.now() - notif.timestamp <= 86400000) {
                setClose(true); //needed because on page load is default false aka viewable
                                // side effect is it will close itself after reload
                                // doing it the other way round, will make it never show itself on load
            }
        } else { // first time
            localStorage['notif'] = JSON.stringify({
                'close': false,
                'timestamp': Date.now(),
                'closeScreen': false,
            });
        }
    }, [])

    return (
        <Fragment>
            <div className="notification mt-1 mb-1" style={close? {display: "none"}:{display: "flex"}}>
                <div className="scrolling-text-container above-background bg-primary">
                    <text className="scrolling-text text-white">
                        {/* max 100% length is ~192 char */}
                        This site is still a work-in-progress, there may be many bugs such as buttons not functioning. 
                        The interfaces and visual design are not final either, I would love to hear some feedbacks.
                        Please submit such bugs or suggestions <Link to={"/feedback"}>here</Link>.
                        While I've checked, I apologize in advance if there are any wrong character information.
                    </text>
                </div>
                <button className="above-background" onClick={closeNotif}><FontAwesomeIcon icon={faTimesCircle} size={"2x"}/></button>
            </div>
        </Fragment>
    )
}

export default Notification;