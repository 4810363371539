import { Fragment, useState } from "react";
// components
// import Notify from "../../../Components/Notify";
import Dropdown from "../../../Components/Dropdown";
// import Update from "./Update";
//css
import './css/menu.css';

const Menu = () => {
    const [modalOpened, setModalOpened] = useState(false)
    const openModal = (e) => {
        setModalOpened(!modalOpened)
    }
    return(
        <Fragment>
            <button id="modalBtn" onClick={openModal}>
                {!modalOpened? 
                    <img src={process.env.PUBLIC_URL+ '/book_2.png'} height="30px"></img> : 
                    <img src={process.env.PUBLIC_URL+ '/open_book_2.png'} height="30px"></img>
                }
                <br/>Info
            </button>
            <div id="simpleModal" style={modalOpened === true? {display: "block"} : {display: "none"}}>
                <div id="modal-content">
                    <div className="container pb-5">

                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 bg-dark px-5 rounded z-depth-5 mt-5" id="whole_box">
                                <span id="closeBtn" onClick={openModal}>&times;</span>
                                
                                <div className="row">
                                    <div className="card-header bg-dark mt-4">
                                        <h1 className="text-white">Info</h1>
                                        <div className="col-sm-12 border border-bottom border-warning"></div>
                                        <br/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 border-right border-warning">
                                        <Dropdown question="What is this?" 
                                        answer="This is what is known as a character sorter. 
                                        
                                        What you do is select one out of two given choices, and this process repeats until you have gone through all possible permutation. 
                                        
                                        What this achieves is to let a user ultimately choose their favourite character out of the bunch that they normally would not be able to at one glance."/>
                                        
                                        <Dropdown question="Sorting Options" 
                                        answer={
                                            <ul>
                                                <li>Select the characters you want to sort by toggling Off/On on each option. 
                                                    Off is red on the left, and On is green on the right. </li>
                                                <li>The 3* character pool contains only specialty-changed characters</li>
                                                <li>Click on the start button when you're done selecting.</li>
                                            </ul>
                                        }/>
                                        <Dropdown question="Sorting Process" 
                                            answer={
                                                <ul>
                                                    <li>Click on the box which you like the character more</li>
                                                    <li>Undo allows you to repick the previous battle again</li>
                                                    <li>Battle # is also known as 'permutation' or number of choices you've made</li>
                                                    <li>% sorted. shows how far you are from finishing </li>
                                                </ul>
                                            }/>
                                    </div>

                                    <div className="col-lg-6 col-md-6 mt-2 text-white">
                                        This is a past project completed in 2022, as such there may be some broken features.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </div>
        </Fragment>
    )
}
export default Menu;