import { Fragment, useEffect, useRef } from "react";
import { Table } from "reactstrap";
import '../../GuessSkill/Components/css/guessesshown.css';

const GuessesShown = (props) => {
    const scrollContainerRef = useRef(null);

    useEffect(() => {

        const scrollTimeout = setTimeout(() => {
            const scrollWidth = document.querySelector("div#shownGuesses-artifact-guesses").scrollWidth

            if (scrollContainerRef.current) {
                scrollContainerRef.current.scrollTo({
                    left: -scrollWidth,
                    behavior: 'smooth',
                });
            }
        }, 100);

        return () => clearTimeout(scrollTimeout);

    }, [props.guessArray])
    
    return (
        <Fragment>
            <Table className="mx-auto" style={{width: "50%", marginTop: "30px"}}>
                <thead> 
                    <tr className="text-white"> 
                        <th>Characters Guessed</th>
                    </tr>
                </thead>
                <tbody id="shownGuesses">
                    <tr>
                        <td className="guess-shown-boxes skill-false-guess">
                            <div 
                                id="shownGuesses-artifact-guesses" 
                                ref={scrollContainerRef}
                                style={{overflowY: "hidden", maxHeight: "150px", flexDirection: "row-reverse", display: "flex"}}
                            >
                                {props.guessArray.map((check) => {
                                    const filteredChar = props.charWithArti.filter((char) => char.name.toLowerCase() == check.toLowerCase())
                                    return (
                                        <img src={filteredChar[0].icon} 
                                            alt={filteredChar[0].name+"-icon"}
                                            // width={80}
                                        >
                                        </img>
                                    )
                                })}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Fragment>
    )
}

export default GuessesShown;