import { Fragment } from "react";
import "./css/minifooter.css";

const MiniFooter = () => {
    
    return (
        <Fragment>
            <div className="e7dle-footer above-background">
              E7dle © 2024
            </div>
        </Fragment>
    )
}

export default MiniFooter;