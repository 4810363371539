import { Fragment } from 'react';
import { connect } from 'react-redux';
import AppMain from './Layout/App';

function App(props) {
  return (
      <Fragment>
          <AppMain/>
      </Fragment>
  );
}

//redux store
const mapStateToProp = state => ({
  AppVersion: state.ProxyData.AppVersion,
})

export default connect(mapStateToProp)(App);
