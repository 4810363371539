import { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import HomeHeader from "../E7dle/Components/HomeHeader";
import GuessFields from "../Classic/Components/GuessFields";
import DailyStats from "../Classic/Components/DailyStats";
import Tutorial from "../Classic/Components/Tutorial";
import Panel from "./Components/Panel";
import Hints from "./Components/Hints";
import GuessesShown from "./Components/GuessesShown";
// import GuessesShown from "../Artifact/Components/GuessesShown";
import GlobalStats from "../Classic/Components/GlobalStats";
import MiniFooter from "../E7dle/Components/MiniFooter";
import { signData } from '../../Utils/cryptoUtils';
import './Components/css/index.css';

const GuessSkill = (props) => {
    const [loading, setLoading] = useState(true)
    const [solved, setSolved] = useState(false)
    const [gameOver, setGameOver] = useState(false)
    const [config, setConfig] = useState([])
    // all 3 characters set after getting api seed
    const [chosenChar, setChosenChar] = useState(null)
    const [chosenChar2, setChosenChar2] = useState(null)
    const [chosenChar3, setChosenChar3] = useState(null)
    // gonna revamp these to numbers (s1,2,3) instead of the images because they can be taken from chosenChar
    const [retainNumber, setRetainNumber] = useState([])
    const [skill1, setSkill1] = useState("") // image url
    const [skill2, setSkill2] = useState("") // image url
    const [skill3, setSkill3] = useState("") // image url
    // states used for this mode
    const [guessesMade, setGuessesMade] = useState(0)
    const [currentGuess, setCurrentGuess] = useState(null) // name only
    const [guessArray, setGuessArray] = useState([])
    const [req, setReq] = useState(0)
    const [instructions, setInstructions] = useState(false)
    // new implementations for 3 char
    const [guessCounter, setGuessCounter] = useState(0)
    const [panel, setPanel] = useState(false) // 1, 2 or 3
    const [skillSelected, setSkillSelected] = useState(null); // 'S1', 'S2' or 'S3'
    const skill_array = ['S1', 'S2', 'S3']
    const list = props.GetInventory.map((char) => { return char.name })

    function refreshStats() {
        setReq(req+1)
    }

    function openInst() {
        setInstructions(!instructions)
    }

    function selectPanel(n) {
        if (panel === n) {
            // deselects panel
            setPanel(false)
            setSkillSelected(null) // can place outside statement if no condition
        } else {
            // select /change a panel
            setPanel(n)
            setSkillSelected(null)
        }
    }

    function selectSkill(n) {
        setSkillSelected(n)
    }

    async function submitResult(data, guessed) {
        const url = "https://e7dle-backend.uc.r.appspot.com/api/skill/submit"
        try {
            const jsonBody = JSON.stringify({
                "guessArray": data,
                "guessed": guessed
            });
            const response = await signData(jsonBody)
            .then((signedBody) => {
                fetch(url, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json', // Send as JSON
                        'Signature': signedBody
                    },
                    body: jsonBody
                })
                return "success"
            })

        } catch (err) {
            console.log(err)
        }
    }

    function checkSolve(b) {
        var data = guessArray.map((name) => {
            var entry = props.GetInventory.filter((char) => char.name.toLowerCase() === name)[0]
            return entry.name
        })
        var currObj = JSON.parse(localStorage['skill'])
        if (b) {
            setSolved(true)
            currObj['solve'] = [true, true, true]
        } else {
            setGameOver(true)
            if (currObj['solve'][0] === null) {
                currObj['solve'][0] = false
            }
            if (currObj['solve'][1] === null) {
                currObj['solve'][1] = false
            }
            if (currObj['solve'][2] === null) {
                currObj['solve'][2] = false
            }
            setConfig([currObj['solve'][0] , currObj['solve'][1], currObj['solve'][2]])
        }
        currObj['guesses'] = data
        localStorage['skill'] = JSON.stringify(currObj)
        try {
            submitResult(data, b)
            setReq(req+1)
        } catch (err) { console.log(err) }
    }

    function processGuess(guess) { // ADD: check for char and skill number, if correct, settle the respective panel
        // if not null do search for name and get all traits
        if (panel && skillSelected === null) { // when they use selection and skill number is not inputted, not valid
            alert("You can only guess without picking an icon, or pick an icon and guess the which skill it is (1, 2 or 3)."+
                " Check 'How to play?' for more rules explanation.") //change to frontend next time
            return
        } else if (currentGuess === null) {
            var retrievedGuessInfo = props.GetInventory.filter(char => char.name.toLowerCase() == guess)
            // pass this object into guesseswritten
            setCurrentGuess(retrievedGuessInfo[0])
            setGuessesMade(guessesMade+1)
            var tempArray = guessArray.slice()
            tempArray.push(guess)
            setGuessArray(tempArray)
        } else { 
            // dont retrieve if same guess, also need make sure haven't guessed before
            // if (guess !== currentGuess.name.toLowerCase() && !guessArray.includes(guess)) {
            if (guess !== currentGuess.name.toLowerCase()) {
                var retrievedGuessInfo = props.GetInventory.filter(char => char.name.toLowerCase() == guess)
                setCurrentGuess(retrievedGuessInfo[0])
                setGuessesMade(guessesMade+1)
                var tempArray = guessArray.slice()
                tempArray.push(guess)
                setGuessArray(tempArray)
            }
        }
        // increment counter for panel to recognize so it doesnt have to use props.guess, which affects back-to-back same guesses
        setGuessCounter(guessCounter+1)
    }

    function cleanupProcessGuess() {
        // after each guess should reset skillSelected so it doesn't allow users to guess on completed answers
        setPanel(false)
        setSkillSelected(null)
    }

    useEffect(() => {
        function refresh(res_seed) {
            // first
            const seed1 = (res_seed + 9) % props.GetInventory.length
            const tempHolder1 = props.GetInventory[seed1]
            const seed1_1 = seed1%6%3
            setChosenChar(tempHolder1)
            // second
            const seed2 = (res_seed + 91) % props.GetInventory.length
            const tempHolder2 = props.GetInventory[seed2]
            const seed2_2 = seed2%30%3
            setChosenChar2(tempHolder2)
            // third
            const seed3 = (res_seed + 47) % props.GetInventory.length
            const tempHolder3 = props.GetInventory[seed3]
            const seed3_3 = seed3%18%3
            setChosenChar3(tempHolder3)

            // const tempHolder = props.GetInventory[seed]
            // setChosenChar(tempHolder) //affected by guessskill, some shared variables (?)
            setRetainNumber([seed1_1, seed2_2, seed3_3]) //the decider for the skills of each char, saved as state in between cycles

            return tempHolder1.name //used once, but not that important can change ltr
        }
        if (chosenChar !== null && chosenChar2 !== null && chosenChar3 !== null) {
            setSkill1(chosenChar.skill[retainNumber[0]]) //how did this crash?
            setSkill2(chosenChar2.skill[retainNumber[1]])
            setSkill3(chosenChar3.skill[retainNumber[2]])
            // help out rendering if game over, dk if has race conditions due to state update then calling refresh seed
            if (gameOver || solved)
            {
                var currObj = JSON.parse(localStorage['skill']);
                setConfig([currObj['solve'][0] , currObj['solve'][1], currObj['solve'][2]])
            }
            
        } else {
            // retrieve seed from API
            fetch("https://e7dle-backend.uc.r.appspot.com/api/seed").then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
            }).then((data) => {
                // check if seed same as yesterday's
                if (localStorage['skill']) {
                    var currObj = JSON.parse(localStorage['skill']);
                    if (currObj['seed'] != data.seed) {
                        // refresh if not
                        refresh(data.seed)
                        // refresh localstorage['skill']
                        var tempObj = {}
                        tempObj['seed'] = data.seed
                        tempObj['solve'] = [null, null, null]
                        localStorage['skill'] = JSON.stringify(tempObj)

                    } else {
                        // check if game is played
                        if (!currObj['solve'].includes(null)) {

                            if (currObj['solve'][0] && currObj['solve'][1] && currObj['solve'][2]) {
                                setSolved(true)
                            } else {
                                setGameOver(true)
                            }
                            refresh(data.seed)

                        } else { // remove localstorage and start fresh, easier to do than keep states
                            // might do keep states for future implementations, depends on time and priority
                            currObj['solve'] = [null, null, null]
                            localStorage['skill'] = JSON.stringify(currObj)
                            refresh(data.seed) // no; nothing
                        }
                    }
                } else {
                    var tempObj = {}
                    tempObj['seed'] = data.seed
                    tempObj['solve'] = [null, null, null]
                    localStorage['skill'] = JSON.stringify(tempObj)
                    refresh(data.seed)
                }
                
                // loading
                setLoading(false)

            }).catch((err) => {
                console.log(err);
            });
        }
    }, [retainNumber])

    return (
        <Fragment>
        <div className="overlay">
        <div className="guess-skill">
            <div className="homepage-header container">
                <HomeHeader />
            </div>
            <div>
                <div className="guess-skill-info above-background" style={(solved || gameOver || loading)? {display: "none"} : {display: "block"}}>
                    Guess the Characters
                </div>
                <div className="guess-skill-image above-background" style={loading? {display: "none"} : {display: "flex"}}>
                    <Panel  num={1}  skill={skill1}  skillNum={skill_array[retainNumber[0]]}  answer={chosenChar}   guessCounter={guessCounter}
                        guess={currentGuess}  panel={panel}  skillSelected={skillSelected}  config={config}
                        select={selectPanel}  selectSkill={selectSkill}  cleanup={cleanupProcessGuess}  solve={checkSolve}
                    />
                    <Panel  num={2}  skill={skill2}  skillNum={skill_array[retainNumber[1]]}  answer={chosenChar2}  guessCounter={guessCounter}
                        guess={currentGuess}  panel={panel}  skillSelected={skillSelected} config={config}
                        select={selectPanel}  selectSkill={selectSkill}  cleanup={cleanupProcessGuess}  solve={checkSolve}
                    />
                    <Panel  num={3}  skill={skill3}  skillNum={skill_array[retainNumber[2]]}  answer={chosenChar3}  guessCounter={guessCounter}
                        guess={currentGuess}  panel={panel}  skillSelected={skillSelected}  config={config}
                        select={selectPanel}  selectSkill={selectSkill}  cleanup={cleanupProcessGuess}  solve={checkSolve}
                    />
                </div>
                {/* <Hints hints={2} threshold={[3, 5]} guessesMade={guessesMade} display={(solved || gameOver || loading)? false : true} /> */}
                <div className="guess-skill-shown above-background" style={(solved || gameOver || loading)? {display: "none"} : {display: "block"}}>
                    <GuessesShown guess={currentGuess} panel={panel} skillSelected={skillSelected} solve={checkSolve} guessCounter={guessCounter}
                        answer={chosenChar} skill1={skill1} skillNum1={skill_array[retainNumber[0]]}
                        answer2={chosenChar2} skill2={skill2} skillNum2={skill_array[retainNumber[1]]}
                        answer3={chosenChar3} skill3={skill3} skillNum3={skill_array[retainNumber[2]]}
                    />
                </div>
                <div className="classic-fields above-background" style={(solved || gameOver || loading)? {display: "none"} : {display: "flex"}}>
                    <div className="classic-char-info">
                        <FontAwesomeIcon icon={faQuestionCircle} />
                        <span></span>
                    </div>
                    {/* bypass the 1x restriction, 1st is for general guesses, 2nd is for only panel guesses */}
                    <GuessFields list={list} func={processGuess} display={panel? false : true}/>
                    <GuessFields list={list} func={processGuess} display={panel && skillSelected !== null? true : false}/>
                    <div className="mx-auto text-white bg-dark text-center" style={panel && skillSelected === null? 
                        {display: "block", marginTop: "20px", fontWeight: "bold", width: "100%", alignContent: "center"} 
                    : {display: "none"}}>
                        Please select the skill number to continue.
                    </div>
                </div>
                <DailyStats api={"https://e7dle-backend.uc.r.appspot.com/api/skill/stats"} />
                <div className="text-center" style={(solved || gameOver || loading)? {display: "none"} : {display: "block", marginBottom: "150px"}}>
                    <text className="text-white" style={{textDecoration: "underline", cursor: "pointer", zIndex: "200", position: "relative"}} 
                    onClick={openInst}>How to play?</text>
                </div>
                <Tutorial open={openInst} inst={instructions} page={"skill"} />
            </div>
        </div>
        {/* end of guess skill */}
        <div className="text-center mt-5 mb-5" style={loading? {display: "block"} : {display: "none"}}>
            <img src={"loading-gif.gif"} alt="loading..." />
        </div>
        </div> 
        {/* end of overlay */}
        <div className="classic-extra-stats above-background" style={((solved || gameOver) && !loading)? {display: "block"} : {display: "none"}}>
            <GlobalStats solved={solved} gameOver={false} ans={chosenChar} page={'skill'} req={req} reload={refreshStats}
                        list={list} default={false}   api={"https://e7dle-backend.uc.r.appspot.com/api/skill/stats"} />
        </div>
        <MiniFooter />
        </Fragment>
    )
}

const mapStateToProp = state => ({
    GetInventory: state.ProxyData.GetInventoryAfter
})

export default connect(mapStateToProp)(GuessSkill);