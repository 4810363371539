import { Fragment } from "react";
import HomeHeader from "../E7dle/Components/HomeHeader";
import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <Fragment>
            <div className="overlay">
            <div className="classic">
                <div className="homepage-header container">
                    <HomeHeader />
                </div>
                <div className="above-background text-center text-white font-weight-bold pt-5">
                    <text style={{fontSize: "5em"}}>Not Found</text>
                </div>
                <div className="above-background text-center text-white font-italic pt-5">
                    <text style={{fontSize: "3em"}}>Return {" "}
                        <Link to="/Home" style={{color: "darkblue", textDecoration: "underline"}}>home</Link>
                    </text>
                </div>
            </div>
            </div>
        </Fragment>
    )
}

export default NotFound;