import { Fragment, useEffect, useState } from "react";
// import "./css/artifactcropper.css";

const ArtifactImage = (props) => {
    const leftDist = [1, 5, 9, 13, 17, 21]
    const rightDist = [4, 8, 12, 16, 20, 24]
    const [row1, setRow1] = useState([])
    const [row2, setRow2] = useState([])
    const [row3, setRow3] = useState([])
    const [row4, setRow4] = useState([])
    const [row5, setRow5] = useState([])
    const [row6, setRow6] = useState([])

    useEffect(() => {
        if (props.img) {
            setRow1(props.img.slice(0,4));
            setRow2(props.img.slice(4,8));
            setRow3(props.img.slice(8,12));
            setRow4(props.img.slice(12,16));
            setRow5(props.img.slice(16,20));
            setRow6(props.img.slice(20,24));
        }
    }, [props.arti])

    return (
        <Fragment>
        {props.arti? (
            <Fragment>
                <div>
                    {row1.map((part, i) => {
                        return <div className={
                            leftDist.includes(i+1)? "artifact-cropper-left" : 
                            rightDist.includes(i+1)? "artifact-cropper-right" :
                                "artifact-cropper-part"
                            } 
                            key={"cropped-part-"+(i+1)}
                        >
                        <img src={part} alt={"cropped-"+(i+1)} />
                        </div>
                    })}
                </div>
                <div>
                    {row2.map((part, i) => {
                        return <div className={
                            leftDist.includes(i+1)? "artifact-cropper-left" : 
                            rightDist.includes(i+1)? "artifact-cropper-right" :
                                "artifact-cropper-part"
                            } 
                            key={"cropped-part-"+(i+1)}
                        >
                        <img src={part} alt={"cropped-"+(i+1)} />
                        </div>
                    })}
                </div>
                <div>
                    {row3.map((part, i) => {
                        return <div className={
                            leftDist.includes(i+1)? "artifact-cropper-left" : 
                            rightDist.includes(i+1)? "artifact-cropper-right" :
                                "artifact-cropper-part"
                            } 
                            key={"cropped-part-"+(i+1)}
                        >
                        <img src={part} alt={"cropped-"+(i+1)} />
                        </div>
                    })}
                </div>
                <div>
                    {row4.map((part, i) => {
                        return <div className={
                            leftDist.includes(i+1)? "artifact-cropper-left" : 
                            rightDist.includes(i+1)? "artifact-cropper-right" :
                                "artifact-cropper-part"
                            } 
                            key={"cropped-part-"+(i+1)}
                        >
                        <img src={part} alt={"cropped-"+(i+1)} />
                        </div>
                    })}
                </div>
                <div>
                    {row5.map((part, i) => {
                        return <div className={
                            leftDist.includes(i+1)? "artifact-cropper-left" : 
                            rightDist.includes(i+1)? "artifact-cropper-right" :
                                "artifact-cropper-part"
                            } 
                            key={"cropped-part-"+(i+1)}
                        >
                        <img src={part} alt={"cropped-"+(i+1)} />
                        </div>
                    })}
                </div>
                <div>
                    {row6.map((part, i) => {
                        return <div className={
                            leftDist.includes(i+1)? "artifact-cropper-left" : 
                            rightDist.includes(i+1)? "artifact-cropper-right" :
                                "artifact-cropper-part"
                            } 
                            key={"cropped-part-"+(i+1)}
                        >
                        <img src={part} alt={"cropped-"+(i+1)} />
                        </div>
                    })}
                </div>
            </Fragment>
            ) : (
                <p>Loading...</p>
            )
        }
        </Fragment>
    )
}

export default ArtifactImage;