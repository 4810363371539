import { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faInfinity, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import ModeLayout from "./Components/ModeLayout";
import HomeHeader from "./Components/HomeHeader";
import Notification from "./Components/Notification";
import MiniFooter from "./Components/MiniFooter";
import './Components/css/index.css'

const E7dle = () => {
    return (
        <Fragment>
        <div className="overlay">
        <div className="homepage">
            {/* header here */}
            <div className="homepage-header container">
                <HomeHeader />
            </div>
            <Notification />
            {/* body here */}
            <div className="homepage-body container">
                <div className="row mt-5" style={{overflow: "auto"}}>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <ModeLayout header="Daily" icon={<FontAwesomeIcon icon={faCalendarDay} />} 
                                    title={["Classic", "Skill Icon", "Artifact"]} 
                                    progress={[false, false, false]} special={[false, true, true]}
                                    desc={["Guess the character by their traits",
                                        "Guess the character by their skill icons",
                                        "Guess the artifact by its cropped image",
                                    ]} 
                                    link={["/classic", "/guessskill", "/artifact"]}
                                    id={1}
                                    timer={true}
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <ModeLayout header="Unlimited" icon={<FontAwesomeIcon icon={faInfinity} />} 
                                    title={["Unlimited"]} progress={[false]}
                                    special={[false]}
                                    desc={["Guess non-stop characters by their traits"
                                    ]}
                                    link={["/unlimited"]}
                                    id={2}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <ModeLayout header="Others" icon={<FontAwesomeIcon icon={faCircleDot} />}  
                                    title={["Changelog", "Sorter"]}  progress={[false, false]}
                                    desc={["Check out the new changes", 
                                        "Find out your favourite character"
                                    ]}
                                    link={["/changelog", "/sorter"]}
                                    id={3}
                        />
                    </div>
                </div>
            </div>
        </div>
        </div>
        <MiniFooter />
        </Fragment>
    )
}

export default E7dle;