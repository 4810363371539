import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import HomeHeader from "../E7dle/Components/HomeHeader";
import CustomizeMenu from "./Components/CustomizeMenu";
import Legend from "../Classic/Components/Legend";
import Timer from "./Components/Timer";
import GuessFields from "../Classic/Components/GuessFields";
import LocalStats from "./Components/LocalStats";
import Lives from "../Classic/Components/Lives";
import Tutorial from "../Classic/Components/Tutorial";
import GuessesWritten from "../Classic/Components/GuessesWritten";
import MiniFooter from "../E7dle/Components/MiniFooter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './Components/css/index.css';

const Unlimited = (props) => {
    const [tempScore, setTempScore] = useState(0)
    const [round, setRound] = useState(false)
    const [solved, setSolved] = useState(false)
    const [gameOver, setGameOver] = useState(false)
    const [fixedLives, setFixedLives] = useState(7)
    const [lives, setLives] = useState(7)
    const [startTimer, setStartTimer] = useState(false)
    const [time, setTime] = useState(NaN)
    const [timeout, setTimeout] = useState(false) // indicate die from timeout
    const [chosenChar, setChosenChar] = useState(null)
    const [currentGuess, setCurrentGuess] = useState(null) // name only
    const [guessArray, setGuessArray] = useState([])
    const [instructions, setInstructions] = useState(false)


    function openInst() {
        setInstructions(!instructions)
    }

    // customizability
    function customizeLives(n) {
        var n = parseInt(n)
        setFixedLives(n)
        setLives(n)
    }
    function customizeTimeLimit(n) {
        var n = parseInt(n)
        setStartTimer(true)
        setTime(n)
    }
    function resolveStartTimer() {
        setStartTimer(false)
    }
    function timeoutGame() {
        claimGameOver()
        setTimeout(true)
    }

    // resolving game state
    function checkSolve() {
        setSolved(true)
        var currObj = JSON.parse(localStorage['unlimited'])
        var newScore = parseInt(currObj['plays']) + 1
        currObj['plays'] = newScore
        currObj['guesses'] = fixedLives - lives + parseInt(currObj['guesses'])
        localStorage['unlimited'] = JSON.stringify(currObj)
        setTempScore(newScore)
    }
    function claimGameOver() {
        setGameOver(true)
        var currObj = JSON.parse(localStorage['unlimited'])
        currObj['guesses'] = fixedLives - lives + parseInt(currObj['guesses'])
        localStorage['unlimited'] = JSON.stringify(currObj)
    }
    function resetRound() {
        setRound(true) //activates useEffect to get new character
    }
    function minusLives() {
        setLives(lives-1)
    }

    // Mock finish guesses => classic answer fill
    // Check answer submitted is part of the databank, done in child component
    // function to process guess returned from child component
    function processGuess(guess) {
        // if not null do search for name and get all traits
        if (currentGuess === null) {
            var retrievedGuessInfo = props.GetInventory.filter(char => char.name.toLowerCase() == guess)
            // pass this object into guesseswritten
            setCurrentGuess(retrievedGuessInfo[0])
            setLives(lives-1)
            var tempArray = guessArray.slice()
            tempArray.push(guess)
            setGuessArray(tempArray)
        } else { 
            // dont retrieve if same guess, also need make sure haven't guessed before
            if (guess !== currentGuess.name.toLowerCase() && !guessArray.includes(guess)) {
                var retrievedGuessInfo = props.GetInventory.filter(char => char.name.toLowerCase() == guess)
                setCurrentGuess(retrievedGuessInfo[0])
                setLives(lives-1)
                var tempArray = guessArray.slice()
                tempArray.push(guess)
                setGuessArray(tempArray)
            }
        }
    }

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }
    
    useEffect(() => {
        if (gameOver) {
            console.log("game over")
            localStorage.removeItem('unlimited');
        } else if (solved && round) {
            // reset all states here
            setSolved(false)
            setLives(fixedLives)
            setCurrentGuess(null)
            setGuessArray([]) //not resetting
            setRound(false)
        } else {
            // Mock seed generation
            if (!localStorage['unlimited']) {
                var tempObj = {}
                tempObj['plays'] = 0
                tempObj['guesses'] = 0
                var shuffledList = shuffleArray(props.GetInventory)
                tempObj['seed'] = JSON.stringify(shuffledList)
                localStorage['unlimited'] = JSON.stringify(tempObj)
            }
            var currObj = JSON.parse(localStorage['unlimited'])
            // what if someone makes it all the way to the end?
            if (currObj['plays'] < props.GetInventory.length) {
                const tempHolder = JSON.parse(currObj['seed'])[currObj['plays']]
                setChosenChar(tempHolder)
            }
            if (currObj['plays'] === props.GetInventory.length - 1) {
                console.log("LAST ROUND")
                // stop them from going to the next round
                const goNext = document.querySelector('#goNextUnlimited')
                goNext.remove()
            }
        }
    }, [round, gameOver, fixedLives])

    return (
        <Fragment>
        <div className="overlay">
        <div className="classic">
            <div className="homepage-header container">
                <HomeHeader />
            </div>
            <CustomizeMenu  lives={lives} customizeLives={customizeLives} 
                            time={time} customizeTimeLimit={customizeTimeLimit}         
            />
            <div className="unlimited-scoreboard above-background" style={gameOver? {display: "block"}:{display: "none"}}>
                <span>FINAL SCORE: {tempScore}</span>
            </div>
            <div className="classic-solution above-background" style={(solved || gameOver)? {display: "block"} : {display: "none"}}> 
                <span>The character was:</span>
                <span>{chosenChar? chosenChar.name : ""}</span>
                <span style={gameOver? {display: "block"}:{display: "none"}}>
                    {[...Array(100).keys()].map((x) => {
                        return ("GAME OVER ")
                    })}
                </span>
                <span style={solved? {display: "block"}:{display: "none"}}>
                    <button id="goNextUnlimited" onClick={resetRound}>Next Character <FontAwesomeIcon icon={faArrowRight}/></button>
                </span>
                <span id="classic-sol-img">
                    <img src={chosenChar? process.env.PUBLIC_URL+chosenChar.image[0] : ""} alt={"char"} />
                </span>
            </div>
            <div style={(solved || gameOver)? {display: "none"} : {display: "block"}}>
                <div className="classic-answer above-background"></div>
                <div className="classic-legend above-background container">
                    <Legend />
                </div>
                <Timer time={time} run={startTimer} resolve={resolveStartTimer} 
                        minus={minusLives} lives={lives} end={timeoutGame} guess={currentGuess}
                        solved={solved} reset={round} gameover={gameOver}  />
                <div className="classic-fields above-background">
                    <div className="classic-char-info">
                        <FontAwesomeIcon icon={faQuestionCircle} />
                        <span></span>
                    </div>
                    <GuessFields list={props.GetInventory.map((char) => { return char.name })} func={processGuess}
                        round={round} display={true} />
                </div>
                <LocalStats />
                <Lives lives={lives} fixedLives={fixedLives} />
                <div className="text-center">
                    <text className="text-white" style={{textDecoration: "underline", cursor: "pointer", zIndex: "200", position: "relative"}} 
                    onClick={openInst}>How to play?</text>
                </div>
                <Tutorial open={openInst} inst={instructions} page={"classic"}/>
            </div>
            <div className="classic-guesses-written above-background">
                <GuessesWritten answer={chosenChar} guess={currentGuess} lives={lives}
                    solve={checkSolve} kill={claimGameOver} timecheck={timeout}
                    reset={round}  />
            </div>
        </div>
        </div>
        <MiniFooter />
        </Fragment>
    )
}

const mapStateToProp = state => ({
    isLoading: state.ProxyData.isLoading,
    GetInventory: state.ProxyData.GetInventoryBefore
})

export default connect(mapStateToProp)(Unlimited);