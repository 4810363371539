import { Fragment, useEffect, useState } from "react";
import Mode from "./Mode";
import DailyReset from "./DailyReset";
import "./css/modelayout.css";

const ModeLayout = (props) => {
    return (
        <Fragment>
            <div className="ModeLayout-header">
                <span>{props.icon}</span>
                {props.header}
            </div>
            <div className="DailyReset-Timer">
                <DailyReset timer={props.timer} />
            </div>
            <div className="ModeLayout-Body">
                {props.title.map((title, idx) => {
                    return (<Mode title={title} desc={props.desc[idx]} 
                                    link={props.link[idx]} progress={props.progress[idx]}
                                    special={props.special? props.special[idx]: []}
                                    id={props.id} id2={idx}
                         />)
                })}
            </div>
        </Fragment>
    )
}

export default ModeLayout;