import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import './css/guessfields.css';

const GuessFields = (props) => {
    const [guess, setGuess] = useState("")
    const [menuState, setMenuState] = useState(false)
    const [guessArray, setGuessArray] = useState([])
    const [submitArray, setSubmitArray] = useState([])

    function menuIsOpen() {
        setMenuState(true)
    }

    function menuIsClose() {
        setMenuState(false)
    }

    function editingGuess(e) { // guess array still got loophole
        setGuess(e.value)
        var tempArray = guessArray.slice()
        tempArray.push(e.value)
        setGuessArray(tempArray)
    }

    function submitGuess(e) { //problem when hovering, no value changes so enter key bugs
        var tempArray = submitArray.slice()
        tempArray.push(guess)
        if (e.code === "Enter") {
            if (guess !== "" && !submitArray.includes(guess) && guessArray.slice(-1)[0] == guess && !menuState) {
                props.func(guess)
                setSubmitArray(tempArray)
            }
        }
        if (e.code === undefined) {
            if (guess !== "" && !submitArray.includes(guess)) {
                props.func(guess)
                setSubmitArray(tempArray)
            }
        }
    }

    function alphabetPositionSum(str) {
        return str
          .toLowerCase()
          .split('')
          .reduce((sum, char) => sum + (char.charCodeAt(0) - 'A'.charCodeAt(0) + 1), 0);
    }

    function customSort(arr) {
        return arr.sort((a, b) => {
          // First, sort by alphabetical order
          if (a < b) return -1;
          if (a > b) return 1;
          
          // If they are the same alphabetically, sort by position sum
          const aPosSum = alphabetPositionSum(a);
          const bPosSum = alphabetPositionSum(b);
      
          return aPosSum - bPosSum;
        });
    }

    useEffect(() => {
        if (props.round) { //true on next char, sets false after all generated
            setSubmitArray([]) //reset
        }
    }, [props.round])


    return (
        <Fragment>
            {props.display?
                <Fragment>
                <Select
                    onKeyDown={submitGuess}
                    onChange={editingGuess}
                    className="classic-input"
                    placeholder="Type a character name to begin..."
                    onMenuClose={menuIsClose}
                    onMenuOpen={menuIsOpen}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            marginTop: '20px',
                        }),
                        menuList: (provided, state) => ({
                            ...provided,
                            maxHeight: '170px'
                        }),
                        valueContainer: (provided, state) => ({
                            ...provided,
                            // textOverflow: "ellipsis", // not working
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          })
                    }}
                    options={
                        customSort(props.list).map((name, idx) => {
                            return  {value: name.toLowerCase(), label: name }
                        }
                    )}
                />
                <div className="classic-button"> {/* need to add a loading anim when calling API */}
                    <button onClick={submitGuess}>Guess!</button>
                </div>
                </Fragment>
                :
                <div></div>
            }
        </Fragment>
    )
}

export default GuessFields;