import { Fragment, useEffect, useState } from "react"

const DailyReset = (props) => {
    const [dailyReset, setDailyReset] = useState(props.timer);
    const [hoursRemaining, setHoursRemaining] = useState(0);
    const [minutesRemaining, setMinutesRemaining] = useState(0);
    const [secondsRemaining, setSecondsRemaining] = useState(0);

    function timeUntilNextDay(unixTimestamp) {
        const currentDate = new Date(unixTimestamp); // Convert UNIX timestamp to Date object

        // Get the current time in UTC
        const currentUTCDate = new Date(currentDate.toUTCString());

        // Get current time in UTC
        const currentUTCHours = currentUTCDate.getUTCHours();
        const currentUTCMinutes = currentUTCDate.getUTCMinutes();
        const currentUTCSeconds = currentUTCDate.getUTCSeconds();
        
        // Calculate remaining time until next day's midnight (UTC)
        const remainingUTCHours = 23 - currentUTCHours;
        const remainingUTCMinutes = 59 - currentUTCMinutes;
        const remainingUTCSeconds = 59 - currentUTCSeconds;
      
        return {
          hours: remainingUTCHours,
          minutes: remainingUTCMinutes,
          seconds: remainingUTCSeconds
        };
    }

    function padZeroes(int) {
        return ('0' + int).slice(-2)
    }

    function manipulateTime() {
        if (secondsRemaining === 0) {
            setMinutesRemaining(minutesRemaining-1)
            setSecondsRemaining(59)

            if (minutesRemaining === 0) {
                setHoursRemaining(hoursRemaining-1)
                setMinutesRemaining(59)
            }
        } else {
            setSecondsRemaining(secondsRemaining-1)
        }
    }

    useEffect(() => {
        if (dailyReset) {
            // var timerToReset = retrieveDaily();
            fetch("https://e7dle-backend.uc.r.appspot.com/api/daily").then((res) => {
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                return res.json();
    
            }).then((data) => {
                var currentTimestamp = parseInt(data.daily);
                var remaining = timeUntilNextDay(currentTimestamp);
                
                setDailyReset(false);
                setHoursRemaining(remaining.hours);
                setMinutesRemaining(remaining.minutes);
                setSecondsRemaining(remaining.seconds);
            })
        }
        
        const intervalId = setInterval(() => {
            manipulateTime();
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);

    }, [hoursRemaining, minutesRemaining, secondsRemaining])

    return (
        <Fragment>
            {props.timer? 
                    `${padZeroes(hoursRemaining)}:${padZeroes(minutesRemaining)}:${padZeroes(secondsRemaining)}`
                : ""}
        </Fragment>
    )
}

export default DailyReset;