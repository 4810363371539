import { Fragment, useEffect, useState } from "react";
import { Table } from "reactstrap";
import './css/guessesshown.css';

const GuessesShown = (props) => {
    const [guessArray, setGuessArray] = useState([]) // temp storage
    // solve states
    const [char1Guessed, setChar1Guessed] = useState(false)
    const [char2Guessed, setChar2Guessed] = useState(false)
    const [char3Guessed, setChar3Guessed] = useState(false)

    function createSolution(lastGuess) {
        const tbody = document.getElementById('shownGuesses');
        const newRow = document.createElement('tr');
        {[...Array(2).keys()].map((i) => {
            var cell = document.createElement('td');
            cell.className = "guess-shown-boxes skill-true-guess"
            if (i === 0) {
                cell.textContent = lastGuess.name
            } else {
                [...Array(3).keys()].map((i) => {
                    const img = document.createElement('img');
                    img.src = lastGuess.skill[i]
                    img.alt = 'skill'+(i+1)
                    // img.width = 50
                    cell.appendChild(img)
                })
            }
            newRow.appendChild(cell) 
        })}
        tbody.insertBefore(newRow, tbody.firstChild)
    }

    function tabulateGuess() {
        if (props.guess != null) {

            // check for panel, correct skill number selected and correct char chosen
            if (props.panel === 1 && props.skillSelected === props.skillNum1 && props.guess.name === props.answer.name) {
                setChar1Guessed(true)
                if (char2Guessed && char3Guessed) { // that means all solved 2 & 3
                    props.solve(true)
                    createSolution(props.guess)
                }
            }
            if (props.panel === 2 && props.skillSelected === props.skillNum2 && props.guess.name === props.answer2.name) {
                setChar2Guessed(true)
                if (char1Guessed && char3Guessed) { // that means all solved 1 & 3
                    props.solve(true)
                    createSolution(props.guess)
                }
            }
            if (props.panel === 3 && props.skillSelected === props.skillNum3 && props.guess.name === props.answer3.name) {
                setChar3Guessed(true)
                if (char1Guessed && char2Guessed) { // that means all solved 1 & 2
                    props.solve(true)
                    createSolution(props.guess)
                }
            }

            if (!props.panel) {
                // grab guess and append; full char info
                var guess = guessArray.slice()
                guess.unshift(props.guess)
                setGuessArray(guess)
            }
        }
    }

    useEffect(() => {
        tabulateGuess()
    }, [props.guessCounter])
    
    return (
        <Fragment>
            <Table>
                <thead> 
                    <tr> 
                        <th>Belongs to them</th>
                        <th>Does not belong to them</th>
                    </tr>
                </thead>
                <tbody id="shownGuesses">
                    <tr>
                        <td className="guess-shown-boxes skill-true-guess">
                            {guessArray.map((check) => {
                                return (
                                    <img src={(props.answer.name === check.name) || (props.answer2.name === check.name) || (props.answer3.name === check.name)
                                        ? check.icon : ""} width={50} />
                                )
                        })}
                        </td>
                        <td className="guess-shown-boxes skill-false-guess">
                            {guessArray.map((check) => {
                                return (
                                    <img src={(props.answer.name !== check.name) && (props.answer2.name !== check.name) && (props.answer3.name !== check.name)
                                        ? check.icon : ""} width={50} />
                                )
                        })}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Fragment>
    )
}

export default GuessesShown;