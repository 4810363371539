import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import HomeHeader from "../E7dle/Components/HomeHeader";
import Legend from "./Components/Legend";
import GuessFields from "./Components/GuessFields";
import DailyStats from "./Components/DailyStats";
import Lives from "./Components/Lives";
import Tutorial from "./Components/Tutorial";
import GuessesWritten from "./Components/GuessesWritten";
import GlobalStats from "./Components/GlobalStats";
import MiniFooter from "../E7dle/Components/MiniFooter";
import { signData } from '../../Utils/cryptoUtils';
import './Components/css/index.css';

const horoscopeCode = {
    'Aquarius': '♒',
    'Pisces': '♓',
    'Aries': '♈',
    'Taurus': '♉',
    'Gemini': '♊',
    'Cancer': '♋',
    'Leo': '♌',
    'Virgo': '♍',
    'Libra': '♎',
    'Scorpio': '♏',
    'Sagittarius': '♐',
    'Capricorn': '♑',
}

const Classic = (props) => {
    const [loading, setLoading] = useState(true)
    const [solved, setSolved] = useState(false)
    const [gameOver, setGameOver] = useState(false)
    const [lives, setLives] = useState(7)
    const [chosenChar, setChosenChar] = useState(null)
    const [currentGuess, setCurrentGuess] = useState(null)
    const [guessArray, setGuessArray] = useState([]) // name only
    const [req, setReq] = useState(0)
    const [instructions, setInstructions] = useState(false)
    const list = props.GetInventory.map((char) => { return char.name })

    function refreshStats() {
        setReq(req+1)
    }

    function openInst() {
        setInstructions(!instructions)
    }

    async function submitResult(data, guessed) {
        const url = "https://e7dle-backend.uc.r.appspot.com/api/classic/submit";
        try {
            const jsonBody = JSON.stringify({
                "guessArray": data,
                "guessed": guessed
            });
            const response = await signData(jsonBody)
            .then((signedBody) => {
                fetch(url, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json', // Send as JSON
                        'Signature': signedBody
                    },
                    body: jsonBody
                })
                return "success"
            })
            
        } catch (err) {
            console.log(err)
        }
    }

    function checkSolve() {
        setSolved(true)
        // in the case of malicious manipulation of data, might reach an exception or have a need to catch err
        // security-wise, need to resolve the missing name/malicious code because it will not crash after err is caught
            // dispose of the submission post req attempt?
        var data = guessArray.map((name) => {
            var entry = props.GetInventory.filter((char) => char.name.toLowerCase() === name)[0]
            return entry.name
        })
        var currObj = JSON.parse(localStorage['classic'])
        currObj['solve'] = true
        currObj['guesses'] = data
        localStorage['classic'] = JSON.stringify(currObj)
        try {
            submitResult(data, true)

        } catch (err) { console.log(err) }
    }

    function claimGameOver() {
        setGameOver(true)
        var data = guessArray.map((name) => {
            var entry = props.GetInventory.filter((char) => char.name.toLowerCase() === name)[0]
            return entry.name
        })
        var currObj = JSON.parse(localStorage['classic'])
        currObj['solve'] = false
        currObj['guesses'] = data
        localStorage['classic'] = JSON.stringify(currObj)
        try {
            submitResult(data, false)

        } catch (err) { console.log(err) }
    }

    // Mock finish guesses => classic answer fill
    // Check answer submitted is part of the databank, done in child component
    // function to process guess returned from child component
    function processGuess(guess) {
        // if not null do search for name and get all traits
        if (currentGuess === null) {
            var retrievedGuessInfo = props.GetInventory.filter(char => char.name.toLowerCase() == guess)
            // pass this object into guesseswritten
            setCurrentGuess(retrievedGuessInfo[0])
            setLives(lives-1)
            var tempArray = guessArray.slice()
            tempArray.push(guess)
            setGuessArray(tempArray)
        } else { 
            // dont retrieve if same guess, also need make sure haven't guessed before
            if (guess !== currentGuess.name.toLowerCase() && !guessArray.includes(guess)) {
                var retrievedGuessInfo = props.GetInventory.filter(char => char.name.toLowerCase() == guess)
                setCurrentGuess(retrievedGuessInfo[0])
                setLives(lives-1)
                var tempArray = guessArray.slice()
                tempArray.push(guess)
                setGuessArray(tempArray)
            }
        }
    }
    
    useEffect(() => {
        function refresh(res_seed) {
            const seed = res_seed % props.GetInventory.length
            const tempHolder = props.GetInventory[seed]
            setChosenChar(tempHolder) //affected by guessskill, some shared variables (?)
            return tempHolder.name
        }
        // retrieve seed from API
        fetch("https://e7dle-backend.uc.r.appspot.com/api/seed").then((res) => {
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json();
        }).then((data) => {
            // check if seed same as yesterday's
            if (localStorage['classic']) {
                var currObj = JSON.parse(localStorage['classic']);
                if (currObj['seed'] != data.seed) {
                    // refresh if not
                    refresh(data.seed)
                    // refresh localstorage['classic']
                    var tempObj = {}
                    tempObj['seed'] = data.seed
                    tempObj['solve'] = null
                    localStorage['classic'] = JSON.stringify(tempObj)
                    
                } else {
                    // check if game is played
                    if (currObj['solve'] !== null) {
                        // yes; set game as over
                        var ansName = refresh(data.seed)
                        currObj['solve'] ? setSolved(true) : setGameOver(true)
                        var ansObj = props.GetInventory.filter(char => char.name == ansName)[0]
                        const newRow2 = 
                        `<tr>
                            <td class='classic-written-boxes classic-solution-guess'>${ansObj.name}</td>
                            <td class='classic-written-boxes classic-solution-guess'>${ansObj.gender}</td>
                            <td class='classic-written-boxes classic-solution-guess classic-${ansObj.element}-guess'>${ansObj.element}</td>
                            <td class='classic-written-boxes classic-solution-guess classic-${ansObj.class.split(" ").pop()}-guess'>${ansObj.class}</td>
                            <td class='classic-written-boxes classic-solution-guess'>${horoscopeCode[ansObj.horoscope]} ${ansObj.horoscope}</td>
                            <td class='classic-written-boxes classic-solution-guess classic-star-guess'>${ansObj.rarity}</td>
                            <td class='classic-written-boxes classic-solution-guess'>${ansObj.release}</td>
                            <td class='classic-written-boxes classic-solution-guess'>${ansObj.skin > 0? "Yes":"No"}</td>
                            <td class='classic-written-boxes classic-solution-guess'>${ansObj.category}</td>
                        </tr>`
                        const tbody = document.getElementById('writtenGuesses');
                        tbody.innerHTML = newRow2+tbody.innerHTML;
                        
                    } else refresh(data.seed)  // no; nothing
                }
            } else {
                var tempObj = {}
                tempObj['seed'] = data.seed
                tempObj['solve'] = null
                localStorage['classic'] = JSON.stringify(tempObj)
                refresh(data.seed)
            }

            
            // loading
            setLoading(false)

        }).catch((err) => {
            console.log(err);
        });

    }, [])

    return (
        <Fragment>
        <div className="overlay">
        <div className="classic">
            <div className="homepage-header container">
                <HomeHeader />
            </div>
            <div className="classic-solution above-background" style={((solved || gameOver) && !loading)? {display: "block"} : {display: "none"}}> 
                <span>The character was:</span>
                <span>{chosenChar? chosenChar.name : ""}</span>
                <span style={gameOver? {display: "block"}:{display: "none"}}>
                    {[...Array(100).keys()].map((x) => {
                        return ("GAME OVER")
                    }).join(" ")}
                </span>
                <span id="classic-sol-img">
                    <img src={chosenChar? process.env.PUBLIC_URL+chosenChar.image[0] : ""} alt="answer" />
                </span>
            </div>
            <div style={(solved || gameOver || loading)? {display: "none"} : {display: "block"}}>
                <div className="classic-answer above-background"></div>
                <div className="classic-legend above-background container">
                    <Legend />
                </div>
                <div className="classic-fields above-background">
                    <div className="classic-char-info">
                        <FontAwesomeIcon icon={faQuestionCircle} />
                        <span></span>
                    </div>
                    <GuessFields list={list} func={processGuess} display={true} />
                </div>
                <DailyStats api={"https://e7dle-backend.uc.r.appspot.com/api/classic/stats"} />
                <Lives lives={lives} fixedLives={7} />
                <div className="text-center">
                    <text className="text-white" style={{textDecoration: "underline", cursor: "pointer", zIndex: "200", position: "relative"}} 
                    onClick={openInst}>How to play?</text>
                </div>
                <Tutorial open={openInst} inst={instructions} page={"classic"}/>
            </div>
            <div className="classic-guesses-written above-background" style={loading? {display: "none"} : {display: "block"}}>
                <GuessesWritten answer={chosenChar} guess={currentGuess} lives={lives}
                    solve={checkSolve} kill={claimGameOver}  />
            </div>
        </div>
        <div className="text-center mt-5 mb-5" style={loading? {display: "block"} : {display: "none"}}>
            <img src={"loading-gif.gif"} alt="loading..." />
        </div>
        </div>
        <div className="classic-extra-stats above-background" style={((solved || gameOver) && !loading)? {display: "block"} : {display: "none"}}>
            <GlobalStats solved={solved} gameOver={gameOver} ans={chosenChar} page={'classic'} req={req} reload={refreshStats}
                        list={list} default={true}   api={"https://e7dle-backend.uc.r.appspot.com/api/classic/stats"} />
        </div>
        <MiniFooter />
        </Fragment>
    )
}

const mapStateToProp = state => ({
    GetInventory: state.ProxyData.GetInventoryAfter
})

export default connect(mapStateToProp)(Classic);