import { Fragment } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeartCrack } from '@fortawesome/free-solid-svg-icons';
import './css/dailystats.css'

const Lives = (props) => {

    return (
        <Fragment>
            <div className="classic-lives above-background">
                {[...Array(props.lives).keys()].map((x) => {
                    return <FontAwesomeIcon key={"heartIcon-"+x} icon={faHeart} fill="true" size="2x" color="red" />
                })}
                {[...Array(props.fixedLives-props.lives).keys()].map((x) => {
                    return <FontAwesomeIcon key={"emptyHeartIcon-"+x} icon={faHeartCrack} size="2x" color="black" />
                })}
            </div>
        </Fragment>
    )
}

export default Lives;