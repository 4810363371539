export const GET_INVENTORY = 'store/GET_INVENTORY';
export const SET_LOADING = 'store/SET_LOADING';

export const setLoading = () => ({
    type: SET_LOADING,
});

export const getInventory = () => ({
    type: GET_INVENTORY,
});


// Local Database of characters
const apiReturn = [
/********************************** RGB ****************************/
// { 
//     'id': 'c1001',
//     'name': 'Ras',
//     'gender': 'M',
//     'element': 'Fire',
//     'class': 'Knight',
//     'horoscope': 'Libra',
//     'rarity': 3,
//     'release': 2018,
//     'image': ["/characters/new_png/RGB/c1001.png"],
//     'tagname': 'ras',
//     'skin': [],
//     'icon': '',
//     'limited': false,
//     'spec': false,
//     'collab': false
// },
{ 
    'id': 'c1002',
    'name': 'Cecilia',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Knight',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1002.png"],
    'tagname': 'cecilia',
    'skin': ["/characters/new_png/SKIN/c1002_s01.png"],
    'icon': '/characters/icons/RGB/c1002_s.png',
    'skill': ["/characters/skill/sk_c1002_1.png",
              "/characters/skill/sk_c1002_2.png",
              "/characters/skill/sk_c1002_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Rise of a Monarch": '/characters/artifact/art0102_fu.png'
    },
},
{ 
    'id': 'c1003',
    'name': 'Rose',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Knight',
    'horoscope': 'Libra',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1003.png"],
    'tagname': 'rose',
    'skin': ["/characters/new_png/SKIN/c1003_s01.png"],
    'icon': '/characters/icons/RGB/c1003_s.png',
    'skill': ["/characters/skill/sk_c1003_1.png",
              "/characters/skill/sk_c1003_2.png",
              "/characters/skill/sk_c1003_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka'
},
{ 
    'id': 'c1004',
    'name': 'Silk',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Ranger',
    'horoscope': 'Virgo',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1004.png"],
    'tagname': 'silk',
    'skin': [],
    'icon': '/characters/icons/RGB/c1004_s.png',
    'skill': ["/characters/skill/sk_c1004_1.png",
              "/characters/skill/sk_c1004_2.png",
              "/characters/skill/sk_c1004_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1005',
    'name': 'Mercedes',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1005.png"],
    'tagname': 'mercedes',
    'skin': ["/characters/new_png/SKIN/c1005_s01.png"],
    'icon': '/characters/icons/RGB/c1005_s.png',
    'skill': ["/characters/skill/sk_c1005_1.png",
              "/characters/skill/sk_c1005_2.png",
              "/characters/skill/sk_c1005_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1006',
    'name': 'Kise',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1006.png"],
    'tagname': 'kise',
    'skin': [],
    'icon': '/characters/icons/RGB/c1006_s.png',
    'skill': ["/characters/skill/sk_c1006_1.png",
              "/characters/skill/sk_c1006_2.png",
              "/characters/skill/sk_c1006_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Alabastron": '/characters/artifact/art0089_fu.png'
    },
},
{ 
    'id': 'c1007',
    'name': 'Vildred',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1007.png"],
    'tagname': 'vildred',
    'skin': ["/characters/new_png/SKIN/c1007_s01.png"],
    'icon': '/characters/icons/RGB/c1007_s.png',
    'skill': ["/characters/skill/sk_c1007_1.png",
              "/characters/skill/pa_c1007_2.png",
              "/characters/skill/sk_c1007_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Sword of Summer Twilight": '/characters/artifact/art0117_fu.png'
    },
},
{ 
    'id': 'c1008',
    'name': 'Armin',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Knight',
    'horoscope': 'Capricorn',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1008.png"],
    'tagname': 'armin',
    'skin': [],
    'icon': '/characters/icons/RGB/c1008_s.png',
    'skill': ["/characters/skill/sk_c1008_1.png",
              "/characters/skill/pa_c1008_2.png",
              "/characters/skill/sk_c1008_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1009',
    'name': 'Charlotte',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Knight',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1009.png"],
    'tagname': 'charlotte',
    'skin': [],
    'icon': '/characters/icons/RGB/c1009_s.png',
    'skill': ["/characters/skill/sk_c1009_1.png",
              "/characters/skill/pa_c1009_2.png",
              "/characters/skill/sk_c1009_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1010',
    'name': 'Zerato',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Aries',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1010.png"],
    'tagname': 'zerato',
    'skin': [],
    'icon': '/characters/icons/RGB/c1010_s.png',
    'skill': ["/characters/skill/sk_c1010_1.png",
              "/characters/skill/sk_c1010_2.png",
              "/characters/skill/sk_c1010_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1011',
    'name': 'Karin',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1011.png"],
    'tagname': 'karin',
    'skin': ["/characters/new_png/SKIN/c1011_s01.png"],
    'icon': '/characters/icons/RGB/c1011_s.png',
    'skill': ["/characters/skill/sk_c1011_1.png",
              "/characters/skill/sk_c1011_2.png",
              "/characters/skill/sk_c1011_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1012',
    'name': 'Corvus',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Knight',
    'horoscope': 'Cancer',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1012.png"],
    'tagname': 'corvus',
    'skin': [],
    'icon': '/characters/icons/RGB/c1012_s.png',
    'skill': ["/characters/skill/sk_c1012_1.png",
              "/characters/skill/sk_c1012_2.png",
              "/characters/skill/sk_c1012_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1013',
    'name': 'Cartuja',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Cancer',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1013.png"],
    'tagname': 'cartuja',
    'skin': [],
    'icon': '/characters/icons/RGB/c1013_s.png',
    'skill': ["/characters/skill/sk_c1013_1.png",
              "/characters/skill/sk_c1013_2.png",
              "/characters/skill/sk_c1013_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1014',
    'name': 'Cidd',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Aries',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1014.png"],
    'tagname': 'cidd',
    'skin': ["/characters/new_png/SKIN/c1014_s01.png"],
    'icon': '/characters/icons/RGB/c1014_s.png',
    'skill': ["/characters/skill/sk_c1014_1.png",
              "/characters/skill/pa_c1014_2.png",
              "/characters/skill/sk_c1014_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1015',
    'name': 'Baal & Sezan',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1015.png"],
    'tagname': 'baal',
    'skin': [],
    'icon': '/characters/icons/RGB/c1015_s.png',
    'skill': ["/characters/skill/sk_c1015_1.png",
              "/characters/skill/sk_c1015_2.png",
              "/characters/skill/sk_c1015_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Last Teatime": '/characters/artifact/art0108_fu.png'
    },
},
{ 
    'id': 'c1016',
    'name': 'Yufine',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1016.png"],
    'tagname': 'yufine',
    'skin': ["/characters/new_png/SKIN/c1016_s01.png"],
    'icon': '/characters/icons/RGB/c1016_s.png',
    'skill': ["/characters/skill/sk_c1016_1.png",
              "/characters/skill/sk_c1016_2.png",
              "/characters/skill/sk_c1016_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Merciless Glutton": '/characters/artifact/art0083_fu.png'
    },
},
{ 
    'id': 'c1017',
    'name': 'Achates',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Soul Weaver',
    'horoscope': 'Gemini',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1017.png"],
    'tagname': 'achates',
    'skin': ["/characters/new_png/SKIN/c1017_s01.png"],
    'icon': '/characters/icons/RGB/c1017_s.png',
    'skill': ["/characters/skill/sk_c1017_1.png",
              "/characters/skill/sk_c1017_2.png",
              "/characters/skill/sk_c1017_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
// { 
//     'id': 'c1018',
//     'name': 'Aither',
//     'gender': 'M',
//     'element': 'Ice',
//     'class': 'Soul Weaver',
//     'horoscope': 'Libra',
//     'rarity': 3,
//     'release': 2018,
//     'image': ["/characters/new_png/RGB/c1018.png"],
//     'tagname': 'aither',
//     'skin': [],
//     'icon': '',
//     'limited': false,
//     'spec': false,
//     'collab': false
// },
{ 
    'id': 'c1019',
    'name': 'Ravi',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1019.png"],
    'tagname': 'ravi',
    'skin': [],
    'icon': '/characters/icons/RGB/c1019_s.png',
    'skill': ["/characters/skill/sk_c1019_1.png",
              "/characters/skill/pa_c1019_2.png", //missing icon || existing not passive
              "/characters/skill/sk_c1019_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1020',
    'name': 'Schuri',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Leo',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1020.png"],
    'tagname': 'schuri',
    'skin': [],
    'icon': '/characters/icons/RGB/c1020_s.png',
    'skill': ["/characters/skill/sk_c1020_1.png",
              "/characters/skill/pa_c1020_2.png",
              "/characters/skill/sk_c1020_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1021',
    'name': 'Dingo',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Soul Weaver',
    'horoscope': 'Virgo',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1021.png"],
    'tagname': 'dingo',
    'skin': [],
    'icon': '/characters/icons/RGB/c1021_s.png',
    'skill': ["/characters/skill/sk_c1021_1.png",
              "/characters/skill/sk_c1021_2.png",
              "/characters/skill/sk_c1021_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1023',
    'name': 'Kayron',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Thief',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1023.png"],
    'tagname': 'kayron',
    'skin': ["/characters/new_png/SKIN/c1023_s01.png"],
    'icon': '/characters/icons/RGB/c1023_s.png',
    'skill': ["/characters/skill/sk_c1023_1.png",
              "/characters/skill/pa_c1023_2.png",
              "/characters/skill/sk_c1023_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Shepherd of the Hollow": '/characters/artifact/art0109_fu.png'
    },
},
{ 
    'id': 'c1024',
    'name': 'Iseria',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Ranger',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1024.png"],
    'tagname': 'iseria',
    'skin': ["/characters/new_png/SKIN/c1024_s01.png"],
    'icon': '/characters/icons/RGB/c1024_s.png',
    'skill': ["/characters/skill/sk_c1024_1.png",
              "/characters/skill/sk_c1024_2.png",
              "/characters/skill/sk_c1024_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
{ 
    'id': 'c1027',
    'name': 'Charles',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Knight',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1027.png"],
    'tagname': 'charles',
    'skin': ["/characters/new_png/SKIN/c1027_s01.png"],
    'icon': '/characters/icons/RGB/c1027_s.png',
    'skill': ["/characters/skill/sk_c1027_1.png",
              "/characters/skill/sk_c1027_2.png",
              "/characters/skill/sk_c1027_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Justice for All": '/characters/artifact/art0043_fu.png'
    },
},
{ 
    'id': 'c1028',
    'name': 'Clarissa',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Warrior',
    'horoscope': 'Leo',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1028.png"],
    'tagname': 'clarissa',
    'skin': [],
    'icon': '/characters/icons/RGB/c1028_s.png',
    'skill': ["/characters/skill/sk_c1028_1.png",
              "/characters/skill/pa_c1028_2.png", // missing icon, existing not passive
              "/characters/skill/sk_c1028_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1029',
    'name': 'Leo',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Ranger',
    'horoscope': 'Capricorn',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1029.png"],
    'tagname': 'leo',
    'skin': [],
    'icon': '/characters/icons/RGB/c1029_s.png',
    'skill': ["/characters/skill/sk_c1029_1.png",
              "/characters/skill/sk_c1029_2.png",
              "/characters/skill/sk_c1029_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1030',
    'name': 'Yuna',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Ranger',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1030.png"],
    'tagname': 'yuna',
    'skin': ["/characters/new_png/SKIN/c1030_s01.png"],
    'icon': '/characters/icons/RGB/c1030_s.png',
    'skill': ["/characters/skill/sk_c1030_1.png",
              "/characters/skill/sk_c1030_2.png",
              "/characters/skill/sk_c1030_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
{ 
    'id': 'c1031',
    'name': 'Lots',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Soul Weaver',
    'horoscope': 'Gemini',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1031.png"],
    'tagname': 'lots',
    'skin': [],
    'icon': '/characters/icons/RGB/c1031_s.png',
    'skill': ["/characters/skill/sk_c1031_1.png",
              "/characters/skill/sk_c1031_2.png",
              "/characters/skill/sk_c1031_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Land of Death'
},
{ 
    'id': 'c1032',
    'name': 'Maya',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Knight',
    'horoscope': 'Aries',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1032.png"],
    'tagname': 'maya',
    'skin': [],
    'icon': '/characters/icons/RGB/c1032_s.png',
    'skill': ["/characters/skill/sk_c1032_1.png",
              "/characters/skill/sk_c1032_2.png",
              "/characters/skill/sk_c1032_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1033',
    'name': 'Coli',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1033.png"],
    'tagname': 'coli',
    'skin': [],
    'icon': '/characters/icons/RGB/c1033_s.png',
    'skill': ["/characters/skill/sk_c1033_1.png",
              "/characters/skill/sk_c1033_2.png",
              "/characters/skill/sk_c1033_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1035',
    'name': 'Purrgis',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1035.png"],
    'tagname': 'purrgis',
    'skin': [],
    'icon': '/characters/icons/RGB/c1035_s.png',
    'skill': ["/characters/skill/sk_c1035_1.png",
              "/characters/skill/pa_c1035_2.png",
              "/characters/skill/sk_c1035_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1036',
    'name': 'Crozet',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Knight',
    'horoscope': 'Cancer',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1036.png"],
    'tagname': 'crozet',
    'skin': [],
    'icon': '/characters/icons/RGB/c1036_s.png',
    'skill': ["/characters/skill/sk_c1036_1.png",
              "/characters/skill/pa_c1036_2.png",
              "/characters/skill/sk_c1036_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1037',
    'name': 'Dominiel',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1037.png"],
    'tagname': 'dominiel',
    'skin': [],
    'icon': '/characters/icons/RGB/c1037_s.png',
    'skill': ["/characters/skill/sk_c1037_1.png",
              "/characters/skill/pa_c1037_2.png",
              "/characters/skill/sk_c1037_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1038',
    'name': 'Sez',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1038.png"],
    'tagname': 'sez',
    'skin': ["/characters/new_png/SKIN/c1038_s01.png"],
    'icon': '/characters/icons/RGB/c1038_s.png',
    'skill': ["/characters/skill/sk_c1038_1.png",
              "/characters/skill/pa_c1038_2.png",
              "/characters/skill/sk_c1038_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Land of Death',
    'artifact': {
        "Manica of Control": '/characters/artifact/art0095_fu.png'
    },
},
{ 
    'id': 'c1039',
    'name': 'Haste',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Thief',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1039.png"],
    'tagname': 'haste',
    'skin': [],
    'icon': '/characters/icons/RGB/c1039_s.png',
    'skill': ["/characters/skill/sk_c1039_1.png",
              "/characters/skill/sk_c1039_2.png",
              "/characters/skill/sk_c1039_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1040',
    'name': 'Serila',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Aquarius',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1040.png"],
    'tagname': 'serila',
    'skin': [],
    'icon': '/characters/icons/RGB/c1040_s.png',
    'skill': ["/characters/skill/sk_c1040_1.png",
              "/characters/skill/sk_c1040_2.png",
              "/characters/skill/sk_c1040_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1042',
    'name': 'Tywin',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Knight',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1042.png"],
    'tagname': 'tywin',
    'skin': [],
    'icon': '/characters/icons/RGB/c1042_s.png',
    'skill': ["/characters/skill/sk_c1042_1.png",
              "/characters/skill/pa_c1042_2.png",
              "/characters/skill/sk_c1042_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Crown of Glory": '/characters/artifact/art0103_fu.png'
    },
},
{ 
    'id': 'c1043',
    'name': 'Romann',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Gemini',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1043.png"],
    'tagname': 'romann',
    'skin': [],
    'icon': '/characters/icons/RGB/c1043_s.png',
    'skill': ["/characters/skill/sk_c1043_1.png",
              "/characters/skill/sk_c1043_2.png",
              "/characters/skill/sk_c1043_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka'
},
{ 
    'id': 'c1044',
    'name': 'Mui',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1044.png"],
    'tagname': 'mui',
    'skin': [],
    'icon': '/characters/icons/RGB/c1044_s.png',
    'skill': ["/characters/skill/sk_c1044_1.png",
              "/characters/skill/sk_c1044_2.png",
              "/characters/skill/sk_c1044_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Circus Fantasia": '/characters/artifact/art0100_fu.png'
    },
},
{ 
    'id': 'c1046',
    'name': 'Lidica',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1046.png"],
    'tagname': 'lidica',
    'skin': ["/characters/new_png/SKIN/c1046_s01.png"],
    'icon': '/characters/icons/RGB/c1046_s.png',
    'skill': ["/characters/skill/sk_c1046_1.png",
              "/characters/skill/sk_c1046_2.png",
              "/characters/skill/sk_c1046_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Land of Death',
    'artifact': {
        "Sword of Judgment": '/characters/artifact/art0041_fu.png'
    },
},
{ 
    'id': 'c1047',
    'name': 'Ken',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1047.png"],
    'tagname': 'ken',
    'skin': [],
    'icon': '/characters/icons/RGB/c1047_s.png',
    'skill': ["/characters/skill/sk_c1047_1.png",
              "/characters/skill/sk_c1047_2.png",
              "/characters/skill/sk_c1047_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Samsara Prayer Beads": '/characters/artifact/art0104_fu.png'
    },
},
{ 
    'id': 'c1048',
    'name': 'Aramintha',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1048.png"],
    'tagname': 'aramintha',
    'skin': [],
    'icon': '/characters/icons/RGB/c1048_s.png',
    'skill': ["/characters/skill/sk_c1048_1.png",
              "/characters/skill/sk_c1048_2.png",
              "/characters/skill/sk_c1048_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Land of Death'
},
{ 
    'id': 'c1049',
    'name': 'Chloe',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Warrior',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1049.png"],
    'tagname': 'chloe',
    'skin': [],
    'icon': '/characters/icons/RGB/c1049_s.png',
    'skill': ["/characters/skill/sk_c1049_1.png",
              "/characters/skill/sk_c1049_2.png",
              "/characters/skill/sk_c1049_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "A Little Queen's Huge Crown": '/characters/artifact/art0105_fu.png'
    },
},
{ 
    'id': 'c1050',
    'name': 'Tenebria',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Cancer',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1050.png"],
    'tagname': 'tenebria',
    'skin': ["/characters/new_png/SKIN/c1050_s01.png"],
    'icon': '/characters/icons/RGB/c1050_s.png',
    'skill': ["/characters/skill/sk_c1050_1.png",
              "/characters/skill/sk_c1050_2.png",
              "/characters/skill/sk_c1050_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Crimson Moon of Nightmares": '/characters/artifact/art0106_fu.png'
    },
},
{ 
    'id': 'c1053',
    'name': 'Basar',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Mage',
    'horoscope': 'Aquarius',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1053.png"],
    'tagname': 'basar',
    'skin': ["/characters/new_png/SKIN/c1053_s01.png"],
    'icon': '/characters/icons/RGB/c1053_s.png',
    'skill': ["/characters/skill/sk_c1053_1.png",
              "/characters/skill/sk_c1053_2.png",
              "/characters/skill/sk_c1053_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1054',
    'name': 'Rin',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Soul Weaver',
    'horoscope': 'Virgo',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1054.png"],
    'tagname': 'rin',
    'skin': [],
    'icon': '/characters/icons/RGB/c1054_s.png',
    'skill': ["/characters/skill/sk_c1054_1.png",
              "/characters/skill/sk_c1054_2.png",
              "/characters/skill/sk_c1054_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Land of Death'
},
{ 
    'id': 'c1055',
    'name': 'Jenua',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/RGB/c1055.png"],
    'tagname': 'jenua',
    'skin': [],
    'icon': '/characters/icons/RGB/c1055_s.png',
    'skill': ["/characters/skill/sk_c1055_1.png",
              "/characters/skill/pa_c1055_2.png",
              "/characters/skill/sk_c1055_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Dark Blood Keeper": '/characters/artifact/art0200_fu.png'
    },
},
{ 
    'id': 'c1060',
    'name': 'Schniel',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Soul Weaver',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/RGB/c1060.png"],
    'tagname': 'schniel',
    'skin': [],
    'icon': '/characters/icons/RGB/c1060_s.png',
    'skill': ["/characters/skill/sk_c1060_1.png",
                "/characters/skill/pa_c1060_2.png",
                "/characters/skill/sk_c1060_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Homage to Tarman": '/characters/artifact/art0210_fu.png'
    },
},
{ 
    'id': 'c1062',
    'name': 'Angelica',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Soul Weaver',
    'horoscope': 'Cancer',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1062.png"],
    'tagname': 'angelica',
    'skin': ["/characters/new_png/SKIN/c1062_s01.png"],
    'icon': '/characters/icons/RGB/c1062_s.png',
    'skill': ["/characters/skill/sk_c1062_1.png",
              "/characters/skill/sk_c1062_2.png",
              "/characters/skill/sk_c1062_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1065',
    'name': 'Surin',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Thief',
    'horoscope': 'Gemini',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1065.png"],
    'tagname': 'surin',
    'skin': [],
    'icon': '/characters/icons/RGB/c1065_s.png',
    'skill': ["/characters/skill/sk_c1065_1.png",
              "/characters/skill/sk_c1065_2.png",
              "/characters/skill/sk_c1065_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon'
},
{ 
    'id': 'c1067',
    'name': 'Tamarinne',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Soul Weaver',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1067.png"],
    'tagname': 'tamarinne',
    'skin': ["/characters/new_png/SKIN/c1067_s01.png"],
    'icon': '/characters/icons/RGB/c1067_s.png',
    'skill': ["/characters/skill/sk_c1067_1.png",
              "/characters/skill/sk_c1067_2.png",
              "/characters/skill/sk_c1067_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Idol's Cheer": '/characters/artifact/art0039_fu.png'
    },
},
{ 
    'id': 'c1069',
    'name': 'Ludwig',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Mage',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1069.png"],
    'tagname': 'ludwig',
    'skin': [],
    'icon': '/characters/icons/RGB/c1069_s.png',
    'skill': ["/characters/skill/sk_c1069_1.png",
              "/characters/skill/sk_c1069_2.png",
              "/characters/skill/sk_c1069_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
{ 
    'id': 'c1070',
    'name': 'Krau',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Knight',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1070.png"],
    'tagname': 'krau',
    'skin': [],
    'icon': '/characters/icons/RGB/c1070_s.png',
    'skill': ["/characters/skill/sk_c1070_1.png",
              "/characters/skill/sk_c1070_2.png",
              "/characters/skill/sk_c1070_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
{ 
    'id': 'c1071',
    'name': 'Bellona',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Ranger',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1071.png"],
    'tagname': 'bellona',
    'skin': [],
    'icon': '/characters/icons/RGB/c1071_s.png',
    'skill': ["/characters/skill/sk_c1071_1.png",
              "/characters/skill/sk_c1071_2.png",
              "/characters/skill/sk_c1071_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Land of Death',
    'artifact': {
        "Iron Fan": '/characters/artifact/art0038_fu.png'
    },
},
{ 
    'id': 'c1072',
    'name': 'Sigret',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Warrior',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c1072.png"],
    'tagname': 'sigret',
    'skin': [],
    'icon': '/characters/icons/RGB/c1072_s.png',
    'skill': ["/characters/skill/sk_c1072_1.png",
              "/characters/skill/sk_c1072_2.png",
              "/characters/skill/sk_c1072_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Cradle of Life": '/characters/artifact/art0093_fu.png'
    },
},
{ 
    'id': 'c1073',
    'name': 'Kawerik',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1073.png"],
    'tagname': 'kawerik',
    'skin': [],
    'icon': '/characters/icons/RGB/c1073_s.png',
    'skill': ["/characters/skill/sk_c1073_1.png",
              "/characters/skill/sk_c1073_2.png",
              "/characters/skill/sk_c1073_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia',
    'artifact': {
        "Black Hand of the Goddess": '/characters/artifact/art0088_fu.png'
    },
},
{ 
    'id': 'c1074',
    'name': 'Violet',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1074.png"],
    'tagname': 'violet',
    'skin': ["/characters/new_png/SKIN/c1074_s01.png"],
    'icon': '/characters/icons/RGB/c1074_s.png',
    'skill': ["/characters/skill/sk_c1074_1.png",
              "/characters/skill/sk_c1074_2.png",
              "/characters/skill/sk_c1074_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia',
    'artifact': {
        "Violet Talisman": '/characters/artifact/art0040_fu.png'
    },
},
{ 
    'id': 'c1079',
    'name': 'Cermia',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1079.png"],
    'tagname': 'cermia',
    'skin': ["/characters/new_png/SKIN/c1079_s01.png"],
    'icon': '/characters/icons/RGB/c1079_s.png',
    'skill': ["/characters/skill/sk_c1079_1.png",
              "/characters/skill/sk_c1079_2.png",
              "/characters/skill/sk_c1079_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Border Coin": '/characters/artifact/art0044_fu.png'
    },
},
{ 
    'id': 'c1080',
    'name': 'Pavel',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Ranger',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1080.png"],
    'tagname': 'pavel',
    'skin': [],
    'icon': '/characters/icons/RGB/c1080_s.png',
    'skill': ["/characters/skill/sk_c1080_1.png",
              "/characters/skill/sk_c1080_2.png",
              "/characters/skill/sk_c1080_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia',
    'artifact': {
        "Dux Noctis": '/characters/artifact/art0084_fu.png'
    },
},
{ 
    'id': 'c1082',
    'name': 'Luluca',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Aquarius',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1082.png"],
    'tagname': 'luluca',
    'skin': ["/characters/new_png/SKIN/c1082_s01.png"],
    'icon': '/characters/icons/RGB/c1082_s.png',
    'skill': ["/characters/skill/sk_c1082_1.png",
              "/characters/skill/sk_c1082_2.png",
              "/characters/skill/sk_c1082_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Spirit's Breath": '/characters/artifact/art0049_fu.png'
    },
},
{ 
    'id': 'c1083',
    'name': 'Zeno',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1083.png"],
    'tagname': 'zeno',
    'skin': [],
    'icon': '/characters/icons/RGB/c1083_s.png',
    'skill': ["/characters/skill/sk_c1083_1.png",
              "/characters/skill/pa_c1083_2.png",
              "/characters/skill/sk_c1083_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
{ 
    'id': 'c1085',
    'name': 'Khawazu',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1085.png"],
    'tagname': 'khawazu',
    'skin': [],
    'icon': '/characters/icons/RGB/c1085_s.png',
    'skill': ["/characters/skill/sk_c1085_1.png",
              "/characters/skill/pa_c1085_2.png",
              "/characters/skill/sk_c1085_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia'
},
{ 
    'id': 'c1086',
    'name': 'Khawana',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Thief',
    'horoscope': 'Pisces',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1086.png"],
    'tagname': 'khawana',
    'skin': [],
    'icon': '/characters/icons/RGB/c1086_s.png',
    'skill': ["/characters/skill/sk_c1086_1.png",
              "/characters/skill/sk_c1086_2.png",
              "/characters/skill/sk_c1086_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia'
},
{ 
    'id': 'c1087',
    'name': 'Furious',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Ranger',
    'horoscope': 'Leo',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1087.png"],
    'tagname': 'furious',
    'skin': [],
    'icon': '/characters/icons/RGB/c1087_s.png',
    'skill': ["/characters/skill/sk_c1087_1.png",
              "/characters/skill/sk_c1087_2.png",
              "/characters/skill/sk_c1087_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia'
},
{ 
    'id': 'c1088',
    'name': 'Vivian',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Mage',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1088.png"],
    'tagname': 'vivian',
    'skin': ["/characters/new_png/SKIN/c1088_s01.png"],
    'icon': '/characters/icons/RGB/c1088_s.png',
    'skill': ["/characters/skill/sk_c1088_1.png",
              "/characters/skill/sk_c1088_2.png",
              "/characters/skill/sk_c1088_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia',
    'artifact': {
        "Dignus Orb": '/characters/artifact/art0061_fu.png'
    },
},
{ 
    'id': 'c1089',
    'name': 'Lilias',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Knight',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1089.png"],
    'tagname': 'lilias',
    'skin': [],
    'icon': '/characters/icons/RGB/c1089_s.png',
    'skill': ["/characters/skill/sk_c1089_1.png",
              "/characters/skill/pa_c1089_2.png",
              "/characters/skill/sk_c1089_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia',
    'artifact': {
        "Bastion of Perlutia": '/characters/artifact/art0067_fu.png'
    },
},
{ 
    'id': 'c1090',
    'name': 'Ray',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Soul Weaver',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1090.png"],
    'tagname': 'ray',
    'skin': [],
    'icon': '/characters/icons/RGB/c1090_s.png',
    'skill': ["/characters/skill/sk_c1090_1.png",
              "/characters/skill/sk_c1090_2.png",
              "/characters/skill/sk_c1090_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia',
    'artifact': {
        "Doctor's Bag": '/characters/artifact/art0094_fu.png'
    },
},
{ 
    'id': 'c1091',
    'name': 'Elena',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Soul Weaver',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1091.png"],
    'tagname': 'elena',
    'skin': ["/characters/new_png/SKIN/c1091_s01.png"],
    'icon': '/characters/icons/RGB/c1091_s.png',
    'skill': ["/characters/skill/sk_c1091_1.png",
              "/characters/skill/pa_c1091_2.png",
              "/characters/skill/sk_c1091_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Cidonia',
    'artifact': {
        "Stella Harpa": '/characters/artifact/art0078_fu.png'
    },
},
{ 
    'id': 'c1095',
    'name': 'Lilibet',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1095.png"],
    'tagname': 'lilibet',
    'skin': [],
    'icon': '/characters/icons/RGB/c1095_s.png',
    'skill': ["/characters/skill/sk_c1095_1.png",
              "/characters/skill/sk_c1095_2.png",
              "/characters/skill/sk_c1095_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Creation & Destruction": '/characters/artifact/art0058_fu.png'
    },
},
{ 
    'id': 'c1096',
    'name': 'Melissa',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/RGB/c1096.png"],
    'tagname': 'melissa',
    'skin': [],
    'icon': '/characters/icons/RGB/c1096_s.png',
    'skill': ["/characters/skill/sk_c1096_1.png",
              "/characters/skill/sk_c1096_2.png",
              "/characters/skill/sk_c1096_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Erasia',
    'artifact': {
        "Bloody Rose": '/characters/artifact/art0069_fu.png'
    },
},
{ 
    'id': 'c1097',
    'name': 'Bomb Model Kanna',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/RGB/c1097.png"],
    'tagname': 'kanna',
    'skin': ["/characters/new_png/SKIN/c1097_s01.png"],
    'icon': '/characters/icons/RGB/c1097_s.png',
    'skill': ["/characters/skill/sk_c1097_1.png",
              "/characters/skill/pa_c1097_2.png",
              "/characters/skill/sk_c1097_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Air-to-Surface Missile: MISHA": '/characters/artifact/art_fu.png'
    },
},
{ 
    'id': 'c1099',
    'name': 'Command Model Laika',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/RGB/c1099.png"],
    'tagname': 'laika',
    'skin': [],
    'icon': '/characters/icons/RGB/c1099_s.png',
    'skill': ["/characters/skill/sk_c1099_1.png",
              "/characters/skill/sk_c1099_2.png",
              "/characters/skill/sk_c1099_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Glo-Wings 21": '/characters/artifact/art0149_fu.png'
    },
},
{ 
    'id': 'c1100',
    'name': 'Alencia',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Cancer',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1100.png"],
    'tagname': 'alencia',
    'skin': ["/characters/new_png/SKIN/c1100_s01.png"],
    'icon': '/characters/icons/RGB/c1100_s.png',
    'skill': ["/characters/skill/sk_c1100_1.png",
              "/characters/skill/pa_c1100_2.png",
              "/characters/skill/sk_c1100_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Alencinox's Wrath": '/characters/artifact/art0082_fu.png'
    },
},
{ 
    'id': 'c1101',
    'name': 'Choux',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Warrior',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1101.png"],
    'tagname': 'choux',
    'skin': ["/characters/new_png/SKIN/c1101_s01.png"],
    'icon': '/characters/icons/RGB/c1101_s.png',
    'skill': ["/characters/skill/sk_c1101_1.png",
              "/characters/skill/sk_c1101_2.png",
              "/characters/skill/sk_c1101_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Snow Crystal": '/characters/artifact/art0101_fu.png'
    },
},
{ 
    'id': 'c1102',
    'name': 'Roana',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Soul Weaver',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1102.png"],
    'tagname': 'roana',
    'skin': [],
    'icon': '/characters/icons/RGB/c1102_s.png',
    'skill': ["/characters/skill/sk_c1102_1.png",
              "/characters/skill/pa_c1102_2.png",
              "/characters/skill/sk_c1102_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Touch of Rekos": '/characters/artifact/art0091_fu.png'
    },
},
{ 
    'id': 'c1103',
    'name': 'Celine',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1103.png"],
    'tagname': 'celine',
    'skin': ["/characters/new_png/SKIN/c1103_s01.png"],
    'icon': '/characters/icons/RGB/c1103_s.png',
    'skill': ["/characters/skill/sk_c1103_1.png",
              "/characters/skill/pa_c1103_2.png",
              "/characters/skill/sk_c1103_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Foreign Land',
    'artifact': {
        "Secret Art - Storm Sword": '/characters/artifact/art0096_fu.png'
    },
},
{ 
    'id': 'c1104',
    'name': 'Mort',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Knight',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1104.png"],
    'tagname': 'mort',
    'skin': [],
    'icon': '/characters/icons/RGB/c1104_s.png',
    'skill': ["/characters/skill/sk_c1104_1.png",
              "/characters/skill/pa_c1104_2.png",
              "/characters/skill/sk_c1104_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Ancient Dragon's Legacy": '/characters/artifact/art0112_fu.png'
    },
},
{ 
    'id': 'c1106',
    'name': 'Senya',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Knight',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/RGB/c1106.png"],
    'tagname': 'senya',
    'skin': ["/characters/new_png/SKIN/c1106_s01.png"],
    'icon': '/characters/icons/RGB/c1106_s.png',
    'skill': ["/characters/skill/sk_c1106_1.png",
              "/characters/skill/pa_c1106_2.png",
              "/characters/skill/sk_c1106_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Spear of a New Dawn": '/characters/artifact/art0130_fu.png'
    },
},
{ 
    'id': 'c1108',
    'name': 'Ervalen',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1108.png"],
    'tagname': 'ervalen',
    'skin': [],
    'icon': '/characters/icons/RGB/c1108_s.png',
    'skill': ["/characters/skill/sk_c1108_1.png",
              "/characters/skill/sk_c1108_2.png",
              "/characters/skill/sk_c1108_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Double-Edged Decrescent": '/characters/artifact/art0116_fu.png'
    },
},
{ 
    'id': 'c1110',
    'name': 'Flan',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Ranger',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/RGB/c1110.png"],
    'tagname': 'flan',
    'skin': [],
    'icon': '/characters/icons/RGB/c1110_s.png',
    'skill': ["/characters/skill/sk_c1110_1.png",
              "/characters/skill/sk_c1110_2.png",
              "/characters/skill/sk_c1110_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Unseen Observer": '/characters/artifact/art0119_fu.png'
    },
},
{ 
    'id': 'c1111',
    'name': 'Eda',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/RGB/c1111.png"],
    'tagname': 'eda',
    'skin': [],
    'icon': '/characters/icons/RGB/c1111_s.png',
    'skill': ["/characters/skill/sk_c1111_1.png",
              "/characters/skill/sk_c1111_2.png",
              "/characters/skill/sk_c1111_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Twilight Calamity": '/characters/artifact/art0125_fu.png'
    },
},
{ 
    'id': 'c1112',
    'name': 'Politis',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/RGB/c1112.png"],
    'tagname': 'politis',
    'skin': [],
    'icon': '/characters/icons/RGB/c1112_s.png',
    'skill': ["/characters/skill/sk_c1112_1.png",
              "/characters/skill/pa_c1112_2.png",
              "/characters/skill/sk_c1112_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Knowledge Seed": '/characters/artifact/art0126_fu.png'
    },
},
{ 
    'id': 'c1113',
    'name': 'Ilynav',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Knight',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/RGB/c1113.png"],
    'tagname': 'ilynav',
    'skin': [],
    'icon': '/characters/icons/RGB/c1113_s.png',
    'skill': ["/characters/skill/sk_c1113_1.png",
              "/characters/skill/sk_c1113_2.png",
              "/characters/skill/sk_c1113_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Wings of Light and Shadow": '/characters/artifact/art0133_fu.png'
    },
},
{ 
    'id': 'c1118',
    'name': 'Ran',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Thief',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/RGB/c1118.png"],
    'tagname': 'ran',
    'skin': ["/characters/new_png/SKIN/c1118_s01.png"],
    'icon': '/characters/icons/RGB/c1118_s.png',
    'skill': ["/characters/skill/sk_c1118_1.png",
              "/characters/skill/sk_c1118_2.png",
              "/characters/skill/sk_c1118_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Sword of Winter Shadow": '/characters/artifact/art0144_fu.png'
    },
},
{ 
    'id': 'c1119',
    'name': 'Zahhak',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/RGB/c1119.png"],
    'tagname': 'zahhak',
    'skin': [],
    'icon': '/characters/icons/RGB/c1119_s.png',
    'skill': ["/characters/skill/sk_c1119_1.png",
              "/characters/skill/sk_c1119_2.png",
              "/characters/skill/sk_c1119_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Pure White Trust": '/characters/artifact/art0146_fu.png'
    },
},
{ 
    'id': 'c1124',
    'name': 'Arunka',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/RGB/c1124.png"],
    'tagname': 'arunka',
    'skin': [],
    'icon': '/characters/icons/RGB/c1124_s.png',
    'skill': ["/characters/skill/sk_c1124_1.png",
              "/characters/skill/pa_c1124_2.png",
              "/characters/skill/sk_c1124_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Sword of Autumn Eclipse": '/characters/artifact/art0167_fu.png'
    },
},
{ 
    'id': 'c1125',
    'name': 'Peira',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Thief',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/RGB/c1125.png"],
    'tagname': 'peira',
    'skin': ["/characters/new_png/SKIN/c1125_s01.png"],
    'icon': '/characters/icons/RGB/c1125_s.png',
    'skill': ["/characters/skill/sk_c1125_1.png",
              "/characters/skill/sk_c1125_2.png",
              "/characters/skill/sk_c1125_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Goblet of Oath": '/characters/artifact/art0150_fu.png'
    },
},
{ 
    'id': 'c1126',
    'name': 'Lua',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Ranger',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/RGB/c1126.png"],
    'tagname': 'lua',
    'skin': [],
    'icon': '/characters/icons/RGB/c1126_s.png',
    'skill': ["/characters/skill/sk_c1126_1.png",
              "/characters/skill/sk_c1126_2.png",
              "/characters/skill/sk_c1126_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Spatiotemporal Fan": '/characters/artifact/art0166_fu.png'
    },
},
{ 
    'id': 'c1127',
    'name': 'Taeyou',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Warrior',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/RGB/c1127.png"],
    'tagname': 'taeyou',
    'skin': [],
    'icon': '/characters/icons/RGB/c1127_s.png',
    'skill': ["/characters/skill/sk_c1127_1.png",
              "/characters/skill/pa_c1127_2.png",
              "/characters/skill/sk_c1127_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Spear of Purification": '/characters/artifact/art0156_fu.png'
    },
},
{ 
    'id': 'c1128',
    'name': 'Hwayoung',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/RGB/c1128.png"],
    'tagname': 'hwayoung',
    'skin': [],
    'icon': '/characters/icons/RGB/c1128_s.png',
    'skill': ["/characters/skill/sk_c1128_1.png",
              "/characters/skill/pa_c1128_2.png",
              "/characters/skill/sk_c1128_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Indestructible Gaiters": '/characters/artifact/art0155_fu.png'
    },
},
{ 
    'id': 'c1129',
    'name': 'Aria',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/RGB/c1129.png"],
    'tagname': 'aria',
    'skin': [],
    'icon': '/characters/icons/RGB/c1129_s.png',
    'skill': ["/characters/skill/sk_c1129_1.png",
              "/characters/skill/pa_c1129_2.png",
              "/characters/skill/sk_c1129_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Scroll of Shadows": '/characters/artifact/art0152_fu.png'
    },
},
{ 
    'id': 'c1131',
    'name': 'Yulha',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Knight',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/RGB/c1131.png"],
    'tagname': 'yulha',
    'skin': [],
    'icon': '/characters/icons/RGB/c1131_s.png',
    'skill': ["/characters/skill/sk_c1131_1.png",
              "/characters/skill/pa_c1131_2.png",
              "/characters/skill/sk_c1131_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Sphere of Sadism": '/characters/artifact/art0160_fu.png'
    },
},
{ 
    'id': 'c1132',
    'name': 'Sharun',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Soul Weaver',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/RGB/c1132.png"],
    'tagname': 'sharun',
    'skin': [],
    'icon': '/characters/icons/RGB/c1132_s.png',
    'skill': ["/characters/skill/sk_c1132_1.png",
              "/characters/skill/pa_c1132_2.png",
              "/characters/skill/sk_c1132_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Sacred Tree Branch": '/characters/artifact/art0164_fu.png'
    },
},
{ 
    'id': 'c1141',
    'name': 'Beehoo',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/RGB/c1141.png"],
    'tagname': 'beehoo',
    'skin': [],
    'icon': '/characters/icons/RGB/c1141_s.png',
    'skill': ["/characters/skill/sk_c1141_1.png",
              "/characters/skill/pa_c1141_2.png",
              "/characters/skill/sk_c1141_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Sphere of Inferno": '/characters/artifact/art0175_fu.png'
    },
},
{ 
    'id': 'c1142',
    'name': 'Eligos',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/RGB/c1142.png"],
    'tagname': 'eligos',
    'skin': [],
    'icon': '/characters/icons/RGB/c1142_s.png',
    'skill': ["/characters/skill/sk_c1142_1.png",
              "/characters/skill/pa_c1142_2.png",
              "/characters/skill/sk_c1142_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "An Offer You Can't Refuse": '/characters/artifact/art0178_fu.png'
    },
},
{ 
    'id': 'c1144',
    'name': 'Abigail',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/RGB/c1144.png"],
    'tagname': 'abigail',
    'skin': [],
    'icon': '/characters/icons/RGB/c1144_s.png',
    'skill': ["/characters/skill/sk_c1144_1.png",
              "/characters/skill/pa_c1144_2.png",
              "/characters/skill/sk_c1144_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Erasia',
    'artifact': {
        "Golden Rose": '/characters/artifact/art0181_fu.png'
    },
},
{ 
    'id': 'c1145',
    'name': 'Brieg',
    'gender': 'M',
    'element': 'Ice',
    'class': 'Knight',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/RGB/c1145.png"],
    'tagname': 'brieg',
    'skin': [],
    'icon': '/characters/icons/RGB/c1145_s.png',
    'skill': ["/characters/skill/sk_c1145_1.png",
              "/characters/skill/sk_c1145_2.png",
              "/characters/skill/sk_c1145_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Erasia',
    'artifact': {
        "Broken Will of the Priest": '/characters/artifact/art0185_fu.png'
    },
},
{ 
    'id': 'c1147',
    'name': 'Fumyr',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/RGB/c1147.png"],
    'tagname': 'fumyr',
    'skin': [],
    'icon': '/characters/icons/RGB/c1147_s.png',
    'skill': ["/characters/skill/sk_c1147_1.png",
              "/characters/skill/pa_c1147_2.png",
              "/characters/skill/sk_c1147_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Erasia',
    'artifact': {
        "Umbral Keystones": '/characters/artifact/art0189_fu.png'
    },
},
{ 
    'id': 'c1148',
    'name': 'Elvira',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Thief',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/RGB/c1148.png"],
    'tagname': 'elvira',
    'skin': [],
    'icon': '/characters/icons/RGB/c1148_s.png',
    'skill': ["/characters/skill/sk_c1148_1.png",
              "/characters/skill/pa_c1148_2.png",
              "/characters/skill/sk_c1148_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Erasia',
    'artifact': {
        "Beguiling Wings": '/characters/artifact/art0192_fu.png'
    },
},
{ 
    'id': 'c1150',
    'name': 'Veronica',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Virgo',
    'rarity': 4,
    'release': 2023,
    'image': ["/characters/new_png/RGB/c1150.png"],
    'tagname': 'veronica',
    'skin': [],
    'icon': '/characters/icons/RGB/c1150_s.png',
    'skill': ["/characters/skill/sk_c1150_1.png",
              "/characters/skill/pa_c1150_2.png",
              "/characters/skill/sk_c1150_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Erasia'
},
{ 
    'id': 'c1151',
    'name': 'Nahkwol',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/RGB/c1151.png"],
    'tagname': 'nahkwol',
    'skin': [],
    'icon': '/characters/icons/RGB/c1151_s.png',
    'skill': ["/characters/skill/sk_c1151_1.png",
              "/characters/skill/sk_c1151_2.png",
              "/characters/skill/sk_c1151_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Elegiac Candle": '/characters/artifact/art0184_fu.png'
    },
},
{ 
    'id': 'c1152',
    'name': 'Kane',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/RGB/c1152.png"],
    'tagname': 'kane',
    'skin': [],
    'icon': '/characters/icons/RGB/c1152_s.png',
    'skill': ["/characters/skill/sk_c1152_1.png",
              "/characters/skill/pa_c1152_2.png",
              "/characters/skill/sk_c1152_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Erasia',
    'artifact': {
        "Tyrant's Descent": '/characters/artifact/art0188_fu.png'
    },
},
{ 
    'id': 'c1158',
    'name': 'Fenris',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Ranger',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/RGB/c1158.png"],
    'tagname': 'fenris',
    'skin': [],
    'icon': '/characters/icons/RGB/c1158_s.png',
    'skill': ["/characters/skill/sk_c1158_1.png",
                "/characters/skill/pa_c1158_2.png",
                "/characters/skill/sk_c1158_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Erasia',
    'artifact': {
        "Renewed Will": '/characters/artifact/art0209_fu.png'
    },
},
{ 
    'id': 'c1160',
    'name': 'Birgitta',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Soul Weaver',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/RGB/c1160.png"],
    'tagname': 'birgitta',
    'skin': [],
    'icon': '/characters/icons/RGB/c1160_s.png',
    'skill': ["/characters/skill/sk_c1160_1.png",
              "/characters/skill/sk_c1160_2.png",
              "/characters/skill/sk_c1160_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Erasia',
    'artifact': {
        "Bird's-Eye View": '/characters/artifact/art0201_fu.png'
    },
},
{ 
    'id': 'c1161',
    'name': 'Immortal Wukong',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/RGB/c1161.png"],
    'tagname': 'wukong',
    'skin': [],
    'icon': '/characters/icons/RGB/c1161_s.png',
    'skill': ["/characters/skill/sk_c1161_1.png",
              "/characters/skill/pa_c1161_2.png",
              "/characters/skill/sk_c1161_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Ruyi Jingu Bang": '/characters/artifact/art0202_fu.png'
    }
},
{ 
    'id': 'c2022',
    'name': 'Destina',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Soul Weaver',
    'horoscope': 'Cancer',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/RGB/c2022.png"],
    'tagname': 'destina',
    'skin': ["/characters/new_png/SKIN/c2022_s01.png"],
    'icon': '/characters/icons/RGB/c2022_s.png',
    'skill': ["/characters/skill/sk_c2022_1.png",
                "/characters/skill/sk_c2022_2.png",
                "/characters/skill/sk_c2022_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
/************************** RGB Limited ****************************/
{ 
    'id': 'c1066',
    'name': 'Luna',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Warrior',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/ALT/c1066.png"],
    'tagname': 'luna',
    'skin': ["/characters/new_png/SKIN/c1066_s01.png"],
    'icon': '/characters/icons/ALT/c1066_s.png',
    'skill': ["/characters/skill/sk_c1066_1.png",
              "/characters/skill/pa_c1066_2.png",
              "/characters/skill/sk_c1066_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Eureka'
},
{ 
    'id': 'c1076',
    'name': 'Diene',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Soul Weaver',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/ALT/c1076.png"],
    'tagname': 'diene',
    'skin': ["/characters/new_png/SKIN/c1076_s01.png"],
    'icon': '/characters/icons/ALT/c1076_s.png',
    'skill': ["/characters/skill/sk_c1076_1.png",
              "/characters/skill/sk_c1076_2.png",
              "/characters/skill/sk_c1076_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Unfading Memories": '/characters/artifact/art0079_fu.png'
    },
},
{ 
    'id': 'c1081',
    'name': 'Cerise',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Ranger',
    'horoscope': 'Aquarius',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ALT/c1081.png"],
    'tagname': 'cerise',
    'skin': ["/characters/new_png/SKIN/c1081_s01.png"],
    'icon': '/characters/icons/ALT/c1081_s.png',
    'skill': ["/characters/skill/sk_c1081_1.png",
              "/characters/skill/sk_c1081_2.png",
              "/characters/skill/sk_c1081_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Cidonia',
    'artifact': {
        "Guiding Light": '/characters/artifact/art0086_fu.png'
    },
},
{ 
    'id': 'c1109',
    'name': 'Landy',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Ranger',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ALT/c1109.png"],
    'tagname': 'landy',
    'skin': [],
    'icon': '/characters/icons/ALT/c1109_s.png',
    'skill': ["/characters/skill/sk_c1109_1.png",
              "/characters/skill/pa_c1109_2.png",
              "/characters/skill/sk_c1109_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Wall of Order": '/characters/artifact/art0107_fu.png'
    },
},
{ 
    'id': 'c1143',
    'name': 'Amid',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Soul Weaver',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ALT/c1143.png"],
    'tagname': 'amid',
    'skin': [],
    'icon': '/characters/icons/ALT/c1143_s.png',
    'skill': ["/characters/skill/sk_c1143_1.png",
              "/characters/skill/sk_c1143_2.png",
              "/characters/skill/sk_c1143_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Natalon',
    'artifact': {
        "Fan of Light and Dark": '/characters/artifact/art0176_fu.png'
    },
},
{ 
    'id': 'c1154',
    'name': 'Byblis',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Ranger',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ALT/c1154.png"],
    'tagname': 'byblis',
    'skin': [],
    'icon': '/characters/icons/ALT/c1154_s.png',
    'skill': ["/characters/skill/sk_c1154_1.png",
              "/characters/skill/pa_c1154_2.png",
              "/characters/skill/sk_c1154_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Ritania'
},
{ 
    'id': 'c1159',
    'name': 'Laia',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ALT/c1159.png"],
    'tagname': 'laia',
    'skin': [],
    'icon': '/characters/icons/ALT/c1159_s.png',
    'skill': ["/characters/skill/sk_c1159_1.png",
              "/characters/skill/sk_c1159_2.png",
              "/characters/skill/sk_c1159_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Sweet Miracle": '/characters/artifact/art0198_fu.png'
    },
},
{ 
    'id': 'c1163',
    'name': 'Frida',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Soul Weaver',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ALT/c1163.png"],
    'tagname': 'frida',
    'skin': [],
    'icon': '/characters/icons/ALT/c1163_s.png',
    'skill': ["/characters/skill/sk_c1163_1.png",
               "/characters/skill/pa_c1163_2.png",
               "/characters/skill/sk_c1163_3.png"
       ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': '',
    'artifact': {
        "Spotted Mouse Hair Tie": '/characters/artifact/art0205_fu.png'
    },
},
{ 
    'id': 'c5110',
    'name': 'Afternoon Soak Flan',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ALT/c5110.png"],
    'tagname': 'flan',
    'skin': [],
    'icon': '/characters/icons/ALT/c5110_s.png',
    'skill': ["/characters/skill/sk_c5110_1.png",
                "/characters/skill/pa_c5110_2.png",
                "/characters/skill/sk_c5110_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Dreamlike Holiday": '/characters/artifact/art0207_fu.png'
    },
},
/********************************** ML *****************************/
{ 
    'id': 'c1022',
    'name': 'Ruele of Light',
    'gender': 'F',
    'element': 'Light',
    'class': 'Soul Weaver',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/ML/c1022.png"],
    'tagname': 'destina',
    'skin': ["/characters/new_png/SKIN/c1022_s01.png"],
    'icon': '/characters/icons/ML/c1022_s.png',
    'skill': ["/characters/skill/sk_c1022_1.png",
              "/characters/skill/sk_c1022_2.png",
              "/characters/skill/sk_c1022_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c1034',
    'name': 'Straze',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ML/c1034.png"],
    'tagname': 'straze',
    'skin': [],
    'icon': '/characters/icons/ML/c1034_s.png',
    'skill': ["/characters/skill/sk_c1034_1.png",
              "/characters/skill/sk_c1034_2.png",
              "/characters/skill/sk_c1034_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
{ 
    'id': 'c1117',
    'name': 'Belian',
    'gender': 'F',
    'element': 'Light',
    'class': 'Knight',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ML/c1117.png"],
    'tagname': 'belian',
    'skin': ["/characters/new_png/SKIN/c1117_s01.png"],
    'icon': '/characters/icons/ML/c1117_s.png',
    'skill': ["/characters/skill/sk_c1117_1.png",
              "/characters/skill/pa_c1117_2.png",
              "/characters/skill/sk_c1117_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
{ 
    'id': 'c1133',
    'name': 'Zio',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/ML/c1133.png"],
    'tagname': 'zio',
    'skin': [],
    'icon': '/characters/icons/ML/c1133_s.png',
    'skill': ["/characters/skill/sk_c1133_1.png",
              "/characters/skill/pa_c1133_2.png",
              "/characters/skill/sk_c1133_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
{ 
    'id': 'c1153',
    'name': 'Harsetti',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ML/c1153.png"],
    'tagname': 'harsetti',
    'skin': [],
    'icon': '/characters/icons/ML/c1153_s.png',
    'skill': ["/characters/skill/sk_c1153_1.png",
              "/characters/skill/pa_c1153_2.png",
              "/characters/skill/sk_c1153_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
{ 
    'id': 'c2002',
    'name': 'Fallen Cecilia',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Knight',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2002.png"],
    'tagname': 'cecilia',
    'skin': ["/characters/new_png/SKIN/c2002_s01.png"],
    'icon': '/characters/icons/ML/c2002_s.png',
    'skill': ["/characters/skill/sk_c2002_1.png",
              "/characters/skill/pa_c2002_2.png",
              "/characters/skill/sk_c2002_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2003',
    'name': 'Shadow Rose',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Knight',
    'horoscope': 'Gemini',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2003.png"],
    'tagname': 'rose',
    'skin': [],
    'icon': '/characters/icons/ML/c2003_s.png',
    'skill': ["/characters/skill/sk_c2003_1.png",
              "/characters/skill/sk_c2003_2.png",
              "/characters/skill/sk_c2003_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2004',
    'name': 'Wanderer Silk',
    'gender': 'F',
    'element': 'Light',
    'class': 'Ranger',
    'horoscope': 'Capricorn',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2004.png"],
    'tagname': 'silk',
    'skin': [],
    'icon': '/characters/icons/ML/c2004_s.png',
    'skill': ["/characters/skill/sk_c2004_1.png",
              "/characters/skill/sk_c2004_2.png",
              "/characters/skill/sk_c2004_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2005',
    'name': 'Celestial Mercedes',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2005.png"],
    'tagname': 'mercedes',
    'skin': [],
    'icon': '/characters/icons/ML/c2005_s.png',
    'skill': ["/characters/skill/sk_c2005_1.png",
              "/characters/skill/sk_c2005_2.png",
              "/characters/skill/sk_c2005_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2006',
    'name': 'Judge Kise',
    'gender': 'F',
    'element': 'Light',
    'class': 'Warrior',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2006.png"],
    'tagname': 'kise',
    'skin': ["/characters/new_png/SKIN/c2006_s01.png"],
    'icon': '/characters/icons/ML/c2006_s.png',
    'skill': ["/characters/skill/sk_c2006_1.png",
              "/characters/skill/sk_c2006_2.png",
              "/characters/skill/sk_c2006_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2007',
    'name': 'Arbiter Vildred',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2007.png"],
    'tagname': 'vildred',
    'skin': ["/characters/new_png/SKIN/c2007_s01.png"],
    'icon': '/characters/icons/ML/c2007_s.png',
    'skill': ["/characters/skill/sk_c2007_1.png",
              "/characters/skill/pa_c2007_2.png",
              "/characters/skill/sk_c2007_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2008',
    'name': 'Crimson Armin',
    'gender': 'F',
    'element': 'Light',
    'class': 'Knight',
    'horoscope': 'Libra',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2008.png"],
    'tagname': 'armin',
    'skin': [],
    'icon': '/characters/icons/ML/c2008_s.png',
    'skill': ["/characters/skill/sk_c2008_1.png",
              "/characters/skill/pa_c2008_2.png",
              "/characters/skill/sk_c2008_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2009',
    'name': 'Little Queen Charlotte',
    'gender': 'F',
    'element': 'Light',
    'class': 'Warrior',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ML/c2009.png"],
    'tagname': 'charlotte',
    'skin': ["/characters/new_png/SKIN/c2009_s01.png"],
    'icon': '/characters/icons/ML/c2009_s.png',
    'skill': ["/characters/skill/sk_c2009_1.png",
              "/characters/skill/pa_c2009_2.png",
              "/characters/skill/sk_c2009_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2010',
    'name': 'Champion Zerato',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Aries',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2010.png"],
    'tagname': 'zerato',
    'skin': [],
    'icon': '/characters/icons/ML/c2010_s.png',
    'skill': ["/characters/skill/sk_c2010_1.png",
              "/characters/skill/pa_c2010_2.png",
              "/characters/skill/sk_c2010_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2011',
    'name': 'Blood Blade Karin',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2011.png"],
    'tagname': 'karin',
    'skin': [],
    'icon': '/characters/icons/ML/c2011_s.png',
    'skill': ["/characters/skill/sk_c2011_1.png",
              "/characters/skill/pa_c2011_2.png",
              "/characters/skill/sk_c2011_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2012',
    'name': 'Dark Corvus',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2012.png"],
    'tagname': 'corvus',
    'skin': [],
    'icon': '/characters/icons/ML/c2012_s.png',
    'skill': ["/characters/skill/sk_c2012_1.png",
              "/characters/skill/pa_c2012_2.png",
              "/characters/skill/sk_c2012_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2013',
    'name': 'Assassin Cartuja',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2013.png"],
    'tagname': 'cartuja',
    'skin': [],
    'icon': '/characters/icons/ML/c2013_s.png',
    'skill': ["/characters/skill/sk_c2013_1.png",
              "/characters/skill/pa_c2013_2.png",
              "/characters/skill/sk_c2013_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2014',
    'name': 'Assassin Cidd',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Thief',
    'horoscope': 'Aquarius',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2014.png"],
    'tagname': 'cidd',
    'skin': [],
    'icon': '/characters/icons/ML/c2014_s.png',
    'skill': ["/characters/skill/sk_c2014_1.png",
              "/characters/skill/pa_c2014_2.png",
              "/characters/skill/sk_c2014_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2015',
    'name': 'Sage Baal & Sezan',
    'gender': 'M',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2015.png"],
    'tagname': 'baal',
    'skin': [],
    'icon': '/characters/icons/ML/c2015_s.png',
    'skill': ["/characters/skill/sk_c2015_1.png",
              "/characters/skill/sk_c2015_2.png",
              "/characters/skill/sk_c2015_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2016',
    'name': 'Abyssal Yufine',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Knight',
    'horoscope': 'Aquarius',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2016.png"],
    'tagname': 'yufine',
    'skin': [],
    'icon': '/characters/icons/ML/c2016_s.png',
    'skill': ["/characters/skill/sk_c2016_1.png",
              "/characters/skill/pa_c2016_2.png",
              "/characters/skill/sk_c2016_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2017',
    'name': 'Shooting Star Achates',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Soul Weaver',
    'horoscope': 'Cancer',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2017.png"],
    'tagname': 'achates',
    'skin': [],
    'icon': '/characters/icons/ML/c2017_s.png',
    'skill': ["/characters/skill/sk_c2017_1.png",
              "/characters/skill/sk_c2017_2.png",
              "/characters/skill/sk_c2017_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2018',
    'name': 'Guider Aither',
    'gender': 'M',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Cancer',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2018.png"],
    'tagname': 'aither',
    'skin': [],
    'icon': '/characters/icons/ML/c2018_s.png',
    'skill': ["/characters/skill/sk_c2018_1.png",
              "/characters/skill/sk_c2018_2.png",
              "/characters/skill/sk_c2018_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2019',
    'name': 'Apocalypse Ravi',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Cancer',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2019.png"],
    'tagname': 'ravi',
    'skin': ["/characters/new_png/SKIN/c2019_s01.png"],
    'icon': '/characters/icons/ML/c2019_s.png',
    'skill': ["/characters/skill/sk_c2019_1.png",
              "/characters/skill/pa_c2019_2.png",
              "/characters/skill/sk_c2019_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2020',
    'name': 'Watcher Schuri',
    'gender': 'M',
    'element': 'Light',
    'class': 'Ranger',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2020.png"],
    'tagname': 'schuri',
    'skin': [],
    'icon': '/characters/icons/ML/c2020_s.png',
    'skill': ["/characters/skill/sk_c2020_1.png",
              "/characters/skill/sk_c2020_2.png",
              "/characters/skill/sk_c2020_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2021',
    'name': 'Blaze Dingo',
    'gender': 'M',
    'element': 'Light',
    'class': 'Soul Weaver',
    'horoscope': 'Sagittarius',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2021.png"],
    'tagname': 'dingo',
    'skin': [],
    'icon': '/characters/icons/ML/c2021_s.png',
    'skill': ["/characters/skill/sk_c2021_1.png",
              "/characters/skill/sk_c2021_2.png",
              "/characters/skill/sk_c2021_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2023',
    'name': 'Twisted Eidolon Kayron',
    'gender': 'M',
    'element': 'Light',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2023.png"],
    'tagname': 'kayron',
    'skin': [],
    'icon': '/characters/icons/ML/c2023_s.png',
    'skill': ["/characters/skill/sk_c2023_1.png",
              "/characters/skill/pa_c2023_2.png",
              "/characters/skill/sk_c2023_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2024',
    'name': 'Briar Witch Iseria',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ML/c2024.png"],
    'tagname': 'iseria',
    'skin': ["/characters/new_png/SKIN/c2024_s01.png"],
    'icon': '/characters/icons/ML/c2024_s.png',
    'skill': ["/characters/skill/sk_c2024_1.png",
              "/characters/skill/pa_c2024_2.png",
              "/characters/skill/sk_c2024_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2027',
    'name': 'Closer Charles',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ML/c2027.png"],
    'tagname': 'charles',
    'skin': [],
    'icon': '/characters/icons/ML/c2027_s.png',
    'skill': ["/characters/skill/sk_c2027_1.png",
              "/characters/skill/pa_c2027_2.png",
              "/characters/skill/sk_c2027_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2028',
    'name': 'Kitty Clarissa',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Virgo',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2028.png"],
    'tagname': 'clarissa',
    'skin': [],
    'icon': '/characters/icons/ML/c2028_s.png',
    'skill': ["/characters/skill/sk_c2028_1.png",
              "/characters/skill/sk_c2028_2.png",
              "/characters/skill/sk_c2028_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2029',
    'name': 'Roaming Warrior Leo',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2029.png"],
    'tagname': 'leo',
    'skin': [],
    'icon': '/characters/icons/ML/c2029_s.png',
    'skill': ["/characters/skill/sk_c2029_1.png",
              "/characters/skill/sk_c2029_2.png",
              "/characters/skill/sk_c2029_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2031',
    'name': 'Auxiliary Lots',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Pisces',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2031.png"],
    'tagname': 'lots',
    'skin': [],
    'icon': '/characters/icons/ML/c2031_s.png',
    'skill': ["/characters/skill/sk_c2031_1.png",
              "/characters/skill/sk_c2031_2.png",
              "/characters/skill/sk_c2031_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2032',
    'name': 'Fighter Maya',
    'gender': 'F',
    'element': 'Light',
    'class': 'Knight',
    'horoscope': 'Libra',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2032.png"],
    'tagname': 'maya',
    'skin': [],
    'icon': '/characters/icons/ML/c2032_s.png',
    'skill': ["/characters/skill/sk_c2032_1.png",
              "/characters/skill/pa_c2032_2.png",
              "/characters/skill/sk_c2032_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2033',
    'name': 'Assassin Coli',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Thief',
    'horoscope': 'Taurus',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2033.png"],
    'tagname': 'coli',
    'skin': [],
    'icon': '/characters/icons/ML/c2033_s.png',
    'skill': ["/characters/skill/sk_c2033_1.png",
              "/characters/skill/pa_c2033_2.png",
              "/characters/skill/sk_c2033_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2035',
    'name': 'General Purrgis',
    'gender': 'M',
    'element': 'Light',
    'class': 'Warrior',
    'horoscope': 'Cancer',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2035.png"],
    'tagname': 'purrgis',
    'skin': [],
    'icon': '/characters/icons/ML/c2035_s.png',
    'skill': ["/characters/skill/sk_c2035_1.png",
              "/characters/skill/pa_c2035_2.png",
              "/characters/skill/sk_c2035_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2036',
    'name': 'Troublemaker Crozet',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Knight',
    'horoscope': 'Pisces',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2036.png"],
    'tagname': 'crozet',
    'skin': [],
    'icon': '/characters/icons/ML/c2036_s.png',
    'skill': ["/characters/skill/sk_c2036_1.png",
              "/characters/skill/pa_c2036_2.png",
              "/characters/skill/sk_c2036_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2037',
    'name': 'Challenger Dominiel',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2037.png"],
    'tagname': 'dominiel',
    'skin': [],
    'icon': '/characters/icons/ML/c2037_s.png',
    'skill': ["/characters/skill/sk_c2037_1.png",
              "/characters/skill/pa_c2037_2.png",
              "/characters/skill/sk_c2037_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2038',
    'name': 'Specimen Sez',
    'gender': 'M',
    'element': 'Light',
    'class': 'Thief',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2038.png"],
    'tagname': 'sez',
    'skin': [],
    'icon': '/characters/icons/ML/c2038_s.png',
    'skill': ["/characters/skill/sk_c2038_1.png",
              "/characters/skill/sk_c2038_2.png",
              "/characters/skill/sk_c2038_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2039',
    'name': 'Blood Moon Haste',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Soul Weaver',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ML/c2039.png"],
    'tagname': 'haste',
    'skin': [],
    'icon': '/characters/icons/ML/c2039_s.png',
    'skill': ["/characters/skill/sk_c2039_1.png",
              "/characters/skill/pa_c2039_2.png",
              "/characters/skill/sk_c2039_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2042',
    'name': 'Ambitious Tywin',
    'gender': 'M',
    'element': 'Light',
    'class': 'Knight',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2042.png"],
    'tagname': 'tywin',
    'skin': [],
    'icon': '/characters/icons/ML/c2042_s.png',
    'skill': ["/characters/skill/sk_c2042_1.png",
              "/characters/skill/pa_c2042_2.png",
              "/characters/skill/sk_c2042_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2043',
    'name': 'Benevolent Romann',
    'gender': 'M',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2043.png"],
    'tagname': 'romann',
    'skin': [],
    'icon': '/characters/icons/ML/c2043_s.png',
    'skill': ["/characters/skill/sk_c2043_1.png",
              "/characters/skill/pa_c2043_2.png",
              "/characters/skill/sk_c2043_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2046',
    'name': 'Faithless Lidica',
    'gender': 'F',
    'element': 'Light',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2046.png"],
    'tagname': 'lidica',
    'skin': ["/characters/new_png/SKIN/c2046_s01.png"],
    'icon': '/characters/icons/ML/c2046_s.png',
    'skill': ["/characters/skill/sk_c2046_1.png",
              "/characters/skill/sk_c2046_2.png",
              "/characters/skill/sk_c2046_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2047',
    'name': 'Martial Artist Ken',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2047.png"],
    'tagname': 'ken',
    'skin': [],
    'icon': '/characters/icons/ML/c2047_s.png',
    'skill': ["/characters/skill/sk_c2047_1.png",
              "/characters/skill/pa_c2047_2.png",
              "/characters/skill/sk_c2047_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2048',
    'name': 'Silver Blade Aramintha',
    'gender': 'F',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2048.png"],
    'tagname': 'aramintha',
    'skin': [],
    'icon': '/characters/icons/ML/c2048_s.png',
    'skill': ["/characters/skill/sk_c2048_1.png",
              "/characters/skill/pa_c2048_2.png",
              "/characters/skill/sk_c2048_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2049',
    'name': 'Maid Chloe',
    'gender': 'F',
    'element': 'Light',
    'class': 'Soul Weaver',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2049.png"],
    'tagname': 'chloe',
    'skin': ["/characters/new_png/SKIN/c2049_s01.png"],
    'icon': '/characters/icons/ML/c2049_s.png',
    'skill': ["/characters/skill/sk_c2049_1.png",
              "/characters/skill/pa_c2049_2.png",
              "/characters/skill/sk_c2049_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2050',
    'name': 'Specter Tenebria',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2018,
    'image': ["/characters/new_png/ML/c2050.png"],
    'tagname': 'tenebria',
    'skin': ["/characters/new_png/SKIN/c2050_s01.png"],
    'icon': '/characters/icons/ML/c2050_s.png',
    'skill': ["/characters/skill/sk_c2050_1.png",
              "/characters/skill/pa_c2050_2.png",
              "/characters/skill/sk_c2050_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2053',
    'name': 'Desert Jewel Basar',
    'gender': 'M',
    'element': 'Light',
    'class': 'Soul Weaver',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2053.png"],
    'tagname': 'basar',
    'skin': [],
    'icon': '/characters/icons/ML/c2053_s.png',
    'skill': ["/characters/skill/sk_c2053_1.png",
              "/characters/skill/sk_c2053_2.png",
              "/characters/skill/sk_c2053_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2054',
    'name': 'Crescent Moon Rin',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Thief',
    'horoscope': 'Taurus',
    'rarity': 4,
    'release': 2019,
    'image': ["/characters/new_png/ML/c2054.png"],
    'tagname': 'rin',
    'skin': [],
    'icon': '/characters/icons/ML/c2054_s.png',
    'skill': ["/characters/skill/sk_c2054_1.png",
              "/characters/skill/sk_c2054_2.png",
              "/characters/skill/sk_c2054_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2062',
    'name': 'Sinful Angelica',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Soul Weaver',
    'horoscope': 'Taurus',
    'rarity': 4,
    'release': 2020,
    'image': ["/characters/new_png/ML/c2062.png"],
    'tagname': 'angelica',
    'skin': ["/characters/new_png/SKIN/c2062_s01.png"],
    'icon': '/characters/icons/ML/c2062_s.png',
    'skill': ["/characters/skill/sk_c2062_1.png",
              "/characters/skill/pa_c2062_2.png",
              "/characters/skill/sk_c2062_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2065',
    'name': 'Tempest Surin',
    'gender': 'F',
    'element': 'Light',
    'class': 'Thief',
    'horoscope': 'Gemini',
    'rarity': 4,
    'release': 2020,
    'image': ["/characters/new_png/ML/c2065.png"],
    'tagname': 'surin',
    'skin': ["/characters/new_png/SKIN/c2065_s01.png"],
    'icon': '/characters/icons/ML/c2065_s.png',
    'skill': ["/characters/skill/sk_c2065_1.png",
              "/characters/skill/pa_c2065_2.png",
              "/characters/skill/sk_c2065_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2066',
    'name': 'New Moon Luna',
    'gender': 'F',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ML/c2066.png"],
    'tagname': 'luna',
    'skin': [],
    'icon': '/characters/icons/ML/c2066_s.png',
    'skill': ["/characters/skill/sk_c2066_1.png",
              "/characters/skill/sk_c2066_2.png",
              "/characters/skill/sk_c2066_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2069',
    'name': 'Eternal Wanderer Ludwig',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2069.png"],
    'tagname': 'ludwig',
    'skin': [],
    'icon': '/characters/icons/ML/c2069_s.png',
    'skill': ["/characters/skill/sk_c2069_1.png",
              "/characters/skill/sk_c2069_2.png",
              "/characters/skill/sk_c2069_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2070',
    'name': 'Last Rider Krau',
    'gender': 'M',
    'element': 'Light',
    'class': 'Knight',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ML/c2070.png"],
    'tagname': 'krau',
    'skin': ["/characters/new_png/SKIN/c2070_s01.png"],
    'icon': '/characters/icons/ML/c2070_s.png',
    'skill': ["/characters/skill/sk_c2070_1.png",
              "/characters/skill/pa_c2070_2.png",
              "/characters/skill/sk_c2070_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2071',
    'name': 'Lone Crescent Bellona',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2071.png"],
    'tagname': 'bellona',
    'skin': [],
    'icon': '/characters/icons/ML/c2071_s.png',
    'skill': ["/characters/skill/sk_c2071_1.png",
              "/characters/skill/pa_c2071_2.png",
              "/characters/skill/sk_c2071_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2072',
    'name': 'Operator Sigret',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Ranger',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ML/c2072.png"],
    'tagname': 'sigret',
    'skin': [],
    'icon': '/characters/icons/ML/c2072_s.png',
    'skill': ["/characters/skill/sk_c2072_1.png",
              "/characters/skill/sk_c2072_2.png",
              "/characters/skill/sk_c2072_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2073',
    'name': 'Mediator Kawerik',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ML/c2073.png"],
    'tagname': 'kawerik',
    'skin': ["/characters/new_png/SKIN/c2073_s01.png"],
    'icon': '/characters/icons/ML/c2073_s.png',
    'skill': ["/characters/skill/sk_c2073_1.png",
              "/characters/skill/sk_c2073_2.png",
              "/characters/skill/sk_c2073_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2074',
    'name': 'Remnant Violet',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ML/c2074.png"],
    'tagname': 'violet',
    'skin': [],
    'icon': '/characters/icons/ML/c2074_s.png',
    'skill': ["/characters/skill/sk_c2074_1.png",
              "/characters/skill/pa_c2074_2.png",
              "/characters/skill/sk_c2074_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2079',
    'name': 'Lionheart Cermia',
    'gender': 'F',
    'element': 'Light',
    'class': 'Warrior',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/ML/c2079.png"],
    'tagname': 'cermia',
    'skin': ["/characters/new_png/SKIN/c2079_s01.png"],
    'icon': '/characters/icons/ML/c2079_s.png',
    'skill': ["/characters/skill/sk_c2079_1.png",
              "/characters/skill/pa_c2079_2.png",
              "/characters/skill/sk_c2079_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2080',
    'name': 'Commander Pavel',
    'gender': 'M',
    'element': 'Light',
    'class': 'Ranger',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/ML/c2080.png"],
    'tagname': 'pavel',
    'skin': [],
    'icon': '/characters/icons/ML/c2080_s.png',
    'skill': ["/characters/skill/sk_c2080_1.png",
              "/characters/skill/pa_c2080_2.png",
              "/characters/skill/sk_c2080_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2082',
    'name': 'Top Model Luluca',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ML/c2082.png"],
    'tagname': 'luluca',
    'skin': [],
    'icon': '/characters/icons/ML/c2082_s.png',
    'skill': ["/characters/skill/sk_c2082_1.png",
              "/characters/skill/sk_c2082_2.png",
              "/characters/skill/sk_c2082_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2085',
    'name': 'Inferno Khawazu',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2021,
    'image': ["/characters/new_png/ML/c2085.png"],
    'tagname': 'khawazu',
    'skin': [],
    'icon': '/characters/icons/ML/c2085_s.png',
    'skill': ["/characters/skill/sk_c2085_1.png",
              "/characters/skill/pa_c2085_2.png",
              "/characters/skill/sk_c2085_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2086',
    'name': 'Great Chief Khawana',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Taurus',
    'rarity': 4,
    'release': 2021,
    'image': ["/characters/new_png/ML/c2086.png"],
    'tagname': 'khawana',
    'skin': [],
    'icon': '/characters/icons/ML/c2086_s.png',
    'skill': ["/characters/skill/sk_c2086_1.png",
              "/characters/skill/pa_c2086_2.png",
              "/characters/skill/sk_c2086_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2087',
    'name': 'Peacemaker Furious',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Ranger',
    'horoscope': 'Scorpio',
    'rarity': 4,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2087.png"],
    'tagname': 'furious',
    'skin': [],
    'icon': '/characters/icons/ML/c2087_s.png',
    'skill': ["/characters/skill/sk_c2087_1.png",
              "/characters/skill/sk_c2087_2.png",
              "/characters/skill/sk_c2087_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2088',
    'name': 'Sylvan Sage Vivian',
    'gender': 'F',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Cancer',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/ML/c2088.png"],
    'tagname': 'vivian',
    'skin': [],
    'icon': '/characters/icons/ML/c2088_s.png',
    'skill': ["/characters/skill/sk_c2088_1.png",
              "/characters/skill/pa_c2088_2.png",
              "/characters/skill/sk_c2088_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2089',
    'name': 'Conqueror Lilias',
    'gender': 'F',
    'element': 'Light',
    'class': 'Warrior',
    'horoscope': 'Aquarius',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ML/c2089.png"],
    'tagname': 'lilias',
    'skin': [],
    'icon': '/characters/icons/ML/c2089_s.png',
    'skill': ["/characters/skill/sk_c2089_1.png",
              "/characters/skill/sk_c2089_2.png",
              "/characters/skill/sk_c2089_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2090',
    'name': 'Death Dealer Ray',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Soul Weaver',
    'horoscope': 'Cancer',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2090.png"],
    'tagname': 'ray',
    'skin': [],
    'icon': '/characters/icons/ML/c2090_s.png',
    'skill': ["/characters/skill/sk_c2090_1.png",
              "/characters/skill/sk_c2090_2.png",
              "/characters/skill/sk_c2090_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2091',
    'name': 'Astromancer Elena',
    'gender': 'F',
    'element': 'Light',
    'class': 'Ranger',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/ML/c2091.png"],
    'tagname': 'elena',
    'skin': [],
    'icon': '/characters/icons/ML/c2091_s.png',
    'skill': ["/characters/skill/sk_c2091_1.png",
              "/characters/skill/pa_c2091_2.png",
              "/characters/skill/sk_c2091_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2095',
    'name': 'Designer Lilibet',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Cancer',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ML/c2095.png"],
    'tagname': 'lilibet',
    'skin': [],
    'icon': '/characters/icons/ML/c2095_s.png',
    'skill': ["/characters/skill/sk_c2095_1.png",
              "/characters/skill/pa_c2095_2.png",
              "/characters/skill/sk_c2095_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2099',
    'name': 'Architect Laika',
    'gender': 'F',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2099.png"],
    'tagname': 'laika',
    'skin': [],
    'icon': '/characters/icons/ML/c2099_s.png',
    'skill': ["/characters/skill/sk_c2099_1.png",
              "/characters/skill/sk_c2099_2.png",
              "/characters/skill/sk_c2099_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2101',
    'name': 'Urban Shadow Choux',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2101.png"],
    'tagname': 'choux',
    'skin': [],
    'icon': '/characters/icons/ML/c2101_s.png',
    'skill': ["/characters/skill/sk_c2101_1.png",
              "/characters/skill/pa_c2101_2.png",
              "/characters/skill/sk_c2101_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2102',
    'name': 'Requiem Roana',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Aquarius',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2102.png"],
    'tagname': 'roana',
    'skin': [],
    'icon': '/characters/icons/ML/c2102_s.png',
    'skill': ["/characters/skill/sk_c2102_1.png",
              "/characters/skill/pa_c2102_2.png",
              "/characters/skill/sk_c2102_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2103',
    'name': 'Spirit Eye Celine',
    'gender': 'F',
    'element': 'Light',
    'class': 'Thief',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ML/c2103.png"],
    'tagname': 'celine',
    'skin': [],
    'icon': '/characters/icons/ML/c2103_s.png',
    'skill': ["/characters/skill/sk_c2103_1.png",
              "/characters/skill/pa_c2103_2.png",
              "/characters/skill/sk_c2103_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2106',
    'name': 'Dragon Bride Senya',
    'gender': 'F',
    'element': 'Light',
    'class': 'Knight',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ML/c2106.png"],
    'tagname': 'senya',
    'skin': [],
    'icon': '/characters/icons/ML/c2106_s.png',
    'skill': ["/characters/skill/sk_c2106_1.png",
              "/characters/skill/pa_c2106_2.png",
              "/characters/skill/sk_c2106_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2109',
    'name': 'Navy Captain Landy',
    'gender': 'F',
    'element': 'Light',
    'class': 'Knight',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ML/c2109.png"],
    'tagname': 'landy',
    'skin': [],
    'icon': '/characters/icons/ML/c2109_s.png',
    'skill': ["/characters/skill/sk_c2109_1.png",
              "/characters/skill/pa_c2109_2.png",
              "/characters/skill/sk_c2109_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2110',
    'name': 'Pirate Captain Flan',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/ML/c2110.png"],
    'tagname': 'flan',
    'skin': ['/characters/new_png/SKIN/c2110_s01.png'],
    'icon': '/characters/icons/ML/c2110_s.png',
    'skill': ["/characters/skill/sk_c2110_1.png",
              "/characters/skill/pa_c2110_2.png",
              "/characters/skill/sk_c2110_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2111',
    'name': 'Solitaria of the Snow',
    'gender': 'F',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ML/c2111.png"],
    'tagname': 'eda',
    'skin': [],
    'icon': '/characters/icons/ML/c2111_s.png',
    'skill': ["/characters/skill/sk_c2111_1.png",
              "/characters/skill/pa_c2111_2.png",
              "/characters/skill/sk_c2111_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2112',
    'name': 'Sea Phantom Politis',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Ranger',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ML/c2112.png"],
    'tagname': 'politis',
    'skin': [],
    'icon': '/characters/icons/ML/c2112_s.png',
    'skill': ["/characters/skill/sk_c2112_1.png",
              "/characters/skill/pa_c2112_2.png",
              "/characters/skill/sk_c2112_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2113',
    'name': 'Empyrean Ilynav',
    'gender': 'F',
    'element': 'Light',
    'class': 'Knight',
    'horoscope': 'Cancer',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ML/c2113.png"],
    'tagname': 'ilynav',
    'skin': [],
    'icon': '/characters/icons/ML/c2113_s.png',
    'skill': ["/characters/skill/sk_c2113_1.png",
              "/characters/skill/pa_c2113_2.png",
              "/characters/skill/sk_c2113_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c2126',
    'name': 'Hellion Lua',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Ranger',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ML/c2126.png"],
    'tagname': 'lua',
    'skin': [],
    'icon': '/characters/icons/ML/c2126_s.png',
    'skill': ["/characters/skill/sk_c2126_1.png",
                "/characters/skill/pa_c2126_2.png",
                "/characters/skill/sk_c2126_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight',
},
{ 
    'id': 'c2128',
    'name': 'Bystander Hwayoung',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ML/c2128.png"],
    'tagname': 'hwayoung',
    'skin': [],
    'icon': '/characters/icons/ML/c2128_s.png',
    'skill': ["/characters/skill/sk_c2128_1.png",
                "/characters/skill/pa_c2128_2.png",
                "/characters/skill/sk_c2128_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight',
},
{ 
    'id': 'c2132',
    'name': 'Dragon King Sharun',
    'gender': 'F',
    'element': 'Light',
    'class': 'Soul Weaver',
    'horoscope': 'Virgo',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ML/c2132.png"],
    'tagname': 'sharun',
    'skin': [],
    'icon': '/characters/icons/ML/c2132_s.png',
    'skill': ["/characters/skill/sk_c2132_1.png",
              "/characters/skill/pa_c2132_2.png",
              "/characters/skill/sk_c2132_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c3026',
    'name': 'Free Spirit Tieria',
    'gender': 'F',
    'element': 'Light',
    'class': 'Warrior',
    'horoscope': 'Virgo',
    'rarity': 4,
    'release': 2020,
    'image': ["/characters/new_png/ML/c3026.png"],
    'tagname': 'tieria',
    'skin': ["/characters/new_png/SKIN/c3026_s01.png"],
    'icon': '/characters/icons/ML/c3026_s.png',
    'skill': ["/characters/skill/sk_c3026_1.png",
              "/characters/skill/sk_c3026_2.png",
              "/characters/skill/sk_c3026_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
// { 
//     'id': 'c6003',
//     'name': 'Wretched Rose',
//     'gender': 'F',
//     'element': 'Dark',
//     'class': '',
//     'horoscope': '',
//     'rarity': 4,
//     'release': 2022,
//     'image': ["/characters/new_png/ML/c6003.png"],
//     'tagname': 'rose',
//     'skin': [],
//     'icon': '',
//     'limited': false,
//     'spec': false,
//     'collab': false,
//     'category': 'Moonlight'
// },
{ 
    'id': 'c6008',
    'name': 'Bad Cat Armin',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Aries',
    'rarity': 4,
    'release': 2022,
    'image': ["/characters/new_png/ML/c6008.png"],
    'tagname': 'armin',
    'skin': [],
    'icon': '/characters/icons/ML/c6008_s.png',
    'skill': ["/characters/skill/sk_c6008_1.png",
              "/characters/skill/pa_c6008_2.png",
              "/characters/skill/sk_c6008_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c6011',
    'name': 'Last Piece Karin',
    'gender': 'F',
    'element': 'Light',
    'class': 'Thief',
    'horoscope': 'Aries',
    'rarity': 4,
    'release': 2023,
    'image': ["/characters/new_png/ML/c6011.png"],
    'tagname': 'karin',
    'skin': [],
    'icon': '/characters/icons/ML/c6011_s.png',
    'skill': ["/characters/skill/sk_c6011_1.png",
              "/characters/skill/sk_c6011_2.png",
              "/characters/skill/sk_c6011_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c6014',
    'name': 'Wandering Prince Cidd',
    'gender': 'M',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Pisces',
    'rarity': 4,
    'release': 2024,
    'image': ["/characters/new_png/ML/c6014.png"],
    'tagname': 'cidd',
    'skin': [],
    'icon': '/characters/icons/ML/c6014_s.png',
    'skill': ["/characters/skill/sk_c6014_1.png",
              "/characters/skill/pa_c6014_2.png",
              "/characters/skill/sk_c6014_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c6017',
    'name': 'Infinite Horizon Achates',
    'gender': 'F',
    'element': 'Light',
    'class': 'Soul Weaver',
    'horoscope': 'Pisces',
    'rarity': 4,
    'release': 2023,
    'image': ["/characters/new_png/ML/c6017.png"],
    'tagname': 'achates',
    'skin': [],
    'icon': '/characters/icons/ML/c6017_s.png',
    'skill': ["/characters/skill/sk_c6017_1.png",
              "/characters/skill/sk_c6017_2.png",
              "/characters/skill/sk_c6017_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c6020',
    'name': 'Westwind Executioner Schuri',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 4,
    'release': 2024,
    'image': ["/characters/new_png/ML/c6020.png"],
    'tagname': 'schuri',
    'skin': [],
    'icon': '/characters/icons/ML/c6020_s.png',
    'skill': ["/characters/skill/sk_c6020_1.png",
                "/characters/skill/pa_c6020_2.png",
                "/characters/skill/sk_c6020_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c6037',
    'name': 'Moon Bunny Dominiel',
    'gender': 'F',
    'element': 'Light',
    'class': 'Soul Weaver',
    'horoscope': 'Taurus',
    'rarity': 4,
    'release': 2022,
    'image': ["/characters/new_png/ML/c6037.png"],
    'tagname': 'dominiel',
    'skin': [],
    'icon': '/characters/icons/ML/c6037_s.png',
    'skill': ["/characters/skill/sk_c6037_1.png",
              "/characters/skill/pa_c6037_2.png",
              "/characters/skill/sk_c6037_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
{ 
    'id': 'c6062',
    'name': 'Angel of Light Angelica',
    'gender': 'F',
    'element': 'Light',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 4,
    'release': 2021,
    'image': ["/characters/new_png/ML/c6062.png"],
    'tagname': 'angelica',
    'skin': [],
    'icon': '/characters/icons/ML/c6062_s.png',
    'skill': ["/characters/skill/sk_c6062_1.png",
              "/characters/skill/pa_c6062_2.png",
              "/characters/skill/sk_c6062_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Moonlight'
},
/********************************** ALT ****************************/
{ 
    'id': 'c1162',
    'name': 'Young Senya',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Soul Weaver',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ALT/c1162.png"],
    'tagname': 'senya',
    'skin': [],
    'icon': '/characters/icons/ALT/c1162_s.png',
    'skill': ["/characters/skill/sk_c1162_1.png",
                "/characters/skill/pa_c1162_2.png",
                "/characters/skill/sk_c1162_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Proof of Friendship": '/characters/artifact/art0208_fu.png'
    },
},
{ 
    'id': 'c5009',
    'name': 'Summer Break Charlotte',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Knight',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/ALT/c5009.png"],
    'tagname': 'charlotte',
    'skin': [],
    'icon': '/characters/icons/ALT/c5009_s.png',
    'skill': ["/characters/skill/sk_c5009_1.png",
              "/characters/skill/pa_c5009_2.png",
              "/characters/skill/sk_c5009_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Mature Sunglasses": '/characters/artifact/art0161_fu.png'
    },
},
{ 
    'id': 'c5016',
    'name': 'Holiday Yufine',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ALT/c5016.png"],
    'tagname': 'yufine',
    'skin': [],
    'icon': '/characters/icons/ALT/c5016_s.png',
    'skill': ["/characters/skill/sk_c5016_1.png",
              "/characters/skill/pa_c5016_2.png",
              "/characters/skill/sk_c5016_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Champion's Trophy": '/characters/artifact/art0098_fu.png'
    },
},
{ 
    'id': 'c5024',
    'name': 'Summertime Iseria',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ALT/c5024.png"],
    'tagname': 'iseria',
    'skin': [],
    'icon': '/characters/icons/ALT/c5024_s.png',
    'skill': ["/characters/skill/sk_c5024_1.png",
              "/characters/skill/pa_c5024_2.png",
              "/characters/skill/sk_c5024_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Star of the Deep Sea": '/characters/artifact/art0141_fu.png'
    },
},
{ 
    'id': 'c5046',
    'name': 'Blooming Lidica',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ALT/c5046.png"],
    'tagname': 'lidica',
    'skin': [],
    'icon': '/characters/icons/ALT/c5046_s.png',
    'skill': ["/characters/skill/sk_c5046_1.png",
              "/characters/skill/pa_c5046_2.png",
              "/characters/skill/sk_c5046_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Ritania',
    'artifact': {
        "Seductive Flower": '/characters/artifact/art0190_fu.png'
    },
},
{ 
    'id': 'c5050',
    'name': 'Fairytale Tenebria',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/ALT/c5050.png"],
    'tagname': 'tenebria',
    'skin': [],
    'icon': '/characters/icons/ALT/c5050_s.png',
    'skill': ["/characters/skill/sk_c5050_1.png",
              "/characters/skill/pa_c5050_2.png",
              "/characters/skill/sk_c5050_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Fairy Tale for a Nightmare": '/characters/artifact/art0123_fu.png'
    },
},
{ 
    'id': 'c5071',
    'name': 'Seaside Bellona',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/ALT/c5071.png"],
    'tagname': 'bellona',
    'skin': [],
    'icon': '/characters/icons/ALT/c5071_s.png',
    'skill': ["/characters/skill/sk_c5071_1.png",
              "/characters/skill/pa_c5071_2.png",
              "/characters/skill/sk_c5071_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Land of Death',
    'artifact': {
        "Reingar's Special Drink": '/characters/artifact/art0062_fu.png'
    },
},
{ 
    'id': 'c5082',
    'name': 'Ocean Breeze Luluca',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Soul Weaver',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ALT/c5082.png"],
    'tagname': 'luluca',
    'skin': [],
    'icon': '/characters/icons/ALT/c5082_s.png',
    'skill': ["/characters/skill/sk_c5082_1.png",
              "/characters/skill/pa_c5082_2.png",
              "/characters/skill/sk_c5082_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy',
    'artifact': {
        "Sole Consolation": '/characters/artifact/art0182_fu.png'
    },
},
{ 
    'id': 'c5089',
    'name': 'Midnight Gala Lilias',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ALT/c5089.png"],
    'tagname': 'lilias',
    'skin': [],
    'icon': '/characters/icons/ALT/c5089_s.png',
    'skill': ["/characters/skill/sk_c5089_1.png",
              "/characters/skill/pa_c5089_2.png",
              "/characters/skill/sk_c5089_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Cidonia',
    'artifact': {
        "Hostess of the Banquet": '/characters/artifact/art0186_fu.png'
    },
},
{ 
    'id': 'c5111',
    'name': 'Festive Eda',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Pisces',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/ALT/c5111.png"],
    'tagname': 'eda',
    'skin': [],
    'icon': '/characters/icons/ALT/c5111_s.png',
    'skill': ["/characters/skill/sk_c5111_1.png",
              "/characters/skill/pa_c5111_2.png",
              "/characters/skill/sk_c5111_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Eureka',
    'artifact': {
        "Magic Bubble Maker": '/characters/artifact/art0204_fu.png'
    },
},
{ 
    'id': 'c5149',
    'name': 'Lethe',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Warrior',
    'horoscope': 'Aquarius',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/ALT/c5149.png"],
    'tagname': 'lethe',
    'skin': [],
    'icon': '/characters/icons/ALT/c5149_s.png',
    'skill': ["/characters/skill/sk_c5149_1.png",
              "/characters/skill/pa_c5149_2.png",
              "/characters/skill/sk_c5149_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': false,
    'category': 'Foreign Land',
    'artifact': {
        "Prayer of Solitude": '/characters/artifact/art0183_fu.png'
    },
},
/********************************** SC *****************************/
// { 
//     'id': 'c0002',
//     'name': 'Mercedes {2021}',
//     'gender': 'F',
//     'element': 'Fire',
//     'class': 'Mage',
//     'horoscope': 'Scorpio',
//     'rarity': 4,
//     'release': 2021,
//     'image': ["/characters/new_png/RGB/c0002.png"],
//     'tagname': 'mercedes',
//     'skin': [],
//     'icon': '',
//     'limited': false,
//     'spec': true,
//     'collab': false,
//     'category': 'Ritania'
// },
{ 
    'id': 'c4001',
    'name': 'Chaos Inquisitor',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Knight',
    'horoscope': 'Leo',
    'rarity': 3,
    'release': 2019,
    'image': ["/characters/new_png/SC/c4001.png"],
    'tagname': 'butcher',
    'skin': [],
    'icon': '/characters/icons/SC/c4001_s.png',
    'skill': ["/characters/skill/sk_c4001_1.png",
              "/characters/skill/pa_c4001_2.png",
              "/characters/skill/sk_c4001_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4003',
    'name': 'Falconer Kluri',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Knight',
    'horoscope': 'Aries',
    'rarity': 3,
    'release': 2018,
    'image': ["/characters/new_png/SC/c4003.png"],
    'tagname': 'kluri',
    'skin': [],
    'icon': '/characters/icons/SC/c4003_s.png',
    'skill': ["/characters/skill/sk_c4003_1.png",
              "/characters/skill/pa_c4003_2.png",
              "/characters/skill/sk_c4003_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4004',
    'name': 'Unbound Knight Arowell',
    'gender': 'F',
    'element': 'Light',
    'class': 'Knight',
    'horoscope': 'Libra',
    'rarity': 3,
    'release': 2022,
    'image': ["/characters/new_png/SC/c4004.png"],
    'tagname': 'arowell',
    'skin': [],
    'icon': '/characters/icons/SC/c4004_s.png',
    'skill': ["/characters/skill/sk_c4004_1.png",
              "/characters/skill/pa_c4004_2.png",
              "/characters/skill/sk_c4004_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4005',
    'name': 'Shadow Knight Pyllis',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Knight',
    'horoscope': 'Cancer',
    'rarity': 3,
    'release': 2021,
    'image': ["/characters/new_png/SC/c4005.png"],
    'tagname': 'pyllis',
    'skin': [],
    'icon': '/characters/icons/SC/c4005_s.png',
    'skill': ["/characters/skill/sk_c4005_1.png",
              "/characters/skill/pa_c4005_2.png",
              "/characters/skill/sk_c4005_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4012',
    'name': "Summer's Disciple Alexa",
    'gender': 'F',
    'element': 'Ice',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 3,
    'release': 2022,
    'image': ["/characters/new_png/SC/c4012.png"],
    'tagname': 'alexa',
    'skin': [],
    'icon': '/characters/icons/SC/c4012_s.png',
    'skill': ["/characters/skill/sk_c4012_1.png",
              "/characters/skill/sk_c4012_2.png",
              "/characters/skill/sk_c4012_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4013',
    'name': 'Righteous Thief Roozid',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Gemini',
    'rarity': 3,
    'release': 2019,
    'image': ["/characters/new_png/SC/c4013.png"],
    'tagname': 'helga',
    'skin': [],
    'icon': '/characters/icons/SC/c4013_s.png',
    'skill': ["/characters/skill/sk_c4013_1.png",
              "/characters/skill/sk_c4013_2.png",
              "/characters/skill/sk_c4013_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4023',
    'name': 'Mercenary Helga',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Sagittarius',
    'rarity': 3,
    'release': 2020,
    'image': ["/characters/new_png/SC/c4023.png"],
    'tagname': 'helga',
    'skin': [],
    'icon': '/characters/icons/SC/c4023_s.png',
    'skill': ["/characters/skill/sk_c4023_1.png",
              "/characters/skill/sk_c4023_2.png",
              "/characters/skill/sk_c4023_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4025',
    'name': 'Chaos Sect Axe',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Leo',
    'rarity': 3,
    'release': 2018,
    'image': ["/characters/new_png/SC/c4025.png"],
    'tagname': 'axegod',
    'skin': [],
    'icon': '/characters/icons/SC/c4025_s.png',
    'skill': ["/characters/skill/sk_c4025_1.png",
              "/characters/skill/sk_c4025_2.png",
              "/characters/skill/sk_c4025_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4034',
    'name': 'Captain Rikoris',
    'gender': 'M',
    'element': 'Light',
    'class': 'Warrior',
    'horoscope': 'Libra',
    'rarity': 3,
    'release': 2019,
    'image': ["/characters/new_png/SC/c4034.png"],
    'tagname': 'rikoris',
    'skin': [],
    'icon': '/characters/icons/SC/c4034_s.png',
    'skill': ["/characters/skill/sk_c4034_1.png",
              "/characters/skill/sk_m0020_2.png",
              "/characters/skill/sk_c4034_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4035',
    'name': 'Commander Lorina',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Warrior',
    'horoscope': 'Leo',
    'rarity': 3,
    'release': 2018,
    'image': ["/characters/new_png/SC/c4035.png"],
    'tagname': 'lorina',
    'skin': [],
    'icon': '/characters/icons/SC/c4035_s.png',
    'skill': ["/characters/skill/sk_m0005_1.png",
              "/characters/skill/pa_c4035_2.png",
              "/characters/skill/sk_c4035_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4041',
    'name': 'Mascot Hazel',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Soul Weaver',
    'horoscope': 'Aquarius',
    'rarity': 3,
    'release': 2019,
    'image': ["/characters/new_png/SC/c4041.png"],
    'tagname': 'hazel',
    'skin': [],
    'icon': '/characters/icons/SC/c4041_s.png',
    'skill': ["/characters/skill/sk_c4041_1.png",
              "/characters/skill/sk_c4041_2.png",
              "/characters/skill/sk_c4041_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4042',
    'name': 'Angelic Montmorancy',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Soul Weaver',
    'horoscope': 'Pisces',
    'rarity': 3,
    'release': 2019,
    'image': ["/characters/new_png/SC/c4042.png"],
    'tagname': 'montmorancy',
    'skin': ["/characters/new_png/SKIN/c4042_s01.png"],
    'icon': '/characters/icons/SC/c4042_s.png',
    'skill': ["/characters/skill/sk_c4042_1.png",
              "/characters/skill/sk_c4042_2.png",
              "/characters/skill/sk_c4042_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4044',
    'name': 'Scholar Doris',
    'gender': 'F',
    'element': 'Light',
    'class': 'Soul Weaver',
    'horoscope': 'Cancer',
    'rarity': 3,
    'release': 2020,
    'image': ["/characters/new_png/SC/c4044.png"],
    'tagname': 'doris',
    'skin': [],
    'icon': '/characters/icons/SC/c4044_s.png',
    'skill': ["/characters/skill/sk_m0009_1.png",
              "/characters/skill/sk_m0027_2.png",
              "/characters/skill/sk_c4044_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4051',
    'name': 'Researcher Carrot',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Sagittarius',
    'rarity': 3,
    'release': 2020,
    'image': ["/characters/new_png/SC/c4051.png"],
    'tagname': 'carrot',
    'skin': [],
    'icon': '/characters/icons/SC/c4051_s.png',
    'skill': ["/characters/skill/sk_c4051_1.png",
              "/characters/skill/pa_c4051_2.png",
              "/characters/skill/sk_c4051_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4052',
    'name': 'Shepherd Jena',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Aries',
    'rarity': 3,
    'release': 2023,
    'image': ["/characters/new_png/SC/c4052.png"],
    'tagname': 'jena',
    'skin': [],
    'icon': '/characters/icons/SC/c4052_s.png',
    'skill': ["/characters/skill/sk_c4052_1.png",
              "/characters/skill/sk_c4052_2.png",
              "/characters/skill/sk_c4052_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4062',
    'name': 'Muse Rima',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Ranger',
    'horoscope': 'Aquarius',
    'rarity': 3,
    'release': 2021,
    'image': ["/characters/new_png/SC/c4062.png"],
    'tagname': 'rima',
    'skin': [],
    'icon': '/characters/icons/SC/c4062_s.png',
    'skill': ["/characters/skill/sk_c4062_1.png",
              "/characters/skill/sk_c4062_2.png",
              "/characters/skill/sk_c4062_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4065',
    'name': 'All-Rounder Wanda',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Ranger',
    'horoscope': 'Gemini',
    'rarity': 3,
    'release': 2019,
    'image': ["/characters/new_png/SC/c4065.png"],
    'tagname': 'wanda',
    'skin': [],
    'icon': '/characters/icons/SC/c4065_s.png',
    'skill': ["/characters/skill/sk_c4065_1.png",
              "/characters/skill/pa_c4065_2.png",
              "/characters/skill/sk_c4065_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4071',
    'name': 'Zealot Carmainerose',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Aquarius',
    'rarity': 3,
    'release': 2021,
    'image': ["/characters/new_png/SC/c4071.png"],
    'tagname': 'carmainerose',
    'skin': [],
    'icon': '/characters/icons/SC/c4071_s.png',
    'skill': ["/characters/skill/sk_c4071_1.png",
              "/characters/skill/sk_c4071_2.png",
              "/characters/skill/sk_c4071_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4073',
    'name': 'Doll Maker PearlHorizon',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 3,
    'release': 2020,
    'image': ["/characters/new_png/SC/c4073.png"],
    'tagname': 'pearlhorizon',
    'skin': [],
    'icon': '/characters/icons/SC/c4073_s.png',
    'skill': ["/characters/skill/sk_c4073_1.png",
              "/characters/skill/sk_c4073_2.png",
              "/characters/skill/sk_c4073_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4103',
    'name': 'Vigilante Leader Glenn',
    'gender': 'M',
    'element': 'Earth',
    'class': 'Ranger',
    'horoscope': 'Libra',
    'rarity': 3,
    'release': 2022,
    'image': ["/characters/new_png/SC/c4103.png"],
    'tagname': 'glenn',
    'skin': [],
    'icon': '/characters/icons/SC/c4103_s.png',
    'skill': ["/characters/skill/sk_c4103_1.png",
              "/characters/skill/sk_c4103_2.png",
              "/characters/skill/sk_c4103_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4105',
    'name': 'Ainos 2.0',
    'gender': 'F',
    'element': 'Dark',
    'class': 'Soul Weaver',
    'horoscope': 'Sagittarius',
    'rarity': 3,
    'release': 2023,
    'image': ["/characters/new_png/SC/c4105.png"],
    'tagname': 'ainos',
    'skin': [],
    'icon': '/characters/icons/SC/c4105_s.png',
    'skill': ["/characters/skill/sk_c4105_1.png",
              "/characters/skill/pa_c4105_2.png",
              "/characters/skill/sk_c4105_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4123',
    'name': 'Silvertide Christy',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Knight',
    'horoscope': 'Capricorn',
    'rarity': 3,
    'release': 2023,
    'image': ["/characters/new_png/SC/c4123.png"],
    'tagname': 'christy',
    'skin': [],
    'icon': '/characters/icons/SC/c4123_s.png',
    'skill': ["/characters/skill/sk_c4123_1.png",
              "/characters/skill/pa_c4123_2.png",
              "/characters/skill/sk_c4123_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4141',
    'name': 'Holy Flame Adin',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 3,
    'release': 2022,
    'image': ["/characters/new_png/SC/c4141.png"],
    'tagname': 'adin',
    'skin': [],
    'icon': '/characters/icons/SC/c4141_s.png',
    'skill': ["/characters/skill/sk_c4141_1.png",
              "/characters/skill/pa_c4141_2.png",
              "/characters/skill/sk_c4141_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4142',
    'name': 'Serene Purity Adin',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 3,
    'release': 2022,
    'image': ["/characters/new_png/SC/c4142.png"],
    'tagname': 'adin',
    'skin': [],
    'icon': '/characters/icons/SC/c4142_s.png',
    'skill': ["/characters/skill/sk_c4142_1.png",
              "/characters/skill/pa_c4142_2.png",
              "/characters/skill/sk_c4142_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4143',
    'name': 'Verdant Adin',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 3,
    'release': 2022,
    'image': ["/characters/new_png/SC/c4143.png"],
    'tagname': 'adin',
    'skin': [],
    'icon': '/characters/icons/SC/c4143_s.png',
    'skill': ["/characters/skill/sk_c4143_1.png",
              "/characters/skill/sk_c4143_2.png",
              "/characters/skill/sk_c4143_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4144',
    'name': 'Savior Adin',
    'gender': 'F',
    'element': 'Light',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 3,
    'release': 2022,
    'image': ["/characters/new_png/SC/c4144.png"],
    'tagname': 'adin',
    'skin': [],
    'icon': '/characters/icons/SC/c4144_s.png',
    'skill': ["/characters/skill/sk_c4144_1.png",
              "/characters/skill/pa_c4144_2.png",
              "/characters/skill/sk_c4144_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c4158',
    'name': 'Inheritor Amiki',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 3,
    'release': 2024,
    'image': ["/characters/new_png/SC/c4158.png"],
    'tagname': 'amiki',
    'skin': [],
    'icon': '/characters/icons/SC/c4158_s.png',
    'skill': ["/characters/skill/sk_c4158_1.png",
                "/characters/skill/sk_c4158_2.png",
                "/characters/skill/sk_c4158_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
{ 
    'id': 'c5001',
    'name': 'Adventurer Ras',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Knight',
    'horoscope': 'Libra',
    'rarity': 3,
    'release': 2020,
    'image': ["/characters/new_png/SC/c5001.png"],
    'tagname': 'ras',
    'skin': [],
    'icon': '/characters/icons/SC/c5001_s.png',
    'skill': ["/characters/skill/sk_c5001_1.png",
              "/characters/skill/sk_c5001_2.png",
              "/characters/skill/sk_c5001_3.png"
    ],
    'limited': false,
    'spec': true,
    'collab': false,
    'category': 'Specialty Change'
},
/******************************* COLLAB ****************************/
{ 
    'id': 'c1092',
    'name': 'Sol Badguy',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/COLLAB/c1092.png"],
    'tagname': 'sol',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1092_s.png',
    'skill': ["/characters/skill/sk_c1092_1.png",
              "/characters/skill/pa_c1092_2.png",
              "/characters/skill/sk_c1092_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Junkyard Dog": '/characters/artifact/art0052_fu.png'
    },
},
{ 
    'id': 'c1093',
    'name': 'Baiken',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Thief',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/COLLAB/c1093.png"],
    'tagname': 'baiken',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1093_s.png',
    'skill': ["/characters/skill/sk_c1093_1.png",
              "/characters/skill/sk_c1093_2.png",
              "/characters/skill/sk_c1093_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Torn Sleeve": '/characters/artifact/art0050_fu.png'
    },
},
{ 
    'id': 'c1094',
    'name': 'Dizzy',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2019,
    'image': ["/characters/new_png/COLLAB/c1094.png"],
    'tagname': 'dizzy',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1094_s.png',
    'skill': ["/characters/skill/sk_c1094_1.png",
              "/characters/skill/sk_c1094_2.png",
              "/characters/skill/sk_c1094_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Necro & Undine": '/characters/artifact/art0047_fu.png'
    },
},
{ 
    'id': 'c1105',
    'name': 'Elphelt',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Ranger',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2020,
    'image': ["/characters/new_png/COLLAB/c1105.png"],
    'tagname': 'elphelt',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1105_s.png',
    'skill': ["/characters/skill/sk_c1105_1.png",
              "/characters/skill/sk_c1105_2.png",
              "/characters/skill/sk_c1105_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Ms. Confille": '/characters/artifact/art0090_fu.png'
    },
},
{ 
    'id': 'c1107',
    'name': 'Kizuna Ai',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Soul Weaver',
    'horoscope': 'Cancer',
    'rarity': 4,
    'release': 2020,
    'image': ["/characters/new_png/COLLAB/c1107.png"],
    'tagname': 'kizuna ai',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1107_s.png',
    'skill': ["/characters/skill/sk_c1107_1.png",
              "/characters/skill/pa_c1107_2.png",
              "/characters/skill/sk_c1107_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration'
},
{ 
    'id': 'c1114',
    'name': 'Rem',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Warrior',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/COLLAB/c1114.png"],
    'tagname': 'rem',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1114_s.png',
    'skill': ["/characters/skill/sk_c1114_1.png",
              "/characters/skill/pa_c1114_2.png",
              "/characters/skill/sk_c1114_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Azure Comet": '/characters/artifact/art0131_fu.png'
    },
},
{ 
    'id': 'c1115',
    'name': 'Ram',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Mage',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/COLLAB/c1115.png"],
    'tagname': 'ram',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1115_s.png',
    'skill': ["/characters/skill/sk_c1115_1.png",
              "/characters/skill/pa_c1115_2.png",
              "/characters/skill/sk_c1115_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Severed Horn Wand": '/characters/artifact/art0132_fu.png'
    },
},
{ 
    'id': 'c1116',
    'name': 'Emilia',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Soul Weaver',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/COLLAB/c1116.png"],
    'tagname': 'emilia',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1116_s.png',
    'skill': ["/characters/skill/sk_c1116_1.png",
              "/characters/skill/sk_c1116_2.png",
              "/characters/skill/sk_c1116_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Guardian Ice Crystals": '/characters/artifact/art0134_fu.png'
    },
},
{ 
    'id': 'c1121',
    'name': 'Rimuru',
    'gender': '?',
    'element': 'Earth',
    'class': 'Warrior',
    'horoscope': 'Libra',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/COLLAB/c1121.png"],
    'tagname': 'rimuru',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1121_s.png',
    'skill': ["/characters/skill/sk_c1121_1.png",
              "/characters/skill/pa_c1121_2.png",
              "/characters/skill/sk_c1121_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Anti-Magic Mask": '/characters/artifact/art0139_fu.png'
    },
},
{ 
    'id': 'c1122',
    'name': 'Milim',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Cancer',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/COLLAB/c1122.png"],
    'tagname': 'milim',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1122_s.png',
    'skill': ["/characters/skill/sk_c1122_1.png",
              "/characters/skill/pa_c1122_2.png",
              "/characters/skill/sk_c1122_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Upgraded Dragon Knuckles": '/characters/artifact/art0138_fu.png'
    },
},
{ 
    'id': 'c1123',
    'name': 'Shuna',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Soul Weaver',
    'horoscope': 'Gemini',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/COLLAB/c1123.png"],
    'tagname': 'shuna',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1123_s.png',
    'skill': ["/characters/skill/sk_c1123_1.png",
              "/characters/skill/sk_c1123_2.png",
              "/characters/skill/sk_c1123_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Flawless Garments": '/characters/artifact/art0140_fu.png'
    },
},
{ 
    'id': 'c1130',
    'name': "Jack-O'",
    'gender': 'F',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/COLLAB/c1130.png"],
    'tagname': 'jack-o',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1130_s.png',
    'skill': ["/characters/skill/sk_c1130_1.png",
              "/characters/skill/pa_c1130_2.png",
              "/characters/skill/sk_c1130_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Jack-O's Symbol": '/characters/artifact/art0151_fu.png'
    },
},
{ 
    'id': 'c1134',
    'name': 'Edward Elric',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/COLLAB/c1134.png"],
    'tagname': 'edward',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1134_s.png',
    'skill': ["/characters/skill/sk_c1134_1.png",
              "/characters/skill/pa_c1134_2.png",
              "/characters/skill/sk_c1134_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Fullmetal's Automail": '/characters/artifact/art0157_fu.png'
    },
},
{ 
    'id': 'c1135',
    'name': 'Roy Mustang',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Mage',
    'horoscope': 'Leo',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/COLLAB/c1135.png"],
    'tagname': 'roy',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1135_s.png',
    'skill': ["/characters/skill/sk_c1135_1.png",
              "/characters/skill/pa_c1135_2.png",
              "/characters/skill/sk_c1135_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Ignition Cloth Gloves": '/characters/artifact/art0158_fu.png'
    },
},
{ 
    'id': 'c1136',
    'name': 'Riza Hawkeye',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Ranger',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/COLLAB/c1136.png"],
    'tagname': 'riza',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1136_s.png',
    'skill': ["/characters/skill/sk_c1136_1.png",
              "/characters/skill/pa_c1136_2.png",
              "/characters/skill/sk_c1136_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Sharpshooter's Handgun": '/characters/artifact/art0159_fu.png'
    },
},
{ 
    'id': 'c1137',
    'name': 'ae-KARINA',
    'gender': 'F',
    'element': 'Ice',
    'class': 'Knight',
    'horoscope': 'Aries',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/COLLAB/c1137.png"],
    'tagname': 'karina',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1137_s.png',
    'skill': ["/characters/skill/sk_c1137_1.png",
              "/characters/skill/pa_c1137_2.png",
              "/characters/skill/sk_c1137_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Rocket Punch Gaunlet": '/characters/artifact/art0170_fu.png'
    },
},
{ 
    'id': 'c1138',
    'name': 'ae-GISELLE',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Mage',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/COLLAB/c1138.png"],
    'tagname': 'giselle',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1138_s.png',
    'skill': ["/characters/skill/sk_c1138_1.png",
              "/characters/skill/pa_c1138_2.png",
              "/characters/skill/sk_c1138_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Frame of Light": '/characters/artifact/art0171_fu.png'
    },
},
{ 
    'id': 'c1139',
    'name': 'ae-WINTER',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Thief',
    'horoscope': 'Capricorn',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/COLLAB/c1139.png"],
    'tagname': 'winter',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1139_s.png',
    'skill': ["/characters/skill/sk_c1139_1.png",
              "/characters/skill/pa_c1139_2.png",
              "/characters/skill/sk_c1139_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "The Armament": '/characters/artifact/art0172_fu.png'
    },
},
{ 
    'id': 'c1140',
    'name': 'ae-NINGNING',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Soul Weaver',
    'horoscope': 'Scorpio',
    'rarity': 5,
    'release': 2022,
    'image': ["/characters/new_png/COLLAB/c1140.png"],
    'tagname': 'ningning',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1140_s.png',
    'skill': ["/characters/skill/sk_c1140_1.png",
              "/characters/skill/sk_c1140_2.png",
              "/characters/skill/sk_c1140_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "EXIF Detective (E.d.) Gadget": '/characters/artifact/art0173_fu.png'
    },
},
{ 
    'id': 'c1146',
    'name': 'Benimaru',
    'gender': 'M',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2023,
    'image': ["/characters/new_png/COLLAB/c1146.png"],
    'tagname': 'benimaru',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1146_s.png',
    'skill': ["/characters/skill/sk_c1146_1.png",
              "/characters/skill/pa_c1146_2.png",
              "/characters/skill/sk_c1146_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Benimaru's Tachi": '/characters/artifact/art0180_fu.png'
    },
},
{ 
    'id': 'c1155',
    'name': 'Ainz Ooal Gown',
    'gender': 'M',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Taurus',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/COLLAB/c1155.png"],
    'tagname': 'ainz',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1155_s.png',
    'skill': ["/characters/skill/sk_c1155_1.png",
              "/characters/skill/pa_c1155_2.png",
              "/characters/skill/sk_c1155_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Staff of Ainz Ooal Gown": '/characters/artifact/art0195_fu.png'
    },
},
{ 
    'id': 'c1156',
    'name': 'Albedo',
    'gender': 'F',
    'element': 'Earth',
    'class': 'Knight',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/COLLAB/c1156.png"],
    'tagname': 'albedo',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1156_s.png',
    'skill': ["/characters/skill/sk_c1156_1.png",
              "/characters/skill/pa_c1156_2.png",
              "/characters/skill/sk_c1156_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "3F": '/characters/artifact/art0193_fu.png'
    },
},
{ 
    'id': 'c1157',
    'name': 'Shalltear',
    'gender': 'F',
    'element': 'Fire',
    'class': 'Warrior',
    'horoscope': 'Sagittarius',
    'rarity': 5,
    'release': 2024,
    'image': ["/characters/new_png/COLLAB/c1157.png"],
    'tagname': 'shalltear',
    'skin': [],
    'icon': '/characters/icons/COLLAB/c1157_s.png',
    'skill': ["/characters/skill/sk_c1157_1.png",
              "/characters/skill/pa_c1157_2.png",
              "/characters/skill/sk_c1157_3.png"
    ],
    'limited': true,
    'spec': false,
    'collab': true,
    'category': 'Collaboration',
    'artifact': {
        "Pipette Lance": '/characters/artifact/art0194_fu.png'
    },
},
/***************************** Template ****************************/
{ 
    'id': 'm9194',
    'name': "Archdemon's Shadow",
    'gender': 'F',
    'element': 'Dark',
    'class': 'Mage',
    'horoscope': 'Aquarius',
    'rarity': 5,
    'release': 2021,
    'image': ["/characters/new_png/ML/m9194.png"],
    'tagname': 'mercedes',
    'skin': [],
    'icon': '/characters/icons/ML/m9194_s.png',
    'skill': ["/characters/skill/sk_m9194_1.png",
              "/characters/skill/pa_m9194_2.png",
              "/characters/skill/sk_m9194_3.png"
    ],
    'limited': false,
    'spec': false,
    'collab': false,
    'category': 'Star Genealogy'
},
// { 
//     'id': 'c1000',
//     'name': '',
//     'gender': '',
//     'element': '',
//     'class': '',
//     'horoscope': '',
//     'rarity': ,
//     'release': 2018,
//     'image': ["/characters/new_png/ALT/c1000.png"],
//     'tagname': '',
//     'skin': [],
//     'icon': '/characters/icons/ML/c1000_s.png',
//     'skill': ["/characters/skill/sk__1.png",
//                 "/characters/skill/pa__2.png",
//                 "/characters/skill/sk__3.png"
//     ],
//     'limited': false,
//     'spec': false,
//     'collab': false,
//     'category': 'Collaboration',
//     'artifact': {
//         "": '/characters/artifact/art_fu.png'
//     },
// },
]

const INITIAL_STATE = { //initial state based on api fetch
    isLoading: true,
    GetInventoryBefore: apiReturn,
    GetInventoryAfter: apiReturn,
    AppVersion: 'main'
}

export default function reducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case GET_INVENTORY:
            return {
                ...state,
            }
        case SET_LOADING:
            return {
                ...state,
                isLoading: false,
            }
    }
    return state;
}