import { Fragment, useEffect, useState } from "react";
import { Table } from "reactstrap";
import './css/guesseswritten.css';

const GuessesWritten = (props) => {
    const [guessArray, setGuessArray] = useState([]) // temp storage
    const [checkArray, setCheckArray] = useState([])
    // const [finish, setFinish] = useState(false)
    const checkList = ["name", "gender", "element", "class", "horoscope", "rarity", "release", "skin", "category"]
    const trueBoxClass = "classic-written-boxes classic-true-guess"
    const falseBoxClass = "classic-written-boxes classic-false-guess"
    const horoscopeCode = {
        'Aquarius': '♒',
        'Pisces': '♓',
        'Aries': '♈',
        'Taurus': '♉',
        'Gemini': '♊',
        'Cancer': '♋',
        'Leo': '♌',
        'Virgo': '♍',
        'Libra': '♎',
        'Scorpio': '♏',
        'Sagittarius': '♐',
        'Capricorn': '♑',
    }

    function createSolution(check, lastGuess) {
        const tbody = document.getElementById('writtenGuesses');
        const newRow = document.createElement('tr');
        {[...Array(9).keys()].map((i) => {
            var cell = document.createElement('td');
            if (i == 2 || i == 3) {
                cell.className = "classic-written-boxes classic-solution-guess classic-"+props.answer[checkList[i]].split(" ").pop()+"-guess"
            } else if (i == 5) {
                cell.className = "classic-written-boxes classic-solution-guess classic-star-guess"
            } else cell.className = "classic-written-boxes classic-solution-guess"

            if (i === 7) {
                cell.textContent = props.answer[checkList[i]].length? "Yes" : "No"
            } else if (i === 4) {
                cell.textContent = horoscopeCode[props.answer[checkList[i]]] + " " + props.answer[checkList[i]];
            } else {
                cell.textContent = props.answer[checkList[i]]
            } 
            newRow.appendChild(cell) 
        })}
        if (check !== null) {
            const newRow2 = 
            `<tr>
                <td class='${check[0]? trueBoxClass : falseBoxClass}'>${lastGuess[checkList[0]]}</td>
                <td class='${check[1]? trueBoxClass : falseBoxClass}'>${lastGuess[checkList[1]]}</td>
                <td class='classic-written-boxes classic-${check[2]}-guess classic-${lastGuess[checkList[2]]}-guess'>${lastGuess[checkList[2]]}</td>
                <td class='classic-written-boxes classic-${check[3]}-guess classic-${lastGuess[checkList[3]]}-guess'>${lastGuess[checkList[3]]}</td>
                <td class='${check[4]? trueBoxClass : falseBoxClass}'>${horoscopeCode[lastGuess[checkList[4]]]+" "+lastGuess[checkList[4]]}</td>
                <td class='classic-written-boxes classic-${check[5]}-guess classic-star-guess'>${lastGuess[checkList[5]]}</td>
                <td class='${"classic-written-boxes classic-"+check[6]+"-guess"}'>${lastGuess[checkList[6]]}</td>
                <td class='${check[7]? trueBoxClass : falseBoxClass}'>${lastGuess[checkList[7]].length? "Yes" : "No"}</td>
                <td class='${check[8]? trueBoxClass : falseBoxClass}'>${lastGuess[checkList[8]]}</td>
            </tr>`
            tbody.innerHTML = newRow2+tbody.innerHTML;
        }
        tbody.insertBefore(newRow, tbody.firstChild)
    }

    function tabulateGuess() {
        if (props.timecheck) {
            createSolution(null, props.guess)
            return
        }
        if (props.guess != null) {
            var tempArray = [...Array(9).keys()].map((i) => {
                var tempCheck = props.answer[checkList[i]] === props.guess[checkList[i]] ? true: false
                if (checkList[i] == "release" && !tempCheck) {
                    tempCheck = props.answer[checkList[i]] < props.guess[checkList[i]] ? "low" : "high"
                }
                if (checkList[i] == "skin") {
                    tempCheck = (props.answer[checkList[i]].length >=1) === (props.guess[checkList[i]].length >=1) ? true: false
                }
                return tempCheck
            })
            // check 
            if ( (tempArray.filter((i) => i ).length) == 9 ) {
                props.solve()
                // setFinish(true)
                // return
            } else if (props.lives === 0) {
                props.kill()
                // setFinish(true)
                createSolution(tempArray, props.guess)
                return
            }
            // full char info
            var guess = guessArray.slice()
            guess.unshift(props.guess)
            setGuessArray(guess)
            // array of true falses
            var check = checkArray.slice()
            check.unshift(tempArray)
            setCheckArray(check)
        }
    }

    useEffect(() => {
        if (props.reset) { //for unlimited only
            //reset states
            setGuessArray([])
            setCheckArray([])
        } else {
            tabulateGuess()
        }
    }, [props.guess, props.reset, props.timecheck])
    
    return (
        <Fragment>
            <Table>
                <thead> 
                    <tr> 
                        <th>Name</th>
                        <th>Gender</th>
                        <th>Element</th> 
                        <th>Class</th> 
                        <th>Horoscope</th> 
                        <th>Rarity</th> 
                        <th>Release</th> 
                        <th>Skin</th> 
                        <th>Category /<br/>Continent</th>
                    </tr> 
                </thead> 
                <tbody id="writtenGuesses">
                    {checkArray.map((check, idx) => {
                        return (
                            <tr>
                                <td className={check[0]? trueBoxClass : falseBoxClass}>{guessArray[idx][checkList[0]]}</td>
                                <td className={check[1]? trueBoxClass : falseBoxClass}>{guessArray[idx][checkList[1]]}</td>
                                <td className={"classic-written-boxes classic-"+check[2]+"-guess classic-"+guessArray[idx][checkList[2]]+"-guess"}>
                                    {guessArray[idx][checkList[2]]}
                                </td>
                                <td className={"classic-written-boxes classic-"+check[3]+"-guess classic-"+guessArray[idx][checkList[3]].split(" ").pop()+"-guess"}>
                                    {guessArray[idx][checkList[3]]}
                                </td>
                                <td className={check[4]? trueBoxClass : falseBoxClass}>{horoscopeCode[guessArray[idx][checkList[4]]]+" "+guessArray[idx][checkList[4]]}</td>
                                <td className={"classic-written-boxes classic-"+check[5]+"-guess classic-star-guess"}>{guessArray[idx][checkList[5]]}</td>
                                <td className={"classic-written-boxes classic-"+check[6]+"-guess"}>{guessArray[idx][checkList[6]]}</td>
                                <td className={check[7]? trueBoxClass : falseBoxClass}>{guessArray[idx][checkList[7]].length? "Yes" : "No"}</td>
                                <td className={check[8]? trueBoxClass : falseBoxClass}>{guessArray[idx][checkList[8]]}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </Fragment>
    )
}

export default GuessesWritten;