import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import HomeHeader from "../E7dle/Components/HomeHeader";
import MiniFooter from "../E7dle/Components/MiniFooter";
import './index.css'

const Changelog = () => {
    const [mcTimer, changeMCTimer] = useState(null)
    const run = true;

    const FutureImp = (props) => {
        // will probably change style in the future but leave it like this for now
        return (
            <div className="log-card above-background">
                <div className="log-title">Future Implementations (?)</div>
                <hr/>
                <div className="log-body">
                    {props.content}
                </div>
            </div>
        )
    }

    const Log = (props) => {
        return (
            <div className="log-card above-background">
                <div className="log-title">Ver. {props.ver} ({props.date})</div>
                <hr/>
                <div className="log-body">
                    {props.content}
                </div>
            </div>
        )
    }

    return (
        <Fragment>
        <div className="overlay">
        <div className="changelog">
            <div className="homepage-header container">
                <HomeHeader />
            </div>
            <h4 className="text-white above-background fs1 text-center">
                    Have an idea for this website? Leave a feedback <Link to="/feedback">here</Link></h4>
            <div className="above-background changelog-body">
                <FutureImp
                    content={
                        <ul>
                            <li>Higher Lower Mode</li>
                            <li>Relationship Mode</li>
                        </ul>
                    } 
                />
                <Log 
                    date="2024-12-19"
                    ver="1.0.0"
                    content={
                        <ul>
                            <li>Release of E7dle</li>
                            <li>Modes added</li>
                            <ul>
                                <li>Classic</li>
                                <li>Skill (beta)</li>
                                <li>Unlimited (beta)</li>
                                <li>Artifact (beta)</li>
                            </ul>
                            <li>Changes</li>
                            <ul>
                                <li></li>
                            </ul>
                            <li>New implementation</li>
                            <ul>
                                <li>Added Daily reset timer countdown</li>
                                <li>Updated list of characters up till Bystander Hwayoung</li>
                            </ul>
                            <li>Bugfixes</li>
                            <ul>
                                <li>Corrected Veronica 5* → 4* </li>
                                <li>Corrected A.Laika Ranger → Mage </li>
                                <li>Fixed Client-side version control</li>
                                <li>Fixed dropdown selector unable to submit previous guesses</li>
                                <li>Fixed dropdown selector submitting unsubmitted guess</li>
                            </ul>
                            <li>Others</li>
                            <ul>
                                <li>Some optimization for Mobile & PC Responsiveness</li>
                                <li>Improved global-stats UI</li>
                                <li>Improved menu UI</li>
                                <li>Added character icons</li>
                            </ul>
                        </ul>
                    }
                />
                <Log 
                    date="2024-09-21"
                    ver="0.1.0"
                    content={
                        <ul>
                            <li>Soft-release of E7dle for 1 week</li>
                            <ul>
                                <li>Classic mode (beta)</li>
                                <li>Skill mode (beta)</li>
                                <li>Unlimited mode (beta)</li>
                            </ul>
                            <li>Latest character additions</li>
                            <ul>
                                <li>Empyrean Ilynav [2024.06.20]</li>
                                <li>Frida and onwards not available yet</li>
                            </ul>
                            <li>Others</li>
                            <ul>
                                <li>Not yet optimized for Mobile Responsiveness</li>
                                <li>Some optimization for PC/Wide Screen Responsiveness</li>
                            </ul>
                        </ul>
                    }
                />
            </div>
            <MiniFooter/>
        </div>
        </div>
        </Fragment>
    )
}

export default Changelog;