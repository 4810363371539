import { Fragment, useState, useEffect } from "react";
import './css/tutorial.css';

const Tutorial = (props) => {
    // const [modalOpened, setModalOpened] = useState(false)

    // const openModal = () => {
    //     setModalOpened(!modalOpened)
    // }

    // useEffect(() => {
    // }, [props.openInst])


    return (
        <Fragment>
            <div id="simpleModal" style={props.inst === true? {display: "block"} : {display: "none"}}>
                <div id="modal-content">
                    <div className="container pt-1 pb-5">

                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 bg-dark px-5 rounded z-depth-5" id="whole_box">
                                <span id="closeBtn" onClick={props.open}>&times;</span>
                                <div className="row">
                                    <div className="card-header bg-dark mt-4" id="unlimited-settings">
                                        <h2 className="text-white">How to play?</h2>
                                        <div className="col-sm-12 border border-bottom border-warning"></div>
                                        <br/>
                                    </div>
                                </div>
                                {/* GENERIC */}
                                <div className="row text-white mb-3" style={
                                                                    props.page !== "artifact-2" && props.page !== "skill"? 
                                {display: "block"}:{display: "none"}}>
                                    <text>To guess the character, thereby referred to as 'A'.</text>
                                </div>
                                {/* CLASSIC */}
                                <div className="row text-white mt-3 mb-3" style={props.page === "classic"? {display: "block"}:{display: "none"}}>
                                    <span className="red_span border border-2 border-white mr-1">&nbsp;&nbsp;</span>
                                    <text><b>Red</b>: The character you guessed does not have the trait.</text>
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "classic"? {display: "block"}:{display: "none"}}>
                                    <span className="green_span border border-2 border-white mr-1">&nbsp;&nbsp;</span>
                                    <text><b>Green</b>: The character you guessed shares the same trait as the A.</text>
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "classic"? {display: "block"}:{display: "none"}}>
                                    <text>Traits: <br/>
                                        <b> Name</b>, <b>Gender</b> = (M / F), <b>Element</b> = (Fire / Ice / Earth / Dark / Light), <br/>
                                        <b> Class</b> = (Knight / Soul Weaver / Mage / Thief / Warrior / Ranger), <br/>
                                        <b> Horoscope</b> = (Aquarius / Pisces / Aries / Taurus / Gemini / Cancer /
                                        Leo / Virgo / Libra / Scorpio / Sagittarius / Capricorn), <br/>
                                        <b> Rarity</b> = (3 / 4 / 5), <br/>
                                        <b> Release</b> = ⇊ A is released earlier than your guess, ⇈ A is released later than your guess,  <br/>
                                        <b> Skin</b> = (Yes / No), <br/>
                                        <b> Category/Continent</b> = 
                                        (Star Genealogy / Ritania / Land of Death / Cidonia / Eureka / Natalon / Erasia / 
                                        Foreign Land / Moonlight / Specialty Change / Collaboration)
                                    </text>
                                </div>
                                {/* SKILL ICON */}
                                <div className="row text-white mt-3 mb-3" style={props.page === "skill"? {display: "block"}:{display: "none"}}>
                                   There are 3 skill icons. They each belong to a character. You need to guess each character and which skill number it is. 
                                   Each skill presented can be either 1, 2 or 3. There is no rule they can't all be 1, 2 or 3.
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "skill"? {display: "block"}:{display: "none"}}>
                                    <text>
                                        To give your final answer, select an icon, choose the skill number and type the character's name.<br/>
                                        If you guess the skill number wrongly, the game will be over instantly.<br/>
                                    </text>
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "skill"? {display: "block"}:{display: "none"}}>
                                    <text>
                                        However, you can guess a character without selecting any icons, and it will tell you if any of the 3 skill icons belong to them.
                                    </text>
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "skill"? {display: "block"}:{display: "none"}}>
                                    <span className="red_span border border-2 border-white mr-1">&nbsp;&nbsp;</span>
                                    <text><b>Red</b>: None of the skill icons belong to character you guessed.</text>
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "skill"? {display: "block"}:{display: "none"}}>
                                    <span className="green_span border border-2 border-white mr-1">&nbsp;&nbsp;</span>
                                    <text><b>Green</b>: One of the skill icons belongs to character you guessed.</text>
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "skill"? {display: "block"}:{display: "none"}}>
                                    <text>
                                        There is no limit to guessing characters this way, but you cannot win unless you guess individually.
                                    </text>
                                </div>
                                {/* PREFIX */}
                                {/* <div className="row text-white mt-3 mb-3" style={props.page === "prefix"? {display: "block"}:{display: "none"}}>
                                    You are to guess the full name of the characters, e.g. instead of Specialty-Changed Ras or ML Mercedes, 
                                    you are supposed to guess Adventuer Ras or Archdemon's Shadow. <br/>
                                    It plays similarly to Hangman, if you've played it before.
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "prefix"? {display: "block"}:{display: "none"}}>
                                    <span className="green_span border border-2 border-white mr-1">&nbsp;&nbsp;</span>
                                    <text><b>Green</b>: The character's name has this letter.</text>
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "prefix"? {display: "block"}:{display: "none"}}>
                                    <span className="red_span border border-2 border-white mr-1">&nbsp;&nbsp;</span>
                                    <text><b>Red</b>: The character's name does not have this letter.</text>
                                </div> */}
                                {/* ARTIFACT */}
                                <div className="row text-white mt-3 mb-3" style={props.page === "artifact-1"? {display: "block"}:{display: "none"}}>
                                    An artifact will revealed bit by bit, and you are to guess the character whose the artifact belongs to.
                                    E.g. Alencia's artifact, on her Story Summon banner, and / or associated to her, is Alencinox's Wrath.
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "artifact-1"? {display: "block"}:{display: "none"}}>
                                    The pool consists of 
                                    <ul>
                                        <li>All RGB 5* Summonable Characters</li>
                                        <li>All 5* Collaboration Characters, including Ainz (ML5) </li>
                                    </ul>
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "artifact-2"? {display: "block"}:{display: "none"}}>
                                    Now the Artifact is displayed to you fully. You are to guess the name of the artifact, letter by letter.
                                    It plays similarly to Hangman, if you've played it before.
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "artifact-2"? {display: "block"}:{display: "none"}}>
                                    <span className="green_span border border-2 border-white mr-1">&nbsp;&nbsp;</span>
                                    <text><b>Green</b>: The Artifact name has this letter.</text>
                                </div>
                                <div className="row text-white mt-3 mb-3" style={props.page === "artifact-2"? {display: "block"}:{display: "none"}}>
                                    <span className="red_span border border-2 border-white mr-1">&nbsp;&nbsp;</span>
                                    <text><b>Red</b>: The Artifact name does not have this letter.</text>
                                </div>
                                {/* GENERIC */}
                                <div className="row text-white mt-3 mb-3">
                                    <text>If applicable, eg. Classic/Skill, 3* Characters only include the Specialty-Changed versions.</text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </div>
        </Fragment>
    )
}

export default Tutorial;