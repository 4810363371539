import { Fragment, useEffect, useState } from "react";
import './css/dailystats.css'

const DailyStats = (props) => {
    const [totalCount, setTotalCount] = useState(0)
    const [averageGuess, setAverageGuess] = useState(0)

    // change the url in classic to actual server url

    useEffect(() => {
        try {
            fetch(props.api)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return res.json();
                }).then(data => {
                    //people guessed
                    // var trueData = data.filter((entry) => entry.guessed);
                    setTotalCount(data.totalPlays);
                    
                    //average guess
                    data.avgGuessedData?
                        setAverageGuess(data.avgGuessedData)
                        :
                        setAverageGuess(0);

                }).catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        } catch(err) {
            console.log(err)
        }
    }, [])

    return (
        <Fragment>
            <div className="classic-stats above-background">
            {totalCount} people already guessed
            /
            {" "+averageGuess.toFixed(2)+" "} average guesses
            </div>
        </Fragment>
    )
}

export default DailyStats;