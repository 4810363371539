import {BrowserRouter as Router, Route, Navigate, Routes} from 'react-router-dom';
import React, {Suspense, Fragment} from "react";
import { connect } from 'react-redux';
//import components
import { ToastContainer } from 'react-toastify';
import Sorter from '../../Pages/Sorter';
import Footer from '../../Components/Footer';
import Feedback from '../../Pages/Feedback';
import E7dle from '../../Pages/E7dle';
import Classic from '../../Pages/Classic';
import Unlimited from '../../Pages/Unlimited';
import GuessSkill from '../../Pages/GuessSkill';
import Artifact from '../../Pages/Artifact';
import Changelog from '../../Pages/Changelog';
import NotFound from '../../Pages/NotFound';

const AppMain = (props) => {
    return (
        <Fragment>
            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <h6 className="mt-3">
                            Please wait while we load all the Vehicles
                        </h6>
                    </div>
                </div>
            }>
                <Routes>
                    <Route strict path="/Home" element={<E7dle/>}/>
                    <Route strict path="/sorter" element={<Sorter/>}/>
                    <Route strict path="/classic" element={<Classic/>}/>
                    <Route strict path="/guessskill" element={<GuessSkill/>}/>
                    <Route strict path="/unlimited" element={<Unlimited/>}/>
                    <Route strict path="/artifact" element={<Artifact/>}/>
                    <Route strict path="/changelog" element={<Changelog/>}/>
                    <Route strict path="/feedback" element={<Feedback/>}/>
                    {/* Redirect first entry to sorter */}
                    <Route path="/" element={<Navigate to="/Home"/>}  />
                    {/* Not Found redirect to page */}
                    <Route path="*" element={<NotFound/>} />
                </Routes>
            </Suspense>
            <ToastContainer/>
            <Footer/>
        </Fragment>
    )
}
// redux store
const mapStateToProp = state => ({
    AppVersion: state.ProxyData.AppVersion,
})
export default connect(mapStateToProp)(AppMain);