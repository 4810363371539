// src/components/PieChart.js
import React from "react";
import { Pie } from "react-chartjs-2";

function PieChart({ chartData }) {
  return (
    <div className="chart-container">
      {/* <h2 style={{ textAlign: "center" }}>Pie Chart</h2> */}
      <Pie
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Most common guesses",
              color: "white"
            },
            legend: {
                labels: {
                    color: "white"
                }
            }
          }
        }}
        style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            height: "17em",
            width: "17em"
        }}
      />
    </div>
  );
}
export default PieChart;