import { Fragment, useEffect, useState } from "react";
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import { connect } from "react-redux";
//components
import { CardText, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
// import { setInventory, submitInventory } from "../../reducers/proxyData";
import HomeHeader from "../E7dle/Components/HomeHeader";
import MiniFooter from "../E7dle/Components/MiniFooter";
import Menu from "./Components/Menu";
import Tutorial from "./Components/Tutorial";
import Update from "./Components/Update";
import ProgressBar from "./Components/ProgressBar";
//css
import './Components/css/design.css';

const Sorter = (props) => {
    const [localDatabase] = useState(props.GetInventory)
    //Connecting to database / API
    const host = "https://epic7-sorter.herokuapp.com/"
    // const host = "http://127.0.0.1:5000/"
    const [finishList, setFinishList] = useState([])


    /** Run a function that compiles all the stars */
    // Specific Filters
    var collabChar = [...localDatabase.filter(char => char.collab == true)]
    var skinsChar = [...localDatabase.filter(char => char.skin.length >= 1)]
    var specialtyChar = [...localDatabase.filter(char => char.spec == true)]
    // General Filters
    var filterChar = [...collabChar, ...specialtyChar] //...skinsChar | bug: excludes units with skin
    var generalChar = [...localDatabase.filter(char => !filterChar.includes(char))]
    // Final Filters
    // var threestarsChar = [...generalChar.filter(char => char.rarity == 3).map((i)=> {return [i.image[0], i.name]})]
    var fourstarsChar = [...generalChar.filter(char => char.rarity == 4).map((i)=> {return [i.image[0], i.name]})]
    var fivestarsChar = [...generalChar.filter(char => char.rarity == 5).map((i)=> {return [i.image[0], i.name]})]
    //
    collabChar = [...collabChar.map((i)=> {return [i.image[0], i.name]})]
    skinsChar = [...skinsChar.map((i)=> {return [i.skin[0], i.name]})]
    specialtyChar = [...specialtyChar.map((i)=> {return [i.image[0], i.name]})]
    // currently overlapped ras and merc spec change


    /** States */
    const [historyCount, setHistoryCount] = useState(0)

    const [numQuestion, setNumQuestion] = useState(1)
    const [currentPercent, setCurrentPercent] = useState(0)
    const [totalPercent, setTotalPercent] = useState(1)
    const [finishFlag, setFinishFlag] = useState(0)

    const [poolThree, setPoolThree] = useState(false)
    const [poolFour, setPoolFour] = useState(false)
    const [poolFive, setPoolFive] = useState(false)
    const [poolCollab, setPoolCollab] = useState(false)
    const [poolSkins, setPoolSkins] = useState(false)
    const [poolSpecialty, setPoolSpecialty] = useState(false)
    
    //create function for iterate, append/push character
    //Instructions: This is where names are inserted
    function addCharacters(batch, array) {
        var i;
        for (i= 0; i < batch.length; i++) {
            array.push(batch[i])
        }
        return array
    }
    
    //The initialization of the variable+++++++++++++++++++++++++++++++++++++++++++++
    
    function initList(list){
        var totalPercent = 0
        var currentPercent = 0
        var totalEntries = list.length

        //localstorage
        var tempSortedArray = JSON.parse(localStorage['sortedArray'])
        var tempHistoryDict = JSON.parse(localStorage['historyDict'])
        var tempHistoryCount = parseInt(localStorage['historyCount'])

        //rounds == complete permutation, each dict key == 1 permutation
        //add all list values into arrayDict
        for (var i=0; i<totalEntries; i++) {
            tempSortedArray.push([list[i]])
        }
        //total percent calculation
        // var totalRounds = 3 + finalCap || Round > Permutation > Battle
        var totalBattles =  0
        var z = Array(totalEntries).fill(1)
        while (z.length > 1) {
            var o = z.splice(0,2)
            o = o[0] + o[1]
            if (o <= 10) {
                totalBattles += o - 1
            } else totalBattles += 10
            z.push(o)
        }
        totalPercent = totalBattles
        //0th step in history
        tempHistoryDict[historyCount] = tempSortedArray.slice()
        //first step in history
        tempHistoryCount += 1
        const leftArray = tempSortedArray.shift()
        const rightArray = tempSortedArray.shift()

        tempHistoryDict[tempHistoryCount] = {
        'leftArray': leftArray.slice(),
        'rightArray': rightArray.slice(),
        'newArray': [],
        'currentPercent': currentPercent,
        'totalPercent': totalPercent
        }
        localStorage['historyCount'] = tempHistoryCount
        localStorage['historyDict'] = JSON.stringify(tempHistoryDict)
        localStorage['sortedArray'] = JSON.stringify(tempSortedArray)
        setChar1(toNameFace(tempHistoryDict[tempHistoryCount], 0)[0])
        setChar2(toNameFace(tempHistoryDict[tempHistoryCount], 1)[0])
        setChar1Name(toNameFace(tempHistoryDict[tempHistoryCount], 0)[1])
        setChar2Name(toNameFace(tempHistoryDict[tempHistoryCount], 1)[1])
    }

    //animation on click
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    async function selectChoice(e) {
        //LEFT -1, RIGHT 1, PASS 0, UNDO 9
        var choice;
        switch (e.target.id) {
            case 'leftField':
                choice = -1
                break;
            case 'rightField':
                choice = 1
                break;
            case 'middleField':
                choice = 9
                break;
        }
        //not tie or undo?
        if (choice !== 9) {

            var currentStep = JSON.parse(localStorage['historyDict'])[localStorage['historyCount']]
            var currentPercent = currentStep.currentPercent
            var totalPercent = currentStep.totalPercent

            var permutationDone = sortList(currentStep['leftArray'].slice(), currentStep['rightArray'].slice(), currentStep['newArray'].slice(), currentPercent, totalPercent, choice)

            if (permutationDone) {
                var tempSortedArray = JSON.parse(localStorage['sortedArray']) // when go back not correct
                var tempHistoryDict = JSON.parse(localStorage['historyDict'])
                var tempHistoryCount = parseInt(localStorage['historyCount'])
                //full newArray, append to sortedArray and push sortedArray into historyDict
                currentStep = tempHistoryDict[tempHistoryCount]
                tempSortedArray.push(currentStep['newArray']) // happen twice bug? // will it break if I remove this and run normally? yes
                tempHistoryCount += 1
                tempHistoryDict[tempHistoryCount] = tempSortedArray //here still havent remove first 2 indexes
                //get new left and right arrays and then create another entry in historyDict for next left/right permutation
                if (tempSortedArray.length > 1) {
                    tempHistoryCount += 1
                    // grab groups from sortedArray to start next permutation
                    var leftArray = tempSortedArray.shift()
                    var rightArray = tempSortedArray.shift()
                    currentPercent = currentStep.currentPercent //this is the part to add discarded %
                    totalPercent = currentStep.totalPercent
                    tempHistoryDict[tempHistoryCount] = {
                        'leftArray': leftArray.slice(),
                        'rightArray': rightArray.slice(),
                        'newArray': [],
                        'currentPercent': currentPercent,
                        'totalPercent': totalPercent 
                    }
                }
                localStorage['historyCount'] = tempHistoryCount
                localStorage['historyDict'] = JSON.stringify(tempHistoryDict)
                localStorage['sortedArray'] = JSON.stringify(tempSortedArray)
                
            } else {
                //perm not done, save into storage
                // var tempSortedArray = JSON.parse(localStorage['sortedArray'])
                // var tempHistoryDict = JSON.parse(localStorage['historyDict'])
                // var tempHistoryCount = parseInt(localStorage['historyCount'])
            }
            setNumQuestion(numQuestion+1)
            var currentStep = JSON.parse(localStorage['historyDict'])[localStorage['historyCount']]
            var currentPercent = currentStep.currentPercent
            var totalPercent = currentStep.totalPercent
            setCurrentPercent(currentPercent)
            setTotalPercent(totalPercent)

        } else if (choice === 9) { // undo || maybe add somewhere to remove future entries that are undone
            
            var tempSortedArray = JSON.parse(localStorage['sortedArray'])
            var tempHistoryDict = JSON.parse(localStorage['historyDict'])
            var tempHistoryCount = parseInt(localStorage['historyCount'])
            var tempNumQn = numQuestion
            const salvageCount = tempHistoryCount
            // backtrack to a previous state in the dictionary, cannot go past the initial stages (historyCount == 2)
            if (tempHistoryCount > 2) { // >2 shd be fine?
                tempNumQn -= 1
                tempHistoryCount -= 1; //move to previous
                if (tempHistoryDict[tempHistoryCount].length !== undefined) { 
                    //not dictionary or is sortedArray. Also always comes after one empty side array
                    //if either one side of the array is blank, which is the final decision, we have to revert that.
                    //current -1 -> array -1 -> empty -> last decision
                    tempHistoryCount -= 2
                    //also need to readd the first two shifted out, take it from the latest historyDict, insert rightArray back first
                    tempSortedArray.splice(0, 0, tempHistoryDict[salvageCount].rightArray)
                    tempSortedArray.splice(0, 0, tempHistoryDict[salvageCount].leftArray)
                    //also need to remove the new addition in the sorted array
                    tempSortedArray.splice(-1, 1)
                } else { //if last decision was not the last in permutation, just a normal one
                    //do nothing
                }
                //remove future array to keeps things less messy for debug, put in here cause only when valid undo then affect else err <2 history
                tempHistoryDict = Object.fromEntries(Object.entries(tempHistoryDict).slice(0,tempHistoryCount+1))
            } else {
                alert("This is the most beginning already.")
            }
            setNumQuestion(tempNumQn)
            localStorage['historyCount'] = tempHistoryCount
            localStorage['historyDict'] = JSON.stringify(tempHistoryDict)
            localStorage['sortedArray'] = JSON.stringify(tempSortedArray) //why didnt fix to prevent dup
            var currentStep = JSON.parse(localStorage['historyDict'])[localStorage['historyCount']]
            var currentPercent = currentStep.currentPercent
            var totalPercent = currentStep.totalPercent
            setCurrentPercent(currentPercent)
            setTotalPercent(totalPercent)
        }

        // check if statement || would not err out if additional group was not added
        if (JSON.parse(localStorage['historyDict'])[localStorage['historyCount']].length === 1 && JSON.parse(localStorage['historyDict'])[localStorage['historyCount']].length !== undefined) {
            //there are 2 times sortedArray is length 1 towards the end, so there needs to be another requirement
            //other req: sortedArray[0].length === list.length but can't use if there are passes/draws
            //undefined is to check that it doesnt end when on in-between stages where historyDict instead of sortedArray is used
            console.log("finished.")
            setFinishFlag(1)
            showResult();
        } else {
            var tempHistoryDict = JSON.parse(localStorage['historyDict'])
            var tempHistoryCount = parseInt(localStorage['historyCount'])
            
            //does card change animation
            if (char1Name !== toNameFace(tempHistoryDict[tempHistoryCount], 0)[1] && char2Name === toNameFace(tempHistoryDict[tempHistoryCount], 1)[1]) {
                document.querySelector('#leftField .card').style.animation = 'destroyed 0.5s'
                await sleep(400); //sleep works, so have time for animation
                document.querySelector('#leftField .card').style.animation = 'replaced 0.5s'
            } else if (char1Name === toNameFace(tempHistoryDict[tempHistoryCount], 0)[1] && char2Name !== toNameFace(tempHistoryDict[tempHistoryCount], 1)[1]) {
                document.querySelector('#rightField .card').style.animation = 'destroyed 0.5s'
                await sleep(400); //sleep works, so have time for animation
                document.querySelector('#rightField .card').style.animation = 'replaced 0.5s'
            } else {
                document.querySelector('#leftField .card').style.animation = 'destroyed 0.5s'
                document.querySelector('#rightField .card').style.animation = 'destroyed 0.5s'
                await sleep(400); //sleep works, so have time for animation
                document.querySelector('#leftField .card').style.animation = 'replaced 0.5s'
                document.querySelector('#rightField .card').style.animation = 'replaced 0.5s'
            }
            
            //and then it changes image
            setChar1(toNameFace(tempHistoryDict[tempHistoryCount], 0)[0])
            setChar2(toNameFace(tempHistoryDict[tempHistoryCount], 1)[0])
            setChar1Name(toNameFace(tempHistoryDict[tempHistoryCount], 0)[1])
            setChar2Name(toNameFace(tempHistoryDict[tempHistoryCount], 1)[1])
            console.log("next battle...")
        }

    }
    
    function sortList(leftArray, rightArray, newArray, currentPercent, totalPercent, choice) {
        var prune = false
        // LEFT
        if (choice === -1) {
            var winner = leftArray.shift()
            newArray.push(winner)
            currentPercent += 1
            if (leftArray.length === 0) { //might need to consider shifting out all the elements when readded
                var leftoverPercent = Math.min((10 - newArray.length), rightArray.length - 1)
                rightArray.map((item) => {
                    newArray.push(item)
                })
                currentPercent += leftoverPercent // only make up for missing newarray
            }
        }
        // RIGHT
        if (choice === 1) {
            var winner = rightArray.shift()
            newArray.push(winner)
            currentPercent += 1
            if (rightArray.length === 0) { //might need to consider shifting out all the elements when readded
                var leftoverPercent = Math.min((10 - newArray.length), leftArray.length - 1)
                leftArray.map((item) => {
                    newArray.push(item)
                })
                currentPercent += leftoverPercent // only make up for missing newarray
            }
        }

        // PASS not implemented

        // PRUNE
        if (newArray.length >= 10) { //prune away anything >= 10 | add currentPercent
            localStorage['historyCount'] = parseInt(localStorage['historyCount']) + 1
            var historyDict = JSON.parse(localStorage['historyDict'])
            historyDict[localStorage['historyCount']] = {
                'leftArray': leftArray.slice(),
                'rightArray': rightArray.slice(),
                'newArray': newArray.slice(0,10), //because array here is not slice of topten, it dies after return?
                'currentPercent': currentPercent,
                'totalPercent': totalPercent 
            }
            localStorage['historyDict'] = JSON.stringify(historyDict)
            prune = true
            // discardedDict[historyCount] = {
            //     'discard': afterten
            // }

        } else { //if arrays still <= 10, the 'normal' procedure
            localStorage['historyCount'] = parseInt(localStorage['historyCount']) + 1
            var historyDict = JSON.parse(localStorage['historyDict'])
            historyDict[localStorage['historyCount']] = {
                'leftArray': leftArray.slice(),
                'rightArray': rightArray.slice(),
                'newArray': newArray.slice(),
                'currentPercent': currentPercent,
                'totalPercent': totalPercent 
            }
            localStorage['historyDict'] = JSON.stringify(historyDict)
        }

        // permutationDone
        if (leftArray.length === 0 || rightArray.length === 0 || prune) {
            return true
        } else {
            return false
        }
        
    }
    
    
    //The results+++++++++++++++++++++++++++++++++++++++++++++++
    
    function showResult() {
        // //PERCENTAGE: got the formula down, but the issue is the % is based on the iterations but since iterations auto add when one side of the array is emptied, the
        // //  % will jump quite drastically the closer it comes to the end. What it should be based on is the number of clicks until it is completely done, which is
        // // the unpredictable part. Perhaps instead of auto adding during iterations, the total percent should be decreased to match the clicks instead.

        var transferList = JSON.parse(localStorage['sortedArray'])
        setFinishList(...transferList)
        document.getElementById('mainTable').style.display = 'none';

        //top three
        var first, second, third;
        transferList[0].slice(0,3).map((character, rank) => {
            switch(rank) {
                case 0:
                    first = character[1]
                    break;
                case 1:
                    second = character[1]
                    break;
                case 2:
                    third = character[1]
                    break;
                default:
                    //do nothing
                    break;
            }
        })

        //call api to insert into database
        var ign_value;
        if (document.getElementById('ign').value == "") {
            ign_value = 'NULL'
        } else {
            ign_value = document.getElementById('ign').value
        }
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    
    }

    function toNameFace(dict, side) { //0 for left array, 1 for right array
        var leftStr = dict['leftArray'][0]
        var rightStr = dict['rightArray'][0]

        if (side === 0) {
            // leftStr = leftStr[0] + leftStr[1]
            return leftStr
        } else if (side === 1) {
            // rightStr = rightStr[0] + rightStr[1]
            return rightStr
        }
    }

    function changeSystemBackground(e) { //problem: animation doesnt carry through because span only created after form submission
        switch(e.target.id) {
            case 'choose-dark':
                systemBackgroundColorDark()
                break;
            case 'choose-light':
                systemBackgroundColorLight()
                break;
            case 'choose-earth':
                systemBackgroundColorEarth()
                break;
            case 'choose-ice':
                systemBackgroundColorIce()
                break;
            case 'choose-fire':
                systemBackgroundColorFire()
                break;
        }
    }

    function systemBackgroundColorDark() {
        document.getElementsByClassName("main-container")[0].style.cssText = "background: #301934; color: #b19cd9;"
        localStorage['background_theme'] = 'dark';
        document.getElementsByTagName("body")[0].style.cssText = "background: rgb(48, 25, 52);"
        Object.values(document.getElementsByClassName('upcoming-info-text')).map((dropdown) => {
            dropdown.style.color = "#b19cd9"
        })
        Object.values(document.getElementsByClassName('latest-info-text')).map((dropdown) => {
            dropdown.style.color = "#b19cd9"
        })
    }

    function systemBackgroundColorLight() {
        document.getElementsByClassName("main-container")[0].style.cssText = "background: rgb(255, 255, 237); color: black;"
        localStorage['background_theme'] = 'light';
        document.getElementsByTagName("body")[0].style.cssText = "background-color: #FFFF99"
        Object.values(document.getElementsByClassName('upcoming-info-text')).map((dropdown) => {
            dropdown.style.color = "black"
        })
        Object.values(document.getElementsByClassName('latest-info-text')).map((dropdown) => {
            dropdown.style.color = "black"
        })
    }

    function systemBackgroundColorEarth() {
        document.getElementsByClassName("main-container")[0].style.cssText = "background: darkgreen; color: yellowgreen;"
        localStorage['background_theme'] = 'earth';
        document.getElementsByTagName("body")[0].style.cssText = "background-color: #228B22;"
        Object.values(document.getElementsByClassName('upcoming-info-text')).map((dropdown) => {
            dropdown.style.color = "yellowgreen"
        })
        Object.values(document.getElementsByClassName('latest-info-text')).map((dropdown) => {
            dropdown.style.color = "yellowgreen"
        })
    }

    function systemBackgroundColorIce() {
        document.getElementsByClassName("main-container")[0].style.cssText = "background: #006994; color: #b8daff;"
        localStorage['background_theme'] = 'ice';
        document.getElementsByTagName("body")[0].style.cssText = "background-color: #add8e6;"
        Object.values(document.getElementsByClassName('upcoming-info-text')).map((dropdown) => {
            dropdown.style.color = "#b8daff"
        })
        Object.values(document.getElementsByClassName('latest-info-text')).map((dropdown) => {
            dropdown.style.color = "#b8daff"
        })
    }

    function systemBackgroundColorFire() {
        document.getElementsByClassName("main-container")[0].style.cssText = "background: #4e2728; color: red;"
        localStorage['background_theme'] = 'fire';
        document.getElementsByTagName("body")[0].style.cssText = "background-color: #ffcccb;"
        Object.values(document.getElementsByClassName('upcoming-info-text')).map((dropdown) => {
            dropdown.style.color = "red"
        })
        Object.values(document.getElementsByClassName('latest-info-text')).map((dropdown) => {
            dropdown.style.color = "red"
        })
    }

    // ******************************************************************************************************************
    const [char1, setChar1] = useState()
    const [char2, setChar2] = useState()
    const [char1Name, setChar1Name] = useState()
    const [char2Name, setChar2Name] = useState()
    // MAIN CALLING
    useEffect(() => {
        // retrieve background from cache
        var chosenTheme = localStorage['background_theme']
        switch(chosenTheme) {
            case "dark":
                systemBackgroundColorDark();
                break;
            case "light":
                systemBackgroundColorLight();
                break;
            case "earth":
                systemBackgroundColorEarth();
                break;
            case "ice":
                systemBackgroundColorIce();
                break;
            case "fire":
                systemBackgroundColorFire();
                break;
            default:
                systemBackgroundColorDark(); //default dark
        }
        //Indicates two elements to compare+++++++++++++++++++++++++++++++++++
        //parsing method differs
        localStorage['sortedArray'] = JSON.stringify([])
        localStorage['historyDict'] = JSON.stringify({})
        localStorage['discardedDict'] = JSON.stringify({})
        localStorage['historyCount'] = 0
    }, [])

    const startSort = (e) => {
        e.preventDefault()
        var checked = document.querySelectorAll("input[type=checkbox]:checked").length;
        if(!checked) {
            alert("You must check at least one checkbox.");
            return false;
        } else {
            console.log("started compiling..");
            var consolidatedList = []
            document.getElementsByClassName('pre-sort')[0]['style'] = "display:none;"
            // if (document.getElementById('threestar').checked === true) {
            //     consolidatedList = addCharacters(threestarsChar, consolidatedList);
            //     setPoolThree(true)
            // }
            if (document.getElementById('sc').checked === true) {
                consolidatedList = addCharacters(specialtyChar, consolidatedList);
                setPoolSpecialty(true)
            }
            if (document.getElementById('fourstar').checked === true) {
                consolidatedList = addCharacters(fourstarsChar, consolidatedList);
                setPoolFour(true)
            }
            if (document.getElementById('fivestar').checked === true) {
                consolidatedList = addCharacters(fivestarsChar, consolidatedList);
                setPoolFive(true)
            }
            if (document.getElementById('collab').checked === true) {
                consolidatedList = addCharacters(collabChar, consolidatedList);
                setPoolCollab(true)
            }
            if (document.getElementById('skins').checked === true) {
                consolidatedList = addCharacters(skinsChar, consolidatedList);
                setPoolSkins(true)
            }
            console.log("finished compilation..")
            document.getElementById('mainTable')['style'] = "display:flex;"
            initList(consolidatedList);
        }
    }

    const toggleInfo = (e) => {
        var element = e.target.parentElement
        var target = document.getElementById(`${element.classList[0]}-text`)
        if (target.style.display === 'block') {
            target.style.display = 'none'
        } else {
            target.style.display = 'block'
        };
    }

    const refreshPage = () => {window.location.reload()}

    return (
        <Fragment>
        <div className="overlay">
        <div className="homepage-header">
            <HomeHeader />
        </div>
        <div className="container main-container">
            <div className="instructions">
                <div className="card-header background-toggle">
                    Choose your preferred background style 
                    <br/>
                    <div className="btn-dark" id="choose-dark" onClick={changeSystemBackground}>
                        Dark
                    </div>
                    <div className="btn-light" id="choose-light" onClick={changeSystemBackground}>
                        Light
                    </div>
                    <div className="btn-success" id="choose-earth" onClick={changeSystemBackground}>
                        Earth
                    </div>
                    <div className="btn-primary" id="choose-ice" onClick={changeSystemBackground}>
                        Ice
                    </div>
                    <div className="btn-danger" id="choose-fire" onClick={changeSystemBackground}>
                        Fire
                    </div>
                </div>

                <div className="page-title"><b>Epic Seven Sorter</b></div>
                <br/><u>IMPORTANT: This might easily take over 30mins. Grab a cup of coffee.</u><br/>
                Select the pool of characters you want to sort. Then, two images will be presented, click on the one you prefer.<br/> 
                <br />
            </div>
            <table id="mainTable" align="center" style={{display: 'none'}}>
            <tbody>
                <tr>
                    <td id="battleNumber" colSpan="3" style={{paddingBottom: '30px', textAlign:'center'}}>
                        <b>
                            Battle #{numQuestion} <br />
                            <ProgressBar progress={parseInt(currentPercent*100/totalPercent)} />
                        </b>
                    </td>
                </tr>
                <tr>
                    <td id="leftField" onClick={selectChoice} rowSpan="2" style={{textAlign:"center"}}>
                        <Container style={{pointerEvents: 'none', userSelect: "none", padding: "0"}}>
                            <div className='card'>
                                <span className="css-magic"></span>
                                <span className="css-magic"></span>
                                <span className="css-magic"></span>
                                <span className="css-magic"></span>
                                <img src={process.env.PUBLIC_URL+char1} alt={char1Name}/>
                                <text style={{backgroundColor: 'white'}}>{char1Name}</text>
                            </div>
                        </Container>
                    </td>
                    <td className="middleField" style={{textAlign:"center", pointerEvents: 'none'}}>
                        <Container style={{pointerEvents: 'none', userSelect: "none"}}>
                            {/* Pass */}
                        </Container>
                    </td>
                    <td id="rightField" onClick={selectChoice} rowSpan="2" style={{textAlign:"center"}}>
                        <Container style={{pointerEvents: 'none', userSelect: "none", padding: "0"}}>
                            <div className='card'>
                                <span className="css-magic"></span>
                                <span className="css-magic"></span>
                                <span className="css-magic"></span>
                                <span className="css-magic"></span>
                                <img src={process.env.PUBLIC_URL+char2} alt={char2Name}/>
                                <text style={{backgroundColor: 'white'}}>{char2Name}</text>
                            </div>
                        </Container>
                    </td>
                </tr>
                <tr>
                    <td id="middleField" className="middleField" onClick={selectChoice} style={{textAlign:"center"}}>
                        <Container style={{pointerEvents: 'none', userSelect: "none"}}>
                            <FontAwesomeIcon icon={faUndo} /> Undo
                        </Container>
                    </td>
                </tr>
            </tbody>
            </table>

            <div id="resultField" style={{textAlign: "center"}}>
                <div>
                    <div className="row" style={{marginBottom: "30px", marginTop: "30px"}}>
                        {finishList.length > 3 ?
                            [finishList.slice(1,2), finishList.slice(0,1), finishList.slice(2,3)].map((character, rank) => {
                                var placement = [2,1,3] //podium
                                var medal = ['silver', 'gold', 'bronze']
                                return (
                                    <Fragment>
                                        <div className="col-4" style={{display: "flex", flexDirection: "column-reverse", alignItems: "center"}}>
                                            <div className={"podium-"+medal[rank]}>
                                                #{placement[rank]}
                                            </div>
                                            <div>{character[0][1]}</div>
                                            <div className="podium-character"><img src={process.env.PUBLIC_URL+character[0][0]}></img></div>
                                            <img src={process.env.PUBLIC_URL+medal[rank]+".png"} width="30px" className="result-medals"></img>
                                        </div>
                                    </Fragment>)
                        }) : null}
                    </div>
                    <table id="resultData" align="center">
                        {finishList.map((character, rank) => {
                            return (
                            <Fragment>
                                {rank === 0? 
                                    <tr>
                                        <th>Rank</th>
                                        <th>Character</th>
                                    </tr> : null
                                }
                                {rank > 2? //index starts @0
                                    <tr>
                                        <td>{rank+1}</td>
                                        <td>
                                            {character[1]}
                                        </td>
                                    </tr> : null
                                }
                            </Fragment>
                            )
                        })}
                    </table>
                </div>
            </div>
            <h4 align="center" style={numQuestion < 1? {display:"block"} : {display:"none"}}>Sorting Options</h4>
            <h4 align="center" style={numQuestion >= 1? {display:"block"} : {display:"none"}}>
                <b>Pool</b>: 
                {/* {poolThree? " [3* Characters]" : ""} */}
                {poolSpecialty? " [Specialty Change]" : ""}
                {poolFour? " [4* Characters]" : ""}
                {poolFive? " [5* Characters]" : ""}
                {poolCollab? " [Collab Characters]" : ""}
                {poolSkins? " [Skins]" : ""}
            </h4>
            <div className="pre-sort">
                    <form id="check_character" action="" align="center" onSubmit={startSort}>
                        {/* <div className="row">
                            <div className="col">
                                <label htmlFor="threestar"> 3* characters</label>
                            </div>
                            <div className="col">
                            <label>
                                <input type="checkbox" name="threestar" id="threestar" />
                                <span className="styled-checkbox"></span>
                                <i className="indicator"></i>
                            </label>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col">
                                <label htmlFor="sc"> 3* (Specialty-Change)</label>
                            </div>
                            <div className="col">
                            <label>
                                <input type="checkbox" name="sc" id="sc" />
                                <span className="styled-checkbox"></span>
                                <i className="indicator"></i>
                            </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="fourstar"> 4* characters</label>
                            </div>
                            <div className="col">
                                <label>
                                    <input type="checkbox" name="fourstar" id="fourstar" />
                                    <span className="styled-checkbox"></span>
                                    <i className="indicator"></i>
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="fivestar"> 5* characters</label><br/>
                            </div>
                            <div className="col">
                            <label>
                                <input type="checkbox" name="fivestar" id="fivestar" />
                                <span className="styled-checkbox"></span>
                                <i className="indicator"></i>
                            </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="collab"> Collab characters</label>
                            </div>
                            <div className="col">
                            <label>
                                <input type="checkbox" name="collab" id="collab" />
                                <span className="styled-checkbox"></span>
                                <i className="indicator"></i>
                            </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="skins"> Skins</label>
                            </div>
                            <div className="col">
                            <label>
                                <input type="checkbox" name="skins" id="skins" />
                                <span className="styled-checkbox"></span>
                                <i className="indicator"></i>
                            </label>
                            </div>
                        </div>
                        <br/>
                        <div className="row" style={{display: "none"}}>
                            <div className="col">
                                <label htmlFor="ign"> In-game Name: </label>
                                <input type="text" name="ign" id="ign" placeholder="epic7#1234567" />
                            </div>
                            <div className="col">
                            <label htmlFor="server"> Server:</label>
                            <select name="server" id="server">
                                <option value="NULL"></option>
                                <option value="global">Global</option>
                                <option value="korea">Korea</option>
                                <option value="asia">Asia</option>
                                <option value="europe">Europe</option>
                                <option value="japan">Japan</option>
                            </select>
                            </div>
                        </div>
                        <br/>
                        <input type="submit" name="submit" id="submit_list" value="Start" />
                    </form>
                
            </div>
        </div>
        <MiniFooter/>
        <Menu />
        </div>
        </Fragment>
    )
}

// redux store
const mapStateToProp = state => ({
    GetInventory: state.ProxyData.GetInventoryAfter,
    // UndoInventory: state.ProxyData.GetInventoryBefore
})

export default connect(mapStateToProp)(Sorter);