import { Fragment, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCalendarDay, faInfinity, faCircleDot } from '@fortawesome/free-solid-svg-icons';
import "./css/homeheader.css";

const HomeHeader = () => {
    const [hamburger, setHamburger] = useState(false);
    const ref = useRef(null);

    function openHamburger() {
        setHamburger(!hamburger)
    }


    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setHamburger(false);
            }
        };
        
        document.addEventListener('click', handleClickOutside);
        
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
        
    }, [ref]);

    return (
        <Fragment>
            <div className="homepage-navbar">
                <div className="homepage-filler"></div>
                <Link to="/">
                    <div className="homepage-title-gradient">
                        <div className="homepage-title">
                            E7dle
                        </div>
                    </div>
                </Link>
                <div className="homepage-hamburger" ref={ref}>
                    <FontAwesomeIcon className="gradient-icon above-background" onClick={openHamburger}
                                        icon={faBars} size="3x" color={"white"}/>
                </div>
            </div>
            <div className="homepage-navbar-dropdown" style={hamburger? {display: "block"} : {display: "none"}}>
                <div className="row">
                    <div className="col">
                        <div className="homepage-navbar-dropheader"><FontAwesomeIcon icon={faCalendarDay} /> Daily</div>
                        <ul>
                            <li><Link to="/classic"><span>Classic</span></Link></li>
                            <li><Link to="/guessskill"><span>Skill Icons</span></Link></li>
                            <li><Link to="/artifact"><span>Artifact</span></Link></li>
                        </ul>
                    </div>
                    <div className="col">
                        <div className="homepage-navbar-dropheader"><FontAwesomeIcon icon={faInfinity} /> Unlimited</div>
                        <ul>
                            <li><Link to="/unlimited"><span>Unlimited</span></Link></li>
                        </ul>
                    </div>
                    <div className="col">
                        <div className="homepage-navbar-dropheader"><FontAwesomeIcon icon={faCircleDot} /> Others</div>
                        <ul>
                            <li><Link to="/sorter"><span>Sorter</span></Link></li>
                        </ul>
                    </div>
                </div>
                <span className="homepage-navbar-template">
                    <img src={"/c2022_sd01_s.png"} alt=""></img>
                </span>
            </div>
        </Fragment>
    )
}

export default HomeHeader;