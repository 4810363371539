import { Fragment } from "react";
import './css/legend.css';

const Legend = () => {
    return (
        <Fragment>
            <div className="classic-legend-item">
                <span className="red_span">&nbsp;</span> Red: Trait is Incorrect</div> 
            <div className="classic-legend-item">
                <span className="green_span">&nbsp;</span> Green: Trait is Correct</div> 
            <div className="classic-legend-item">⇈  Your guess is released earlier than actual character</div> 
            <div className="classic-legend-item">⇊ Your guess is released later than actual character</div> 
        </Fragment>
    )
}

export default Legend;